import { ManualApiCall } from '../../middlewares/apiMiddleware';
import { saveAuthToken } from '../../utils/index';
import history from 'utils/history';
export const REFER_FRIEND = 'REFER_FRIEND';
export const REFER_FRIEND_SUCCESS = 'REFER_FRIEND_SUCCESS';
export const REFER_FRIEND_FAILURE = 'REFER_FRIEND_FAILURE';
// export const LOGOUT = 'LOGOUT';

export const referFriendAction = (payload) => async (dispatch) => {
	dispatch({
		type: REFER_FRIEND,
	});

	const apiRequest = await ManualApiCall(
		`/auth/advertiser/login`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify(payload),
		},
		dispatch,
		REFER_FRIEND_FAILURE,
		history
	);
	if (apiRequest.error === false) {
		dispatch({ type: REFER_FRIEND_SUCCESS, apiResponse: apiRequest });
		// history.push('/app/customer/dashboard');
	}
};

// export const logout = () => ({
// 	type: LOGOUT,
// });
