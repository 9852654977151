import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Container, Button, Modal } from 'react-bootstrap';
import {
	AuthFooterComponent,
	LogoutComponent,
	AuthAdminHeaderComponent,
	AccountPassword,
	AboutYou,
} from 'components/common';
import crossIcon from '../../../assets/images/crossIcon.svg';
import Profile from '../../../assets/images/Profile.svg';
import { fetchProfileAction } from '../../../actions';
import { connect } from 'react-redux';
import '../../User/ProfileComponent/profileComponent.css';
import './AdminProfile.css';

const AdminProfile = ({
	profile: { apiErrors, isLoading, user },
	fetchProfileAction,
	dispatch,
}) => {
	useEffect(() => {
		fetchProfileAction();
	}, []);

	console.log({ user });

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);

	return (
		<>
			<div className="authfullContainer">
				<AuthAdminHeaderComponent user={user && user} />
				<div className="fullBg">
					<Container fluid>
						<Row style={{ backgroundColor: '#F4F0E3', paddingTop: '100px' }}>
							<div className="offset-xl-1 col-xl-5 offset-lg-1 col-lg-6 offset-md-1 col-md-6 offset-sm-1 col-sm-6 pr-0">
								<div className=" ml-xl-4 ml-lg-4 ml-md-4 ml-sm-4 ml-0 mt-5">
									<div className="admin-profileHeading">
										<h1 className="heading">Your Profile</h1>
										<div>
											<ul className="profile ">
												<li className="d-inline-block pr-2 mr-3 ">
													<Link
														className="link text-decoration-none"
														onClick={() => setShow(true)}
													>
														Logout
													</Link>
													<Modal
														show={show}
														onHide={() => setShow(false)}
														dialogClassName="customModal2"
														aria-labelledby="example-custom-modal-styling-title"
													>
														<Modal.Header className="colorModal">
															<Button
																className="closeButton border-0 shadow-none rounded-0"
																onClick={handleClose}
															>
																<img
																	className="m-xl-3 m-lg-3 m-md-3 m-1"
																	src={crossIcon}
																	alt="icon"
																/>

																{/* <img className="mx-1 img-fluid" src={crossIcon} /> */}
															</Button>
															<Modal.Title className="colorModal"></Modal.Title>
														</Modal.Header>
														<Modal.Body className="colorModal">
															<Container fluid>
																<LogoutComponent handleClose={handleClose} />
															</Container>
														</Modal.Body>
													</Modal>
												</li>
												{/* <li className=" d-inline-block mx-2">
													<Link className="link text-decoration-none">
														Password Reset
													</Link>
												</li> */}
											</ul>
										</div>
									</div>
								</div>
							</div>
							<Col xl={6}>
								<img
									src={Profile}
									alt="profile"
									className="img-fluid admin-profile"
								/>
							</Col>
						</Row>
					</Container>
				</div>
				<Container fluid>
					<Row>
						<div className=" col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 ">
							{/* <GetVerified status={user && user.status} /> */}
							<div className="adminAccountPassword">
								<AccountPassword />
							</div>

							{/* <DeleteAccount /> */}
						</div>

						<div className="col-xl-8  col-lg-7 col-md-10 col-sm-12 col-xm-12 ">
							<AboutYou user={user && user} />
						</div>
					</Row>
				</Container>
			</div>
			<AuthFooterComponent />
		</>
	);
};

// export default ProfileComponent;

const mapStateToProps = (state) => ({
	profile: state.profile,
});

export default connect(mapStateToProps, {
	fetchProfileAction,
})(withRouter(AdminProfile));
