import { ManualAuthApiCall } from '../../../middlewares/apiMiddleware';
// import { saveAdvertiserToken } from '../../utils/index';
import history from 'utils/history';
import { toQueryString } from 'utils';
export const SEND_RESEND = 'SEND_RESEND';
export const SEND_RESEND_SUCCESS = 'SEND_RESEND_SUCCESS';
export const SEND_RESEND_FAILURE = 'SEND_RESEND_FAILURE';

export const SendResendAction = (payload) => async (dispatch) => {
	dispatch({
		type: SEND_RESEND,
	});

	const apiRequest = await ManualAuthApiCall(
		`/auth/profile/resend`,
		{
			method: 'PUT',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(payload),
		},
		dispatch,
		SEND_RESEND_FAILURE,
		history,
		true
	);
	if (apiRequest.error === false) {
		dispatch({ type: SEND_RESEND_SUCCESS, apiResponse: apiRequest });
	}
};
