import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
// import { ColumnFilterCheckbox } from './AdminColumnFilterCheckbox';
// import ColumnFilterText from './AdminColumnFilterText';
// import checkSmall from '../../assets/images/CheckSmall.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
// import crossIcon from '../../../../assets/images/Cross.svg';
import TableBaseComponent from '../../common/TableBaseComponent';
import PaginationComponent from '../../common/Pagination';
import PendingSuccess from '../../common/PendingSuccess';
import history from 'utils/history';
import '../AdminCompaniesDashboard/AdminStyle.css';
import { CompanyInvoicesTableAction } from '../../../actions';

const CompanyViewTable = (
	{
		companyInvoices: { apiErrors, isLoading, tableData },
		CompanyInvoicesTableAction,
	},
	user
) => {
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [filter, setFilter] = useState({
		filter: [],
		sorting: [],
		pagination: [],
	});

	useEffect(() => {
		CompanyInvoicesTableAction(filter);
	}, []);
	const handleTableAction = () => {
		CompanyInvoicesTableAction(filter);
	};
	// const { getTable, data, getTableSort } = props;
	// const handleSort = (data) => {
	//   getTableSort(data);
	// };
	const data = [
		{
			email: 'email@gmail.com',
			name: 'name',
			coin_earnt: '12',
			postId: '12/2/2020',
		},
		{
			email: 'email1@gmail.com',
			name: 'name1',
			coin_earnt: '121',
			postId: '12/2/2021',
		},
		{
			email: 'email1@gmail.com',
			name: 'name1',
			coin_earnt: '121',
			postId: '12/2/2021',
		},
	];
	const COLUMNS = [
		// {
		// 	Header: () => {
		// 		return (
		// 			<>
		// 				<div className="header-wrapper">
		// 					<span className="pt-2">Email</span>
		// 					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		// 					<ColumnFilterCheckbox />
		// 				</div>
		// 			</>
		// 		);
		// 	},
		// 	accessor: 'email',
		// },
		{
			Header: () => {
				return (
					<>
						<div className="header-wrapper" style={{ marginRight: '-2em' }}>
							<span className="buttonHeaders mb-4 ">Description</span>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							{/* <ColumnFilterText /> */}
						</div>
					</>
				);
			},
			accessor: 'email',
		},
		{
			Header: () => {
				return (
					<>
						<div
							className="header-wrapper"
							style={{ marginRight: '-4em' }}
							onClick={() => {
								filter['sorting'] &&
								filter['sorting']['costPerCustomer'] &&
								filter['sorting']['costPerCustomer'] == 'ASC'
									? (filter['sorting']['costPerCustomer'] = 'DESC')
									: (filter['sorting'] = {
											...filter['sorting'],
											costPerCustomer: 'ASC',
									  });

								setFilter(filter);
								handleTableAction();
							}}
						>
							<span>Cost per customer</span>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<span className="tableSortIcon" style={{ cursor: 'pointer' }}>
								{filter['sorting'] &&
								filter['sorting']['costPerCustomer'] &&
								filter['sorting']['costPerCustomer'] == 'ASC' ? (
									<FontAwesomeIcon icon={faCaretDown} />
								) : (
									<FontAwesomeIcon icon={faCaretUp} />
								)}
							</span>
						</div>
					</>
				);
			},
			accessor: 'coin_earnt',
		},
		{
			Header: () => (
				<div
					className="header-wrapper"
					style={{ marginRight: '-2em' }}
					onClick={() => {
						filter['sorting'] &&
						filter['sorting']['time'] &&
						filter['sorting']['time'] == 'ASC'
							? (filter['sorting']['time'] = 'DESC')
							: (filter['sorting'] = {
									...filter['sorting'],
									time: 'ASC',
							  });

						setFilter(filter);
						handleTableAction();
					}}
				>
					<span>
						Time &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					</span>
					<span className="tableSortIcon" style={{ cursor: 'pointer' }}>
						{filter['sorting'] &&
						filter['sorting']['time'] &&
						filter['sorting']['time'] == 'ASC' ? (
							<FontAwesomeIcon icon={faCaretDown} />
						) : (
							<FontAwesomeIcon icon={faCaretUp} />
						)}
					</span>
				</div>
			),
			accessor: 'postId',
		},
		{
			Header: () => {
				return (
					<div
						className="header-wrapper"
						className="header-wrapper"
						style={{ marginRight: '-2em' }}
						onClick={() => {
							filter['sorting'] &&
							filter['sorting']['paymentStatus'] &&
							filter['sorting']['paymentStatus'] == 'ASC'
								? (filter['sorting']['paymentStatus'] = 'DESC')
								: (filter['sorting'] = {
										...filter['sorting'],
										paymentStatus: 'ASC',
								  });

							setFilter(filter);
							handleTableAction();
						}}
					>
						<span>Payment Status &ensp;&nbsp;&nbsp;&nbsp;</span>
						<span className="tableSortIcon" style={{ cursor: 'pointer' }}>
							{filter['sorting'] &&
							filter['sorting']['paymentStatus'] &&
							filter['sorting']['paymentStatus'] == 'ASC' ? (
								<FontAwesomeIcon icon={faCaretDown} />
							) : (
								<FontAwesomeIcon icon={faCaretUp} />
							)}
						</span>
					</div>
				);
			},
			accessor: 'status',
			Cell: ({ cell: { value } }) => (
				<PendingSuccess
					type="success"
					width="30px"
					className="img-fluid mx-4"
				/>
			),
			// <img src={checkSmall} />,
		},
		{
			Header: () => {
				return (
					<div className="header-wrapper">
						<span>Action</span>
					</div>
				);
			},
			accessor: 'action',
			Cell: ({ cell: { value } }) => (
				<Button className="button shadow-none" onClick={() => setShow(true)}>
					<b>View</b>
				</Button>
			),
		},
	];

	// const COLUMNS_MODAL = [
	// 	{
	// 		Header: () => {
	// 			return (
	// 				<>
	// 					<div className="header-wrapper">
	// 						<span className="pb-2">Company</span>
	// 						{/* <ColumnFilterCheckbox /> */}
	// 					</div>
	// 				</>
	// 			);
	// 		},
	// 		accessor: 'email',
	// 	},
	// 	{
	// 		Header: () => {
	// 			return (
	// 				<>
	// 					<div className="header-wrapper">
	// 						<span className="pb-2">Time</span>
	// 						{/* <ColumnFilterText /> */}
	// 					</div>
	// 				</>
	// 			);
	// 		},
	// 		accessor: 'name',
	// 	},
	// 	{
	// 		Header: () => {
	// 			return (
	// 				<>
	// 					<div className="header-wrapper">
	// 						<span className="pb-2">URL</span>
	// 						{/* &nbsp; &nbsp;{' '}
	// 						<span>
	// 							<FontAwesomeIcon icon={faSort} />
	// 						</span> */}
	// 					</div>
	// 				</>
	// 			);
	// 		},
	// 		accessor: 'coin_earnt',
	// 	},
	// 	{
	// 		Header: () => (
	// 			<div className="header-wrapper">
	// 				<span
	// 					className="pb-2"
	// 					// onClick={() => {
	// 					//   handleSort("date");
	// 					// }}
	// 				>
	// 					Device
	// 					{/* &ensp; <FontAwesomeIcon icon={faSort} /> */}
	// 				</span>
	// 			</div>
	// 		),
	// 		accessor: 'postId',
	// 	},
	// 	{
	// 		Header: () => {
	// 			return (
	// 				<div className="header-wrapper">
	// 					<span className="pb-2">Broswer</span>
	// 				</div>
	// 			);
	// 		},
	// 		accessor: 'status',
	// 		Cell: ({ cell: { value } }) => <img src={checkSmall} />,
	// 	},
	// 	{
	// 		Header: () => {
	// 			return (
	// 				<div className="header-wrapper">
	// 					<span className="pb-2">Date</span>
	// 				</div>
	// 			);
	// 		},
	// 		accessor: 'action',
	// 		Cell: ({ cell: { value } }) => (
	// 			<Button className="button shadow-none" onClick={() => setShow(true)}>
	// 				<b>View</b>
	// 			</Button>
	// 		),
	// 	},
	// ];

	// useEffect(() => {
	//   getTable();
	// }, []);

	return (
		<>
			{/* <Modal
				show={show}
				onHide={() => setShow(false)}
				dialogClassName="customModal"
				aria-labelledby="example-custom-modal-styling-title"
			>
				<Modal.Header className="colorModal">
					<Button
						className="closeButton border-0 shadow-none rounded-0"
						onClick={handleClose}
					>
						<img className="m-xl-3 m-lg-3 m-md-3 m-1" src={crossIcon} />
					</Button>
					<Modal.Title className="colorModal"></Modal.Title>
				</Modal.Header>
				<Modal.Body className="colorModal px-xl-5 px-lg-5 py-5">
					<div className="colorModal px-xl-5 px-lg-5 px-md-3">
						<Container fluid>
							<h1 className="colorModal  m-b-3">Details</h1>
							<TableBaseComponent COLUMNS={COLUMNS_MODAL} DATA={data} />
							<PaginationComponent />
						</Container>
					</div>
				</Modal.Body>
			</Modal> */}

			<Row className="mt-5">
				<div className=" ml-5 pt-5 mt-5">
					<h1 className="profileHeading">Company Invoices</h1>
				</div>
				<Col
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					className="py-5 px-xl-5 px-lg-5 px-md-3 mt-3"
				>
					<TableBaseComponent COLUMNS={COLUMNS} DATA={data} />
					<PaginationComponent
						filter={filter}
						setFilter={setFilter}
						handleTableAction={handleTableAction}
					/>
				</Col>
			</Row>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		companyInvoices: state.companyInvoices,
	};
};

export default connect(mapStateToProps, { CompanyInvoicesTableAction })(
	CompanyViewTable
);
// export default CompanyViewTable;
