import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { Row, Col, Form } from 'react-bootstrap';
import '../../Advertiser/AdvertiserProfileComponent/profileComponent.css';
import { MyTextField } from '../../../Validations/InputField';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PendingSuccess from '../PendingSuccess';
import { PhoneNumberAction, SendResendAction } from '../../../actions';
// import { ClipLoader } from 'react-spinners';
// import Timer from 'react-compound-timer';

const AdminPhoneNumber = ({
	phoneNumber: { apiErrors, isLoading, phoneNumberStatus },
	PhoneNumberAction,
	history,
	location,
	user,
}) => {
	const dispatch = useDispatch();

	const [serverError, setServerErrors] = useState({});
	const [showPhone, setShowPhone] = useState(true);
	const [showButton, setShowButton] = useState(false);
	const [phoneNumberTemp, setPhoneNumberTemp] = useState(false);

	useEffect(() => {
		setServerErrors(apiErrors);
	}, [apiErrors]);

	useEffect(() => {
		// if (!phoneNumberStatus.valid) setShowPhone(false);
		// console.log('api error', apiErrors);
		{
			(phoneNumberStatus && phoneNumberStatus.valid) ||
			(apiErrors && apiErrors.valid) ||
			(apiErrors && apiErrors.phoneNumber)
				? setShowPhone(true)
				: setShowPhone(false);
		}
	}, [isLoading, apiErrors, phoneNumberStatus, location.pathname]);

	return (
		<>
			<div
				className={
					user && user.status && user.status.phoneNumberVerified
						? 'line2'
						: 'line1'
				}
			>
				<div className="position-relative ml-3  ml-md-5 ml-sm-4  ml-xs-3 ">
					<Row className=" mt-5 ">
						<div className="col-xl-11 col-lg-11  col-md-11  col-sm-11   col-sx-11  mt-4">
							<div
								className="d-flex"
								style={{ justifyContent: 'space-between' }}
							>
								<div style={{ width: '87%' }}>
									<h1 className="profileHeading">Phone Number</h1>
								</div>
								<div className="mb-3">
									{user && user.status && user.status.phoneNumberVerified ? (
										<PendingSuccess className="" type="success" width="50px" />
									) : (
										<PendingSuccess className="" type="pending" width="50px" />
									)}
								</div>
							</div>
						</div>
					</Row>
					<Row>
						<Col xl={11} lg={11} md={12} sm={12} xs={12}>
							<Formik
								enableReinitialize={true}
								initialValues={{
									phoneNumber: user && user.phoneNumber ? user.phoneNumber : '',
									verificationCode: '',
								}}
								onSubmit={async (values, { setSubmitting }) => {
									setSubmitting(true);
									setPhoneNumberTemp(values.phoneNumber);
									dispatch(PhoneNumberAction(values));
									setSubmitting(false);
								}}
								validationSchema={Yup.object().shape({
									phoneNumber: Yup.string()
										.required("Phone Number can't be empty")
										.matches(
											/^\+[1-9]\d{3,14}$/,
											'Phone number must be formatted as [+][country code][phone number plus area code]'
										),
								})}
								// ^\+?[1-9]\d{1,14}$
							>
								{(props) => {
									const {
										values,
										touched,
										errors,
										isSubmitting,
										handleChange,
										handleSubmit,
										// setStatus,
									} = props;
									return (
										<Form onSubmit={handleSubmit} noValidate>
											<Row>
												{showPhone && (
													<Col
													// xl={6} lg={12} md={12} sm={12} xs={12}
													>
														<MyTextField
															disabled={true}
															style={{ backgroundColor: 'white' }}
															placeholder="+440000000000"
															inputType="text"
															fieldName="phoneNumber"
															onChange={(e) => {
																phoneNumberStatus = false;
															}}
															serverError={serverError}
															errors={errors.phoneNumber || false}
															inputValue={values.phoneNumber}
															fieldTouch={touched.phoneNumber}
															setServerErrors={setServerErrors}
															apiErrors={apiErrors}
															handleChange={handleChange}
														/>

														{/* <Button
															type="submit"
															className="loginButton   my-4 "
															disabled={isLoading}
															// onClick={start}
														>
															{isLoading ? (
																<ClipLoader size={20} color="#FCF9F2" />
															) : (
																`Send Code`
															)}
														</Button> */}
													</Col>
												)}
												{!showPhone && (
													<Col
													// xl={6} lg={12} md={12} sm={12} xs={12}
													>
														<MyTextField
															placeholder="Verification Code"
															inputType="text"
															fieldName="verificationCode"
															// onChange={(e) => {
															// 	customHandle(handleChange, 'emailAddress', e);
															// }}
															serverError={serverError}
															errors={errors.verificationCode || false}
															inputValue={values.verificationCode}
															fieldTouch={touched.verificationCode}
															setServerErrors={setServerErrors}
															apiErrors={apiErrors}
															handleChange={handleChange}
															disabled={true}
															style={{ backgroundColor: 'white' }}
														/>
														{/* <Button
															type="submit"
															className="loginButton   my-4 "
															disabled={isLoading}
														>
															{isLoading ? (
																<ClipLoader size={20} color="#FCF9F2" />
															) : (
																`Verify`
															)}
														</Button> */}
													</Col>
												)}
											</Row>
										</Form>
									);
								}}
							</Formik>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	phoneNumber: state.phoneNumber,
});

export default connect(mapStateToProps, { PhoneNumberAction })(
	withRouter(AdminPhoneNumber)
);
