import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { MyTextField } from '../../../Validations/InputField';
import * as Yup from 'yup';
import '../../../Validations/formik.css';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { redeemGiftAction } from 'actions/User/RedeemGiftAction';
import { ClipLoader } from 'react-spinners';

const CongratulationsComponents = ({
	redeemGift: { apiErrors, isLoading },
	redeemGiftAction,
	coins,
}) => {
	// const [showError, setShowError] = useState(true);
	const [serverError, setServerErrors] = useState({});

	useEffect(() => {
		setServerErrors(apiErrors);
	}, [apiErrors]);

	return (
		<>
			<Row className="text-center pt-5 mt-5">
				<Col>
					<h1 className="heading1 ml-2">
						Congratulations, your gift card is ready!
					</h1>
				</Col>
			</Row>
			<Row className="text-center py-3">
				<Col
					xl={4}
					lg={6}
					md={8}
					sm={12}
					xs={12}
					className="text-center offset-xl-4 offset-lg-3 offset-md-2"
				>
					<div>
						<p className="paragraph">
							Fill in the form below to redeem your gift card. Each submission
							will remove the entered amount of coins from your balance.
						</p>
					</div>
				</Col>
			</Row>
			<Row className="pb-5 mb-5">
				<Col
					xl={4}
					xl={{ offset: 4 }}
					lg={4}
					lg={{ offset: 4 }}
					md={6}
					md={{ offset: 3 }}
					sm={8}
					sm={{ offset: 2 }}
					xs={10}
					xs={{ offset: 1 }}
					className="col-10 eForm"
				>
					<Formik
						initialValues={{ giftCardName: '', giftCardAmount: '' }}
						onSubmit={async (values, { setSubmitting }) => {
							setSubmitting(true);
							redeemGiftAction(values);
							setSubmitting(false);
						}}
						validationSchema={Yup.object().shape({
							giftCardName: Yup.string().required(
								"Gift card name can't be empty"
							),
							giftCardAmount: Yup.number('Gift card amount must be a number')
								.required("Gift card amount can't be empty")
								.integer("Gift card amount can't be a decimal value")
								.positive("Gift card amount can't be zero or negative")
								.test(
									'isGiftCardAmmount',
									'Gift card ammount must be less than or equals to total verified coins',
									(value) => value <= coins
								),
						})}
					>
						{(props) => {
							const {
								values,
								touched,
								errors,
								isSubmitting,
								handleChange,
								// handleBlur,
								handleSubmit,
								// setStatus,
							} = props;
							return (
								<Form onSubmit={handleSubmit} noValidate>
									<MyTextField
										placeholder="Gift card name"
										inputType="text"
										fieldName="giftCardName"
										// onChange={(e) => {
										// 	customHandle(handleChange, 'emailAddress', e);
										// }}
										serverError={serverError}
										errors={errors.giftCardName || false}
										inputValue={values.giftCardName}
										fieldTouch={touched.giftCardName}
										setServerErrors={setServerErrors}
										apiErrors={apiErrors}
										handleChange={handleChange}
									/>
									<MyTextField
										placeholder="Gift card amount"
										inputType="text"
										fieldName="giftCardAmount"
										// onChange={(e) => {
										// 	customHandle(handleChange, 'password', e);
										// }}
										serverError={serverError}
										errors={errors.giftCardAmount || false}
										inputValue={values.giftCardAmount}
										fieldTouch={touched.giftCardAmount}
										setServerErrors={setServerErrors}
										apiErrors={apiErrors}
										handleChange={handleChange}
									/>
									<Button
										type="submit"
										disabled={isSubmitting}
										className="border-0 font-weight-bold loginButton my-2 px-5 shadow-none"
									>
										{isLoading ? (
											<ClipLoader size={20} color="#FCF9F2" />
										) : (
											`Submit`
										)}
									</Button>
								</Form>
							);
						}}
					</Formik>
					{/* <MyEnhancedForm user={{ giftCardName: "", giftCardAmount: "" }} /> */}
				</Col>
			</Row>
		</>
	);
};

// export default CongratulationsComponents;

const mapStateToProps = (state) => ({
	redeemGift: state.redeemGift,
});

export default connect(mapStateToProps, {
	redeemGiftAction,
})(CongratulationsComponents);
