import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Container, Button, Modal } from 'react-bootstrap';
import {
	AuthFooterComponent,
	AuthAdminHeaderComponent,
	ProfileView,
	CompanyDetail,
} from 'components/common';
import TableComponent from '../../Advertiser/AdvertiserInvoices/AdvertiserInvoicesTableComponent';
import { useLocation } from 'react-router';
import Profile from '../../../assets/images/Profile.svg';
import { fetchProfileAction } from '../../../actions';
import { connect } from 'react-redux';
import CompanyViewTable from './CompanyViewTable';
import queryString from 'query-string';
import '../../User/ProfileComponent/profileComponent.css';

const AdminCompanyView = ({
	profile: { apiErrors, isLoading, user },
	fetchProfileAction,
	dispatch,
}) => {
	const location = useLocation();
	const [id, setId] = useState({});

	useEffect(() => {
		const companyId = queryString.parse(location.search);
		setId(companyId);
	}, []);
	// console.log(id);

	return (
		<>
			<div className="authfullContainer">
				<AuthAdminHeaderComponent user={user && user} />
				<div className="fullBg">
					<Container fluid>
						<Row style={{ backgroundColor: '#F4F0E3', paddingTop: '66px' }}>
							<div className="offset-xl-1 col-xl-5 offset-lg-1 col-lg-6 offset-md-1 col-md-6 offset-sm-1 col-sm-6 pr-0">
								<div className=" ml-xl-4 ml-lg-4 ml-md-4 ml-sm-4 ml-0 mt-5">
									<div>
										<h1 className="heading admin-heading">Company</h1>
									</div>
								</div>
							</div>
							<Col xl={6}>
								<img
									src={Profile}
									alt="profile"
									className="img-fluid admin-girl"
								/>
							</Col>
						</Row>
					</Container>
				</div>
				<Container fluid>
					<Row>
						<div className=" col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 ">
							<ProfileView userId={id} />
						</div>

						<div className="col-xl-8  col-lg-8 col-md-12 col-sm-12 col-xm-12 ">
							<CompanyDetail userId={id} />
							{/* <YourEmail user={user && user} /> */}
							{/* <PhoneNumber user={user && user} /> */}
						</div>
					</Row>
				</Container>

				<Container fluid>
					<h1 className="pt-5 ml-5">Company Invoices</h1>
					<TableComponent userId={id} />
				</Container>
			</div>
			<AuthFooterComponent />
		</>
	);
};

// export default ProfileComponent;

const mapStateToProps = (state) => ({
	profile: state.profile,
});

export default connect(mapStateToProps, {
	fetchProfileAction,
})(withRouter(AdminCompanyView));
