// import * as ActionTypes from '../../actions/home'
import * as ActionTypes from '../../../actions';

const initialState = {
	isLoading: false,
	user: [],
	apiErrors: [],
};

export const GET_PROFILE_EMAIL = 'GET_PROFILE_EMAIL';
export const GET_PROFILE_EMAIL_SUCCESS = 'GET_PROFILE_EMAIL_SUCCESS';
export const GET_PROFILE_EMAIL_FAILURE = 'GET_PROFILE_EMAIL_FAILURE';

export const GetEmailReducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.GET_PROFILE_EMAIL:
			return { ...state, isLoading: true };

		case ActionTypes.GET_PROFILE_EMAIL_SUCCESS:
			return {
				...state,
				isLoading: false,
				user: action.apiResponse.data ? action.apiResponse.data : null,
				apiErrors: null,
			};

		case ActionTypes.GET_PROFILE_EMAIL_FAILURE:
			return {
				...state,
				isLoading: false,
				user: null,
				apiErrors: action.apiResponse.error ? action.apiResponse.errors : null,
			};
		default:
			return state;
	}
};

