import React from 'react';
import { Row, Col, Button, Nav, Navbar, Container } from 'react-bootstrap';
import './HeaderStyle.css';
import GravyIcon from '../../../../assets/images/GravyIcon.svg';
import PendingSuccess from '../../../common/PendingSuccess';
import { Link } from 'react-router-dom';
import { browserChecker } from 'utils';

const AuthHeaderComponent = ({ user }) => {
	const handleClick = () => {
		const browser = browserChecker();

		if (browser === 'Google Chrome or Chromium') {
			window.open('https://google.com/');
		} else if (browser === 'Mozilla Firefox') {
			window.open('https://www.mozilla.org/en-US/firefox/new/');
		} else {
			alert('Gravy extension is not available for current browser');
		}
	};

	return (
		<>
			<div className="fullBg">
				<Container fluid={true}>
					<Navbar collapseOnSelect expand="lg" className="header1">
						<Link to="/app/customer/dashboard">
							<Navbar.Brand>
								<img src={GravyIcon} alt="gravy icon" className="my-3 " />
							</Navbar.Brand>
						</Link>
						<Navbar.Toggle aria-controls="responsive-navbar-nav" />
						<Navbar.Collapse id="responsive-navbar-nav">
							<Nav className="mx-auto"></Nav>
							<Nav className="my-4 mr-1">
								<Link
									to="/app/customer/dashboard"
									className="mx-4 mt-2 text-decoration-none"
									style={{ color: 'black' }}
								>
									<h5>
										<b>Dashboard</b>
									</h5>
								</Link>

								<Link
									to="/app/customer/CustomerGiftCardRedeemRNA"
									className="mx-4 mt-2 text-decoration-none"
									style={{ color: 'black' }}
								>
									<h5>
										<b>Redeem Gift Cards</b>
									</h5>
								</Link>
								<Link
									to="/app/customer/referFriend"
									style={{ color: 'black' }}
									className="mx-4 mt-2 text-decoration-none"
								>
									<h5>
										<b>Refer A Friend</b>
									</h5>
								</Link>
								<Link
									to="/app/customer/profile"
									className="mx-4 text-decoration-none mt-2"
								>
									<Row className="header1">
										<Col>
											<div className="d-flex">
												{user &&
												user.status &&
												user.status.bioVerified &&
												user.status.phoneNumberVerified &&
												user.status.emailVerified ? (
													<PendingSuccess
														type="success"
														width="35px"
														height="35px"
													/>
												) : (
													<PendingSuccess
														type="pending"
														width="35px"
														height="35px"
													/>
												)}
												<div className="ml-3">
													<h5 className="accounts">
														<b style={{ color: 'black' }}>Your Account</b>
													</h5>
													{user &&
													user.status &&
													user.status.bioVerified &&
													user.status.phoneNumberVerified &&
													user.status.emailVerified ? (
														<small
															style={{ fontSize: '11px', color: '#50BFA5' }}
														>
															Status: Verified
														</small>
													) : (
														<small
															style={{ fontSize: '11px', color: '#F47458' }}
														>
															Status: Pending
														</small>
													)}
												</div>
											</div>
										</Col>
									</Row>
								</Link>
								<Nav.Link className="pt-xl-0 pt-lg-0 pt-3 pl-4">
									<Button className="button shadow-none" onClick={handleClick}>
										<b>Install Extension</b>
									</Button>
								</Nav.Link>
							</Nav>
						</Navbar.Collapse>
					</Navbar>
				</Container>
			</div>
		</>
	);
};

export default AuthHeaderComponent;

// const mapStateToProps = (state) => ({
// 	profile: state.profile,
// });

// export default connect(mapStateToProps, {
// 	fetchProfileAction,
// })(withRouter(AuthHeaderComponent));
