import React from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import gravyBig from '../../../../assets/images/GravyBig.svg';
import google from '../../../../assets/images/googles.svg';
import Firefox from '../../../../assets/images/Firefox.svg';
import Opera from '../../../../assets/images/Opera.svg';
import Edge from '../../../../assets/images/Edge.svg';
import Download from '../../../../assets/images/Download.svg';
import './footer.css';

const AuthFooterComponent = () => {
	const handleClickChrome = () => {
		window.open('https://google.com/');
	};
	const handleClickFirefox = () => {
		window.open('https://www.mozilla.org/en-US/firefox/new/');
	};

	const handleClickOpera = () => {
		alert('This extension is not availabe yet.');
	};

	const handleClickEdge = () => {
		alert('This extension is not available yet.');
	};

	return (
		<>
			<div className="fullBg authFullFooter">
				<footer>
					<Container fluid className="header1 mt-5">
						<Row className="py-5 px-xl-5 px-lg-5 px-md-2 ">
							<Col xl={2} lg={2} md={3} sm={11} xs={11} className=" allfooter ">
								<img src={gravyBig} alt="icon" className="img-fluid" />
							</Col>
							<Col xl={10} lg={10} md={10} sm={10} xs={10}>
								<Row>
									<Col
										xl={3}
										xl={{ offset: 0 }}
										lg={3}
										lg={{ offset: 0 }}
										md={4}
										md={{ offset: 2 }}
										sm={6}
										xs={8}
									>
										<ul className="footerbtn">
											<li className="mt-3 pr-2 footerHeading">Company</li>
											<li className="pt-5 ">
												<a
													className="link text-decoration-none footerText"
													href="#"
												>
													About
												</a>
											</li>
											<li className="pt-3">
												<a
													className="link text-decoration-none footerText"
													href="#"
												>
													Help
												</a>
											</li>
											<li className="pt-3">
												<a
													className="link text-decoration-none footerText"
													href="#"
												>
													Media
												</a>
											</li>
											<li className="pt-3 pb-3">
												<a
													className="link text-decoration-none footerText"
													href="#"
												>
													News
												</a>
											</li>
										</ul>
									</Col>
									<Col xl={3} lg={3} md={5} sm={8} xs={8}>
										<ul className="footerbtn">
											<li className="mt-3 footerHeading">Legal</li>
											<li className="pt-5">
												<a
													className="link text-decoration-none footerText"
													href="#"
												>
													Terms
												</a>
											</li>
											<li className="pt-3">
												<a
													className="link text-decoration-none footerText"
													href="#"
												>
													Privacy
												</a>
											</li>
											<li className="pt-3">
												<a
													className="link text-decoration-none footerText"
													href="#"
												>
													Copyright
												</a>
											</li>
										</ul>
									</Col>
									<Col
										xl={3}
										xl={{ offset: 0 }}
										lg={3}
										lg={{ offset: 0 }}
										md={4}
										md={{ offset: 2 }}
										sm={8}
										xs={8}
									>
										<ul className="footerbtn">
											<li className="mt-3 footerHeading">Follow Us</li>
											<li className="pt-5">
												<a
													className="link text-decoration-none footerText"
													href="#"
												>
													Facebook
												</a>
											</li>
											<li className="pt-3">
												<a
													className="link text-decoration-none footerText"
													href="#"
												>
													Twitter
												</a>
											</li>
											<li className="pt-3">
												<a
													className="link text-decoration-none footerText"
													href="#"
												>
													Instagram
												</a>
											</li>
										</ul>
									</Col>
									<Col xl={3} lg={3} md={6} sm={8} xs={8}>
										<ul className="footerbtn">
											<li className="mt-3 footerHeading">
												Install the extension
											</li>
											<li className="pt-5">
												<Button
													className="shadow-none footerBtnSocial mb-2"
													onClick={handleClickChrome}
												>
													<span className="btn-label">
														<img src={google} alt="chrome" className="py-1" />
													</span>
													<b>Chrome</b>
													<img
														src={Download}
														alt="download"
														style={{ paddingLeft: '28px' }}
													/>
												</Button>
											</li>
											<li>
												<Button
													className="shadow-none footerBtnSocial mb-2"
													onClick={handleClickFirefox}
												>
													<span className="btn-label firefox">
														<img src={Firefox} alt="firefox" className="py-1" />
													</span>
													<b>Firefox</b>
													<img
														src={Download}
														alt="download"
														style={{ paddingLeft: '33px' }}
													/>
												</Button>
											</li>
											<li>
												<Button
													className="shadow-none footerBtnSocial mb-2"
													onClick={handleClickOpera}
												>
													<span className="btn-label opera">
														<img src={Opera} alt="Opera" className="py-1" />
													</span>
													<b>Opera</b>
													<img
														src={Download}
														alt="download"
														style={{ paddingLeft: '37px' }}
													/>
												</Button>
											</li>
											<li>
												<Button
													className="shadow-none footerBtnSocial mb-2"
													onClick={handleClickEdge}
												>
													<span className="btn-label edge">
														<img src={Edge} alt="edge" className="py-1" />
													</span>
													<b>Edge</b>
													<img
														src={Download}
														alt="download"
														style={{ paddingLeft: '45px' }}
													/>
												</Button>
											</li>
										</ul>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row className="pb-4 pl-4">
							<Col>
								<small>© 2021 Gravy. All Rights Reserved.</small>
							</Col>
						</Row>
						{/* <Row className="pt-4"></Row> */}
					</Container>
				</footer>
			</div>
		</>
	);
};

export default AuthFooterComponent;
