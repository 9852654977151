import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Group from '../../../assets/images/Group.svg';
import './Gift.css';
import PendingSuccess from '../../common/PendingSuccess';

const RedeemGiftCardComponent = ({ user }) => {
	let coinsRequired = 100;

	if (user) {
		coinsRequired = 100 - parseInt(user.totalVerifiedCoin);

		if (coinsRequired < 0) {
			coinsRequired = 0;
		}
	}

	return (
		<>
			<div className="fullBg">
				<Container fluid>
					<Row className="header1">
						<Col xl={4} lg={12} md={12} sm={12} className="starbucksGirl">
							<img
								src={Group}
								alt="Redeem"
								className="img-fluid ml-md-5 mt-5 pt-5 redeemImg"
							/>
						</Col>
						<Col xl={8} lg={12} md={12} sm={12}>
							<Row className="mt-5 mx-xl-5 mx-lg-5 mx-md-5 mx-sm-5 mx-6">
								<Col xl={12} className="mt-4 ">
									<h1 className="heading2">Redeem your gift card</h1>
									<h5 className="heading4">
										For more information about gift card redeems and
										requirements per country,&nbsp;
										<a href="#" className="p1 text-decoration-none">
											click here
										</a>
										.
									</h5>
								</Col>
							</Row>
							<Row className="mt-5 mx-xl-5 mx-lg-5 mx-md-5 coinsDown">
								<Col
									xl={6}
									lg={6}
									md={12}
									sm={12}
									xs={12}
									style={{ backgroundColor: '#FCF9F2', padding: '0px ' }}
								>
									<div className="pt-5 d-flex imgSuccess">
										<PendingSuccess
											type="sucess"
											width="50px"
											className="img-fluid mx-4"
										/>

										<h1 className="cointext">
											Total coins: <b>{user.totalVerifiedCoin}</b>
										</h1>
									</div>
								</Col>
								<Col
									xl={6}
									lg={6}
									md={12}
									sm={12}
									xs={12}
									style={{ backgroundColor: '#FCF9F2' }}
									className="py-5 d-flex"
								>
									<PendingSuccess
										type="pending"
										width="50px"
										className="img-fluid mr-4 imgPending"
									/>
									<h1 className="cointext">
										Coins required: <b>{coinsRequired}</b>
									</h1>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default RedeemGiftCardComponent;
