import * as ActionTypes from '../../actions';

const initialState = {
	isPaymentSaving: false,
	data: [],
	apiErrors: [],
};
const StripeDeleteReducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.STRIPE_DELETE:
			return { ...state, isPaymentSaving: true };

		case ActionTypes.STRIPE_DELETE_SUCCESS:
			return {
				...state,
				isPaymentSaving: false,
				data: action.apiResponse.data ? action.apiResponse.data : null,
				apiErrors: null,
			};

		case ActionTypes.STRIPE_DELETE_FAILURE:
			return {
				...state,
				isPaymentSaving: false,
				data: null,
				apiErrors: action.apiResponse.error ? action.apiResponse.errors : null,
			};
		default:
			return state;
	}
};

export default StripeDeleteReducer;
