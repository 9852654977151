import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Button, Form } from 'react-bootstrap';
import PendingSuccess from '../../common/PendingSuccess';
import Stripe from '../../../assets/images/Stripe.svg';
import '../../Advertiser/AdvertiserProfileComponent/profileComponent.css';
import {
	CardElement,
	useElements,
	useStripe,
	CardNumberElement,
	CardCvcElement,
	CardExpiryElement,
} from '@stripe/react-stripe-js';
import { stripePaymentAction } from '../../../actions';
import { useDispatch } from 'react-redux';
import { ClipLoader } from 'react-spinners';

const ErrorMessage = ({ children }) => (
	<div
		className="pt-2"
		style={{ color: '#fbaa26', fontWeight: '700', fontSize: '80%' }}
	>
		{children}
	</div>
);

const PendingPaymentDetail = ({
	stripePayment: { isPaymentSaving, data, apiErrors },
	user,
	history,
}) => {
	const [serverError, setServerErrors] = useState({});
	const [stateList, setSateList] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const stripe = useStripe();
	const elements = useElements();
	const [error, setError] = useState(null);
	const [cardComplete, setCardComplete] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [paymentMethod, setPaymentMethod] = useState(null);

	const CARD_OPTIONS = {
		showIcon: true,
		// iconStyle: 'solid',
		style: {
			border: '2px solid red',
			base: {
				iconColor: '#fdbf00',
				color: 'black',
				// fontWeight: 500,
				fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
				fontSize: '16px',
				fontSmoothing: 'antialiased',
				':-webkit-autofill': {
					// color: '#fce883',
				},

				'::placeholder': {
					// color: '#87bbfd',
				},
				border: '2px solid red',
			},
			invalid: {
				iconColor: '#ffc7ee',
				// color: '#ffc7ee',
			},
		},
	};

	useEffect(() => {
		if (!isPaymentSaving && !apiErrors) {
			setLoading(false);
			history.go(0);
		}
	}, [isPaymentSaving, data, apiErrors]);
	const handleSubmit = async (event) => {
		setLoading(true);
		event.preventDefault();
		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			setLoading(false);
			return;
		}

		const payload = await stripe.createPaymentMethod({
			type: 'card',
			card: elements.getElement(CardNumberElement),
		});
		// console.log(payload.error);
		if (payload.error) {
			// alert(JSON.stringify(payload.error));
			setLoading(false);
			setError(payload.error.message);
		} else if (!payload.paymentMethod) {
			setLoading(false);
			setError(`Something went wrong please check your card data again!`);
		} else {
			dispatch(stripePaymentAction(payload));
		}
	};
	return (
		<>
			<div
				className={
					user && user.status && user.status.paymentVerified ? 'line2' : 'line1'
				}
			>
				<div className="position-relative ml-3  ml-md-5 ml-sm-4  ml-xm-3  ">
					<Row className="mt-5">
						<div className="col-xl-11 col-lg-11  col-md-8  col-sm-7 pt-4 ">
							<div
								className="d-flex "
								style={{ justifyContent: 'space-between' }}
							>
								<div style={{ width: '75%' }}>
									<h1 className="profileHeading"> Payment Details</h1>
									<p>
										The payment details below will be charged weekly and is
										calculated by the number of users that visit your website
										times the cost per user. You will not be charged for your
										first 20 users.
									</p>
								</div>
								<div>
									{user && user.status && user.status.paymentVerified ? (
										<PendingSuccess
											className="mt-3 "
											type="success"
											width="50px"
										/>
									) : (
										<PendingSuccess
											className="mt-3 "
											type="pending"
											width="50px"
										/>
									)}
								</div>
							</div>
						</div>
					</Row>
					<Row>
						<Col xl={10} lg={10} md={12} sm={12} xs={12}>
							<Form onSubmit={handleSubmit} noValidate>
								<Row>
									<Col xl={6} lg={12} md={6} sm={12} xs={12}>
										<CardNumberElement
											// className="StripeElement"
											options={CARD_OPTIONS}
											onReady={() => {
												console.log('CardElement [ready]', CARD_OPTIONS);
											}}
											onChange={(event) => {
												// setError(event.error);
												setCardComplete(event.complete);
												console.log('CardElement [change]', event);
											}}
											onBlur={() => {
												console.log('CardElement [blur]');
											}}
											onFocus={() => {
												console.log('CardElement [focus]');
											}}
										/>
									</Col>
									<Col xl={3} lg={6} md={3} sm={12} xs={6}>
										<CardCvcElement
											// className="mb-4"
											options={CARD_OPTIONS}
											onReady={() => {
												console.log('CardElement [ready]', CARD_OPTIONS);
											}}
											onChange={(event) => {
												// setError(event.error.message);
												// setCardComplete(event.complete);
												console.log('CardElement [change]', event);
											}}
											onBlur={() => {
												console.log('CardElement [blur]');
											}}
											onFocus={() => {
												console.log('CardElement [focus]');
											}}
										/>
									</Col>
									<Col xl={3} lg={6} md={3} sm={12} xs={6}>
										<CardExpiryElement
											options={CARD_OPTIONS}
											onReady={() => {
												console.log('CardElement [ready]', CARD_OPTIONS);
											}}
											onChange={(event) => {
												// setError(event.error);
												// setCardComplete(event.complete);
												console.log('CardElement [change]', event);
											}}
											onBlur={() => {
												console.log('CardElement [blur]');
											}}
											onFocus={() => {
												console.log('CardElement [focus]');
											}}
										/>
									</Col>
								</Row>
								{error && <ErrorMessage>{error}</ErrorMessage>}
								<img src={Stripe} alt="pending" className="pt-4 pb-2" />
								<Button
									type="submit"
									className="loginButton   my-4 "
									disabled={isLoading}
								>
									{isLoading ? (
										<ClipLoader size={20} color="#FCF9F2" />
									) : (
										`Save`
									)}
								</Button>
							</Form>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	stripePayment: state.stripePayment,
});

export default connect(mapStateToProps, {
	stripePaymentAction,
})(withRouter(PendingPaymentDetail));
