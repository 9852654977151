import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
	SignInComponent,
	// Register,
	// ForgotPassword,
	// LandingPage,
	SignUpComponent,
	AdvertiserSignUpComponent,
	// EmailVerification,
} from 'components';
import { PasswordReset, NotFound, EmailEnter } from 'components/common';

function PublicRoutes() {
	return (
		<Fragment>
			<Switch>
				<Route exact path="/">
					{/* <LandingPage /> */}
					<SignInComponent />
				</Route>
				<Route exact path="/forgot-password">
					<EmailEnter />
				</Route>
				<Route exact path="/register/:referralCode?">
					<SignUpComponent />
				</Route>
				<Route exact path="/login">
					<SignInComponent />
				</Route>
				<Route exact path="/business-register">
					<AdvertiserSignUpComponent />
				</Route>
				<Route exact path="/password-reset/:resetPasswordCode">
					<PasswordReset />
				</Route>

				<Route component={NotFound} />
			</Switch>
		</Fragment>
	);
}

export default PublicRoutes;
