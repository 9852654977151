// import * as ActionTypes from '../../actions/home'
import * as ActionTypes from '../../actions/';

const initialState = {
	isLoading: false,
	tableData: [],
	apiErrors: [],
};

const AdvertiserDashboardTableReducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.ADVERTISER_DASHBOARD_TABLE:
			return { ...state, isLoading: true };

		case ActionTypes.ADVERTISER_DASHBOARD_TABLE_SUCCESS:
			return {
				...state,
				isLoading: false,
				tableData: action.apiResponse ? action.apiResponse.data : null,
				apiErrors: null,
			};

		case ActionTypes.ADVERTISER_DASHBOARD_TABLE_FAILURE:
			return {
				...state,
				isLoading: false,
				tableData: null,
				apiErrors: action.apiResponse ? action.apiResponse : null,
			};
		default:
			return state;
	}
};

export default AdvertiserDashboardTableReducer;
