import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import PendingSuccess from '../../common/PendingSuccess';
// import checkSmall from '../../../assets/images/CheckSmall.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSVLink } from 'react-csv';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import TableBaseComponent from '../../common/TableBaseComponent';
import PaginationComponent from '../../common/Pagination';
import './AdminInvoicesTableStyle.css';
import ColumnFilterText from './AdminColumnFilterText';
import { InvoicesTableAction } from '../../../actions';

const AdminInvoiceTableComponent = ({
	invoicesTable: { apiErrors, isLoading, tableData },
	InvoicesTableAction,
	setTotalWebsiteUsers,
}) => {
	const [show, setShow] = useState(false);
	// const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [filter, setFilter] = useState({
		filter: [],
		sorting: [],
		pagination: [],
	});
	let csv = [];

	tableData &&
		tableData.map((item, index) => {
			item.verifiedAt = new Date(item.verifiedAt).toLocaleDateString('en-US');

			csv[index] = {
				description: item.description,
				costPerCoin: item.costPerCoin,
				verifiedAt: item.verifiedAt,
				companyName: item.companyName,
				invoiceUrl: item.invoiceUrl,
			};
		});
	useEffect(() => {
		setTotalWebsiteUsers(
			tableData && tableData[0] && tableData[0].totalWebsiteUsers
		);
		console.log(tableData);
	}, [tableData]);

	const headers = [
		{ label: 'Description', key: 'description' },
		{ label: 'Cost per customer', key: 'costPerCoin' },
		{ label: 'Time', key: 'verifiedAt' },
		{ label: 'Company', key: 'companyName' },
		{ label: 'Invoice URL', key: 'invoiceUrl' },
	];

	useEffect(() => {
		InvoicesTableAction(filter);
	}, []);

	const handleTableAction = () => {
		InvoicesTableAction(filter);
	};

	const COLUMNS = [
		{
			Header: () => {
				return (
					<>
						<div className="header-wrapper spacingTable">
							<span>Description</span>
							{/* <ColumnFilterText /> */}
						</div>
					</>
				);
			},
			accessor: 'description',
		},
		{
			Header: () => {
				return (
					<>
						<div
							className="header-wrapper spacingTable"
							onClick={() => {
								filter['sorting'] &&
								filter['sorting']['costPerCoin'] &&
								filter['sorting']['costPerCoin'] == 'ASC'
									? (filter['sorting']['costPerCoin'] = 'DESC')
									: (filter['sorting'] = {
											...filter['sorting'],
											costPerCoin: 'ASC',
									  });

								setFilter(filter);
								handleTableAction();
							}}
						>
							<span>Cost per customer &nbsp; &nbsp;</span>
							<span className="tableSortIcon" style={{ cursor: 'pointer' }}>
								{filter['sorting'] &&
								filter['sorting']['costPerCoin'] &&
								filter['sorting']['costPerCoin'] == 'ASC' ? (
									<FontAwesomeIcon icon={faCaretDown} />
								) : (
									<FontAwesomeIcon icon={faCaretUp} />
								)}
							</span>
						</div>
					</>
				);
			},
			accessor: 'costPerCoin',
		},
		{
			Header: () => (
				<div
					className="header-wrapper spacingTable"
					onClick={() => {
						filter['sorting'] &&
						filter['sorting']['verifiedAt'] &&
						filter['sorting']['verifiedAt'] == 'ASC'
							? (filter['sorting']['verifiedAt'] = 'DESC')
							: (filter['sorting'] = {
									...filter['sorting'],
									verifiedAt: 'ASC',
							  });

						setFilter(filter);
						handleTableAction();
					}}
				>
					<span style={{ marginRight: '-3em' }}>
						Time &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
						&nbsp;&nbsp; &nbsp;
					</span>
					<span className="tableSortIcon" style={{ cursor: 'pointer' }}>
						{filter['sorting'] &&
						filter['sorting']['verifiedAt'] &&
						filter['sorting']['verifiedAt'] == 'ASC' ? (
							<FontAwesomeIcon icon={faCaretDown} />
						) : (
							<FontAwesomeIcon icon={faCaretUp} />
						)}
					</span>
				</div>
			),
			accessor: 'verifiedAt',
		},
		{
			Header: () => {
				return (
					<div className="header-wrapper" style={{ marginRight: '-5em' }}>
						<span className="spacingTable">Company</span>
						&emsp;&emsp;&emsp;
						<ColumnFilterText
							filter={filter}
							setFilter={setFilter}
							handleTableAction={handleTableAction}
						/>
					</div>
				);
			},

			accessor: 'companyName',
		},
		{
			Header: () => {
				return (
					<div className="header-wrapper spacingTable">
						<span>Action</span>
					</div>
				);
			},
			accessor: 'invoiceUrl',
			Cell: ({ cell: { value } }) => (
				<a href={value} target="_blank">
					<Button className="button shadow-none">
						<b>View</b>
					</Button>
				</a>
			),
		},
	];

	return (
		<>
			<Row className="mt-5">
				<Col xl={{ span: 12 }} className="p-5 mt-3">
					<CSVLink
						data={csv && csv}
						headers={headers}
						filename={'Admin Invoice Data.csv'}
						onClick={() => {
							if (csv && csv.length == 0) {
								return false;
							} else {
								return true;
							}
						}}
					>
						<Button
							className="button shadow-none float-right"
							disabled={csv && csv.length == 0 ? true : false}
						>
							<b>Export to CSV</b>
						</Button>
					</CSVLink>
					<TableBaseComponent COLUMNS={COLUMNS} DATA={tableData && tableData} />
					<PaginationComponent
						filter={filter}
						setFilter={setFilter}
						handleTableAction={handleTableAction}
						totalUser={tableData && tableData[0] && tableData[0].totalRecord}
					/>
				</Col>
			</Row>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		invoicesTable: state.invoicesTable,
	};
};

export default connect(mapStateToProps, { InvoicesTableAction })(
	AdminInvoiceTableComponent
);
// export default TableComponent;
