import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import happyGirl from '../../../assets/images/HappyGirl.svg';
// import Line from '../../../assets/images/Line.svg';
import PendingSuccess from '../../common/PendingSuccess';
// import AuthAdminHeaderComponent from '../../common/Advertiser Header/HeaderComponent';
// import FooterComponent from '../../common/Auth/FooterComponent/FooterComponent';
import AdminTableComponent from './AdminTableComponent';
import { DashbaordAction } from '../../../actions';
import { connect } from 'react-redux';
import {
	AuthFooterComponent,
	AuthAdminHeaderComponent,
} from 'components/common';
import './AdminMemberTableStyle.css';
const AdminMemberDashboard = ({
	dashboard: { apiErrors, isLoading, user },
	DashbaordAction,
}) => {
	const [totalMember, setTotalMember] = useState({
		totalCustomers: 0,
		totalCompanies: 0,
	});

	// useEffect(() => {
	// 	DashbaordAction();
	// }, []);
	return (
		<>
			<div className="authfullContainer">
				<AuthAdminHeaderComponent user={user && user} />
				<div className="fullBg">
					<Container fluid className="font">
						<Row className="header1 pt-5">
							<Col
								xl={4}
								lg={12}
								md={10}
								sm={12}
								xs={12}
								className="happy-girl-block"
							>
								<img
									className="img-fluid"
									src={happyGirl}
									alt="happy girl"
									className="adninhappy-girls"
								/>
							</Col>
							<Col xl={8} lg={12} md={12}>
								<Row className="pt-5 mt-4">
									<Col className="pr-0 col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12">
										<h1 className="MemberMaxText ml-xl-5 ml-lg-5 ml-md-5 ml-sm-5 ml-xs-5 ml-3">
											Welcome Back
											<b>
												{user && user.status && user.status.bioVerified
													? `, ${user && user.fullName}`
													: ''}
											</b>
										</h1>
										<h4 className="MemberCoinSummary  ml-xl-5 ml-lg-5 ml-md-5 ml-sm-5 ml-xs-5 ml-3">
											Here are the Gravy members
										</h4>
									</Col>
								</Row>
								<Row className="mt-4 mx-xl-5 mx-lg-5 mx-md-5 coins-positions">
									<Col
										xl={6}
										lg={6}
										md={12}
										sm={12}
										xs={12}
										style={{ backgroundColor: '#FCF9F2', padding: '0px ' }}
									>
										{/* <img src={Check} className="img-fluid pr-5 " /> */}
										<div className="pt-5 d-flex imgSuccess">
											<PendingSuccess
												type="sucess"
												width="50px"
												className="img-fluid mx-xl-4 mx-lg-4 mx-md-4 mx-sm-4 mx-4"
											/>

											<h1 className="cointext">
												Customers:{' '}
												<b>{totalMember && totalMember.totalCustomers}</b>
											</h1>
										</div>
									</Col>

									<Col
										xl={6}
										lg={6}
										md={12}
										sm={12}
										xs={12}
										style={{ backgroundColor: '#FCF9F2' }}
										className="py-5 d-flex coinPadding"
									>
										<PendingSuccess
											type="success"
											width="50px"
											className="img-fluid mr-4 imgPending"
										/>
										<h1 className="cointext">
											Companies:{' '}
											<b>{totalMember && totalMember.totalCompanies}</b>
										</h1>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</div>
				<Container fluid className="mt-5 font">
					<AdminTableComponent
						totalMember={totalMember}
						setTotalMember={setTotalMember}
					/>
				</Container>
			</div>
			<AuthFooterComponent />
		</>
	);
};

const mapStateToProps = (state) => ({
	dashboard: state.dashboard,
});

export default connect(mapStateToProps, {
	DashbaordAction,
})(withRouter(AdminMemberDashboard));
