import React, { memo } from 'react';
import { TopNav } from './common';

const navOptions = [
	{ title: 'Login', path: '/login' },
	{ title: 'Register', path: '/register' },
];

function LandingPage() {
	return (
		<div className="w3-display-container">
			<TopNav routes={navOptions} />
		</div>
	);
}

export default memo(LandingPage);
