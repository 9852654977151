// import * as ActionTypes from '../../actions/home'
import * as ActionTypes from '../../actions';

const initialState = {
	isLoadingInvitation: false,
	referral: [],
	apiErrors: [],
};

const ReferralInvitation = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.REFERRAL_INVITATION:
			return { ...state, isLoadingInvitation: true };

		case ActionTypes.REFERRAL_INVITATION_SUCCESS:
			return {
				...state,
				isLoadingInvitation: false,
				referral: action.apiResponse.data ? action.apiResponse.data : null,
				apiErrors: null,
			};

		case ActionTypes.REFERRAL_INVITATION_FAILURE:
			return {
				...state,
				isLoadingInvitation: false,
				referral: null,
				apiErrors: action.apiResponse.error ? action.apiResponse.errors : null,
			};
		default:
			return state;
	}
};

export default ReferralInvitation;
