// import * as ActionTypes from '../../actions/home'
import * as ActionTypes from '../../../actions';

const initialState = {
	isLoading: false,
	phoneNumberStatus: {
		valid: true,
	},
	// data:[],
	apiErrors: [],
};

export const PHONE_NUMBER = 'PHONE_NUMBER';
export const PHONE_NUMBER_SUCCESS = 'PHONE_NUMBER_SUCCESS';
export const PHONE_NUMBER_FAILURE = 'PHONE_NUMBER_FAILURE';

export const PhoneNumberReducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.PHONE_NUMBER:
			return { ...state, isLoading: true };

		case ActionTypes.PHONE_NUMBER_SUCCESS:
			return {
				...state,
				isLoading: false,
				phoneNumberStatus: action.apiResponse.data
					? action.apiResponse.data
					: null,
				apiErrors: null,
			};

		case ActionTypes.PHONE_NUMBER_FAILURE:
			return {
				...state,
				isLoading: false,
				phoneNumberStatus: [],
				apiErrors: action.apiResponse.error ? action.apiResponse.errors : null,
			};
		default:
			return state;
	}
};
