import {
	ManualApiCall,
	ManualAuthApiCall,
} from '../../middlewares/apiMiddleware';
import { toQueryString } from '../../utils/index';
import history from 'utils/history';
export const INVOICES_TABLE = 'INVOICES_TABLE';
export const INVOICES_TABLE_SUCCESS = 'INVOICES_TABLE_SUCCESS';
export const INVOICES_TABLE_FAILURE = 'INVOICES_TABLE_FAILURE';
// export const LOGOUT = 'LOGOUT';

export const InvoicesTableAction = (filter) => async (dispatch) => {
	const queryString = toQueryString(filter);

	dispatch({
		type: INVOICES_TABLE,
	});

	const apiRequest = await ManualAuthApiCall(
		`/auth/admin/invoice?${queryString}`,
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			// body: JSON.stringify(filter),
		},
		dispatch,
		INVOICES_TABLE_FAILURE,
		history
	);
	console.log('apiRequest for first action', apiRequest);
	if (apiRequest.error === false) {
		dispatch({
			type: INVOICES_TABLE_SUCCESS,
			apiResponse: apiRequest,
		});
		// history.push('/app/dashboard');
	}
};
