import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Dropdown, Button, Form } from 'react-bootstrap';
import '../AdminMemberView/MemberView.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

export const ColumnFilterCheckbox = ({
	filter,
	setFilter,
	handleTableAction,
}) => {
	let [coinEntered, setCoinEntered] = useState(false);

	useEffect(() => {
		setCoinEntered(filter.checkFilter.coinEntered);
	}, [filter]);

	const handleSubmit = (e) => {
		// e.preventDefault();
		filter['checkFilter'] = {
			...filter['checkFilter'],
			coinEntered: coinEntered,
		};

		setFilter(filter);
		handleTableAction();
		console.log('filter', filter);
	};

	const handleReset = (e) => {
		// e.preventDefault();
		filter['checkFilter'] = {
			...filter['checkFilter'],
			coinEntered: false,
		};

		setFilter(filter);
		handleTableAction();
		console.log('filter', filter);
	};

	// const [values, setValues] = useState({
	// 	value1: false,
	// 	value2: false,
	// 	value3: false,
	// });
	// const [clicked, setClicked] = useState(false);
	// const handleClicked = () => {
	// 	setClicked(!clicked);
	// };
	// const onChangeCheckbox = (e) => {
	// 	console.log('====name', e.target.name, '===value', typeof e.target.value);
	// 	setValues({
	// 		...values,
	// 		[e.target.name]: e.target.value,
	// 	});
	// };
	// console.log('---values', values);
	return (
		<>
			<Dropdown>
				<Dropdown.Toggle
					id="dropdown-basic"
					className="dropdown-icon shadow-none rounded-0"
					style={{ paddingTop: '30px', paddingBottom: '30px' }}
					variant="none"
				>
					<FontAwesomeIcon icon={faFilter} className="img-fluid filter" />
				</Dropdown.Toggle>

				<Dropdown.Menu align="right">
					<Container id="checkBox ">
						<Row>
							<Col>
								<Form onSubmit={handleSubmit}>
									<div className="d-flex py-1">
										<div className="checkbox-wrapper1">
											<input
												id="test1"
												type="checkbox"
												name="coinEntered"
												value={coinEntered}
												onChange={(e) => {
													setCoinEntered(e.target.checked);
												}}
												checked={coinEntered}
											/>
											<label htmlFor="test1"></label>
										</div>
										<span className="columnFilterCheckbox">Coins Entered</span>
									</div>
									{/* <div className="d-flex py-1">
										<div className="checkbox-wrapper2">
											<input
												id="test2"
												type="checkbox"
												name="value2"
												onChange={onChangeCheckbox}
												value={values.value2}
												// checked={values.value2}
												// onChange={(e) => {
												// 	setFieldValue('termsCheck', e.target.checked);
												// }}
												// value={values.termsCheck}
											/>
											<label htmlFor="test2"></label>
										</div>
										<span className="columnFilterCheckbox">Website View</span>
									</div>
									<div className="d-flex py-1">
										<div className="checkbox-wrapper3">
											<input
												id="test3"
												type="checkbox"
												name="value3"
												onChange={onChangeCheckbox}
												value={values.value3}
												// checked={values.value3}
												// onChange={(e) => {
												// 	setFieldValue('termsCheck', e.target.checked);
												// }}
												// value={values.termsCheck}
											/>
											<label htmlFor="test3"></label>
										</div>
										<span className="columnFilterCheckbox">Advert View</span>
									</div>
									{/* <Form.Group controlId="referral">
										<Form.Check
											type="checkbox"
											label="Referral"
											// value={filterValue || ""}
											// onChange={(e) => setFilter(e.target.value)}
										/>
									</Form.Group>
									<Form.Group controlId="websiteView">
										<Form.Check type="checkbox" label="Website View" />
									</Form.Group>
									<Form.Group controlId="advertView">
										<Form.Check type="checkbox" label="Advert View" />
									</Form.Group> */}
									<Dropdown.Divider />
									<Button
										variant="none"
										type="submit"
										className="dropdown-reset-button shadow-none"
										onClick={handleReset}
									>
										<b>Reset</b>
									</Button>
									<Button
										variant="warning"
										type="submit"
										className="ml-2 shadow-none"
									>
										<b>OK</b>
									</Button>
								</Form>
							</Col>
						</Row>
					</Container>
				</Dropdown.Menu>
			</Dropdown>

			{/* <DropdownButton
				menuAlign="right"
				title={<FontAwesomeIcon icon={faFilter} className="img-fluid" />}
				id="dropdown-menu-align-right"
				className="dropdown-icon"
				variant="none"
				onClick={handleClicked}
			>
				<Dropdown.Item id="checkBox">
					<Row>
						<Col>
							<Form>
								<Form.Group controlId="referral">
									<Form.Check type="checkbox" label="Referral" />
								</Form.Group>
								<Form.Group controlId="websiteView">
									<Form.Check type="checkbox" label="Website View" />
								</Form.Group>
								<Form.Group controlId="advertView">
									<Form.Check type="checkbox" label="Advert View" />
								</Form.Group>
								<Dropdown.Divider className="dropdown-divider" />
								<Button
									variant="none"
									className="dropdown-reset-button shadow-none"
								>
									Reset
								</Button>
								<Button
									variant="warning"
									type="submit"
									className="ml-3 shadow-none"
								>
									Ok
								</Button>
							</Form>
						</Col>
					</Row>
				</Dropdown.Item>
			</DropdownButton> */}
		</>
	);
};
