import React, { Component } from 'react';
import Routes from 'routes';
import './index.css';

class App extends Component {
	render() {
		return (
			<div className=" p-md-0" style={{ height: '100vh' }}>
				<Routes />
			</div>
		);
	}
}

export default App;
