import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PendingSuccess from '../PendingSuccess';
import { stripeDeleteAction } from '../../../actions';
import history from 'utils/history';
import Visa from '../../../assets/images/Visa.svg';
import mastercard from '../../../assets/images/mastercard.svg';
import amex from '../../../assets/images/amex.svg';
import discover from '../../../assets/images/discover.svg';
import dinersclub from '../../../assets/images/dinersclub.svg';
import '../../Advertiser/AdvertiserProfileComponent/profileComponent.css';

const AdminSuccessPaymentDetail = ({
	stripeDelete: { isPaymentSaving, data, apiErrors },
	stripeDeleteAction,
	user,
}) => {
	const [isLoading, setLoading] = useState(false);

	useEffect(() => {
		if (!isPaymentSaving && !apiErrors) {
			setLoading(false);
			history.go(0);
		}
	}, [isPaymentSaving, apiErrors]);
	const date = new Date(
		user && user.stripePayment && user.stripePayment.created * 1000
	).toLocaleDateString('en-US');
	return (
		<>
			<div
				className={
					user && user.status && user.status.paymentVerified ? 'line2' : 'line1'
				}
			>
				<div className="position-relative ml-3  ml-md-5 ml-sm-4  ml-xm-3 ">
					<Row className="mt-5 ">
						<div className="col-xl-11 col-lg-11  col-md-11  col-sm-8   col-sx-7 mt-3">
							<div
								className="d-flex"
								style={{ justifyContent: 'space-between' }}
							>
								<div style={{ width: '75%' }}>
									<h1 className="profileHeading">Payment Details</h1>
								</div>
								<div className="mb-3">
									{user && user.status && user.status.paymentVerified ? (
										<PendingSuccess className="" type="success" width="50px" />
									) : (
										<PendingSuccess className="" type="pending" width="50px" />
									)}
								</div>
							</div>
						</div>
					</Row>

					<Row>
						<Col xl={5} lg={5} md={6} sm={6} xs={12}>
							<div className="border px-4 py-3" style={{ height: '9em' }}>
								<h1 className="cardHeading pb-2"> Payment Method</h1>
								{user &&
								user.stripePayment &&
								user.stripePayment.card &&
								user.stripePayment.card.brand ? (
									user.stripePayment.card.brand == 'mastercard' ? (
										<img src={mastercard} alt="logo" width="50px" />
									) : user.stripePayment.card.brand == 'visa' ? (
										<img src={Visa} alt="logo" width="50px" />
									) : user.stripePayment.card.brand == 'amex' ? (
										<img src={amex} alt="logo" width="50px" />
									) : user.stripePayment.card.brand == 'discover' ? (
										<img src={discover} alt="logo" width="50px" />
									) : user.stripePayment.card.brand == 'diners' ? (
										<img src={dinersclub} alt="logo" width="50px" />
									) : (
										''
									)
								) : (
									''
								)}
								<p
									style={
										user && user.status && user.status.paymentVerified
											? {}
											: { color: '#fa726a', fontWeight: 'bold' }
									}
								>
									{user && user.status && user.status.paymentVerified
										? `**** **** **** 
									${
										user &&
										user.stripePayment &&
										user.stripePayment.card &&
										user.stripePayment.card.last4
									}`
										: 'Payment Method is not attached'}
								</p>
							</div>
						</Col>
						<Col xl={5} lg={5} md={6} sm={6} xs={12}>
							<div className="border px-4 pt-3" style={{ height: '9em' }}>
								<h1 className="cardHeading pb-2"> Subscription</h1>
								<p
									style={
										user && user.status && user.status.paymentVerified
											? {}
											: { color: '#fa726a', fontWeight: 'bold' }
									}
								>
									{user && user.status && user.status.paymentVerified
										? `Started: ${date}`
										: 'Company is not subscribed'}
								</p>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	stripeDelete: state.stripeDeleteReducer,
});

export default connect(mapStateToProps, {
	stripeDeleteAction,
})(withRouter(AdminSuccessPaymentDetail));
