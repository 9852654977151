import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

// import { ColumnFilterCheckbox } from './AdminColumnFilterCheckbox';
// import ColumnFilterText from './AdminColumnFilterText';
import { TableBaseComponent } from 'components/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';

// import TableBaseComponent from '../../../common/TableBaseComponent';
// import PaginationComponent from '../../../../common/Pagination';
// import PendingSuccess from '../../../common/PendingSuccess';
// import history from 'utils/history';
import '../../Admin/AdminCompaniesDashboard/AdminStyle.css';
// import {
//   getTable,
//   getTableSort,
// } from "./../../../Redux/actions/Table/TableAction";

const AdminGiftTable = (props) => {
	// const [show, setShow] = useState(false);
	// const handleClose = () => setShow(false);
	// const handleShow = () => setShow(true);
	// const { getTable, data, getTableSort } = props;
	// const handleSort = (data) => {
	//   getTableSort(data);
	// };
	const data = [
		{
			country: 'UK',
			Requiredverifiedcoins: '100',
		},
		{
			country: 'US',
			Requiredverifiedcoins: '120',
		},
		{
			country: 'Europe',
			Requiredverifiedcoins: '110',
		},
	];
	const COLUMNS = [
		{
			Header: () => {
				return (
					<>
						<div className="header-wrapper" style={{ marginRight: '1em' }}>
							<span className="buttonHeaders mb-4 ">Country</span>

							{/* <ColumnFilterText /> */}
						</div>
					</>
				);
			},
			accessor: 'country',
		},
		{
			Header: () => {
				return (
					<>
						<div
							className="header-wrapper"
							style={{ marginRight: '0em', minWidth: '210px' }}
						>
							<span>Required verified coins </span>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<span>
								<FontAwesomeIcon icon={faSort} />
							</span>
						</div>
					</>
				);
			},
			accessor: 'Requiredverifiedcoins',
		},
	];

	// useEffect(() => {
	//   getTable();
	// }, []);

	return (
		<>
			<Row>
				<div className=" ml-5 pt-2 mt-2">
					<h1>Gift card requirement amounts</h1>
				</div>
				<Col
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					className="py-5 px-xl-5 px-lg-5 px-md-3 mt-3"
				>
					<TableBaseComponent COLUMNS={COLUMNS} DATA={data} />
				</Col>
			</Row>
		</>
	);
};

// const mapStateToProps = (state) => {
//   return {
//     data: state.table.data,
//     loading: state.category.loading,
//   };
// };

// export default connect(mapStateToProps, { getTable, getTableSort })(
//   TableComponent
// );
export default AdminGiftTable;
