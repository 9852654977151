import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import history from 'utils/history';
import { connect } from 'react-redux';
import { Row, Col, Button, Form } from 'react-bootstrap';
import '../../Advertiser/AdvertiserProfileComponent/profileComponent.css';
import { MyTextField } from '../../../Validations/InputField';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PendingSuccess from '../PendingSuccess';
import { SelectState, SelectGender } from './DropDown/DropDown';
import { DatePickerField } from './DataPicker/DataPicker';
import countryData from '../../../Dataset/countries.json';
import stateData from '../../../Dataset/states.json';
import cityData from '../../../Dataset/cities.json';
import { AboutYouAction } from '../../../actions';
import { ClipLoader } from 'react-spinners';
const AdminAboutYou = (
	{
		profileAboutYou: { apiErrors, isAboutYouSaving, updatedUser },
		AboutYouAction,
		user,
	},
	dispatch
) => {
	const [serverError, setServerErrors] = useState({});
	const [stateList, setStateList] = useState([]);
	const [cityList, setCityList] = useState([]);

	useEffect(() => {
		if (user && user.country) {
			handleCountryChange(user.country);
		}
		if (user && user.state) {
			handleStateChange(user.state);
		}
	}, [user]);

	useEffect(() => {
		if (updatedUser == true) {
			history.go(0);
		}
	}, [updatedUser]);

	const handleCountryChange = (id) => {
		const stateResult = stateData.filter((state) => state.country_id == id);
		setStateList([]);

		if (stateResult.length !== 0) {
			setStateList(stateResult);
		} else {
			setStateList([
				{
					name: 'No Data',
					id: 1,
				},
			]);
		}
	};

	const handleStateChange = (id) => {
		const cityResult = cityData.filter((city) => city.state_id == id);

		if (cityResult.length !== 0) {
			setCityList(cityResult);
			// city = cityResult;
		} else {
			setCityList([
				{
					name: 'No Data',
					id: 1,
				},
			]);
		}
	};
	return (
		<>
			<div
				className={
					user && user.status && user.status.bioVerified ? 'line2' : 'line1'
				}
			>
				<div className="position-relative ml-3  ml-md-5 ml-sm-4  ml-xm-3  ">
					<Row className=" my-5 ">
						<div className="col-xl-11 col-lg-11  col-md-11  col-sm-7 mt-3 ">
							<div
								className="d-flex"
								style={{ justifyContent: 'space-between' }}
							>
								<div style={{ width: '75%' }}>
									<h1 className="profileHeading">About Member</h1>
								</div>
								<div className="mb-3 ">
									{user && user.status && user.status.bioVerified ? (
										<PendingSuccess className="" type="success" width="50px" />
									) : (
										<PendingSuccess className="" type="pending" width="50px" />
									)}
								</div>
							</div>
						</div>
					</Row>
					<Row>
						<Col xl={11} lg={11} md={12} sm={12} xs={12}>
							<Formik
								enableReinitialize={true}
								initialValues={{
									fullName: user && user.fullName ? user.fullName : '',
									dob: user && user.dob ? user.dob : '',
									gender: user && user.gender ? user.gender : '',
									country: user && user.country ? user.country : '',
									state: user && user.state ? user.state : '',
									city: user && user.city ? user.city : '',
									postalCode: user && user.postalCode ? user.postalCode : '',

									// fullName: "",
									// dateOfBirth:"",
									// gender:"",
									// country:"",
									// state:"",
									// city:"",
									// postalCode: "",
								}}
								onSubmit={async (values, { setSubmitting }) => {
									setSubmitting(true);
									console.log('VALUES', values);
									dispatch(AboutYouAction(values));
									setSubmitting(false);
								}}
								validationSchema={Yup.object().shape({
									fullName: Yup.string()
										.required("Full Name can't be empty")
										.max(40, 'Full Name must be less then 40 char')
										.min(2),
									dob: Yup.string("Date of birth can't be empty")
										.required("Date Of Birth can't be empty")
										.nullable(),
									gender: Yup.string().required("Gender can't be empty"),
									country: Yup.string().required("Country can't be empty"),
									state: Yup.string().required("State can't be empty"),
									city: Yup.string().required("City can't be empty"),
									postalCode: Yup.string()
										.required("Postal Code can't be empty")
										.max(10, 'Postal Code Name must be less then 10 char')
										.min(2),
								})}
							>
								{(props) => {
									const {
										values,
										touched,
										errors,
										isSubmitting,
										handleChange,
										handleBlur,
										handleSubmit,
										// setStatus,
										setFieldValue,
									} = props;
									return (
										<Form onSubmit={handleSubmit} noValidate>
											<Row>
												<Col xl={6} lg={12} md={12} sm={12} xs={12}>
													<div className="">
														<MyTextField
															placeholder="Full Name"
															inputType="text"
															fieldName="fullName"
															// onChange={(e) => {
															// 	customHandle(handleChange, 'emailAddress', e);
															// }}
															serverError={serverError}
															errors={errors.fullName || false}
															inputValue={values.fullName}
															fieldTouch={touched.fullName}
															setServerErrors={setServerErrors}
															apiErrors={apiErrors}
															handleChange={handleChange}
															onBlur={handleBlur}
															disabled={true}
															style={{ backgroundColor: 'white' }}
														/>
													</div>
													<div>
														<DatePickerField
															disabled={true}
															style={{ backgroundColor: 'red' }}
															name="dob"
															placeholderText="Date of Birth"
															error={touched.dob && errors.dob}
															onBlur={handleBlur}
															dateFormat="dd-MM-yyyy"
															maxDate={new Date()}
															required={true}
															value={
																values &&
																(values.dob ? new Date(values.dob) : new Date())
															}
														/>
													</div>
													<div className="">
														<SelectGender
															id="gender"
															error={touched.gender && errors.gender}
															value={values.gender}
															onChange={handleChange}
															onBlur={handleBlur}
															className="formField"
															disabled={true}
															style={{ backgroundColor: 'white' }}
														/>
													</div>
													<div className="">
														<MyTextField
															placeholder="Postal Code"
															inputType="text"
															fieldName="postalCode"
															// onChange={(e) => {
															// 	customHandle(handleChange, 'password', e);
															// }}
															serverError={serverError}
															errors={errors.postalCode || false}
															inputValue={values.postalCode}
															fieldTouch={touched.postalCode}
															setServerErrors={setServerErrors}
															// apiErrors={apiErrors}
															handleChange={handleChange}
															disabled={true}
															style={{ backgroundColor: 'white' }}
														/>
													</div>
												</Col>
												<Col xl={6} lg={12} md={12} sm={12} xs={12}>
													<div className="">
														<SelectState
															id="country"
															error={touched.country && errors.country}
															value={values.country}
															onChange={(e) => {
																setFieldValue('country', e.target.value);
																handleCountryChange(e.target.value);
															}}
															onBlur={handleBlur}
															data={countryData}
															selected="Country"
															className="formField"
															type="country"
															disabled={true}
															style={{ backgroundColor: 'white' }}
														/>
													</div>
													<div className="">
														<SelectState
															id="state"
															error={touched.state && errors.state}
															value={values.state}
															onChange={(e) => {
																setFieldValue('state', e.target.value);
																handleStateChange(e.target.value);
															}}
															onBlur={handleBlur}
															data={stateList}
															selected={
																values && values.country
																	? 'State'
																	: 'Select Country First'
															}
															disabled={true}
															className="formField"
															style={{ backgroundColor: 'white' }}
															type="state"
														/>
													</div>

													<div className="">
														<SelectState
															id="city"
															error={touched.city && errors.city}
															value={values.city}
															onChange={handleChange}
															onBlur={handleBlur}
															data={cityList}
															selected={
																values && values.state
																	? 'City'
																	: 'Select State First'
															}
															disabled={true}
															className="formField"
															style={{ backgroundColor: 'white' }}
															type="city"
														/>
													</div>
												</Col>
											</Row>
											{/* <Button
												type="submit"
												className="loginButton   my-4 "
												disabled={isAboutYouSaving}
											>
												{isAboutYouSaving ? (
													<ClipLoader size={20} color="#FCF9F2" />
												) : (
													`Save`
												)}
											</Button>    */}
										</Form>
									);
								}}
							</Formik>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	profileAboutYou: state.aboutYou,
});

export default connect(mapStateToProps, {
	AboutYouAction,
})(withRouter(AdminAboutYou));
