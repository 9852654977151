import { ManualAuthApiCall } from '../../../middlewares/apiMiddleware';
import history from 'utils/history';

export const PHONE_NUMBER = 'PHONE_NUMBER';
export const PHONE_NUMBER_SUCCESS = 'PHONE_NUMBER_SUCCESS';
export const PHONE_NUMBER_FAILURE = 'PHONE_NUMBER_FAILURE';

export const PhoneNumberAction = (payload) => async (dispatch) => {
	dispatch({
		type: PHONE_NUMBER,
	});

	const apiRequest = await ManualAuthApiCall(
		`/auth/verify/phonenumber`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify(payload),
		},
		dispatch,
		PHONE_NUMBER_FAILURE,
		history
		// true
	);
	if (apiRequest.error === false) {
		dispatch({ type: PHONE_NUMBER_SUCCESS, apiResponse: apiRequest });
	}
};
