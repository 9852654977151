import { ManualAuthApiCall } from '../../middlewares/apiMiddleware';
import { saveAuthToken } from '../../utils/index';
import history from 'utils/history';
export const REDEEM_GIFT = 'REDEEM_GIFT';
export const REDEEM_GIFT_SUCCESS = 'REDEEM_GIFT_SUCCESS';
export const REDEEM_GIFT_FAILURE = 'REDEEM_GIFT_FAILURE';
// export const LOGOUT = 'LOGOUT';

export const redeemGiftAction = (payload) => async (dispatch) => {
	dispatch({
		type: REDEEM_GIFT,
	});

	const apiRequest = await ManualAuthApiCall(
		`/auth/customer/redeem`,
		{
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify(payload),
		},
		dispatch,
		REDEEM_GIFT_FAILURE,
		history
	);
	if (apiRequest.error === false) {
		dispatch({ type: REDEEM_GIFT_SUCCESS, apiResponse: apiRequest });
		history.go(0);
	}
};

// export const logout = () => ({
// 	type: LOGOUT,
// });
