import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { HeaderComponent, FooterComponent } from '../../common';
import { Row, Col, Container, Form } from 'react-bootstrap';
import Group from '../../../assets/images/Group.svg';
import { Button } from 'react-bootstrap';
import { MyTextField } from '../../../Validations/InputField';
import * as Yup from 'yup';
import './SignUp.css';
import { RegisterCustomerAction } from '../../../actions';
import '../../../Validations/formik.css';
import { Formik } from 'formik';
import queryString from 'query-string';
import { ClipLoader } from 'react-spinners';

const SignUpComponent = ({
	register: { apiErrors, isLoading, user },
	RegisterCustomerAction,
	history,
	location,
	dispatch,
	match: {
		params: { referralCode },
	},
}) => {
	// const [showError, setShowError] = useState(true);
	const [serverError, setServerErrors] = useState({});
	useEffect(() => {
		setServerErrors(apiErrors);
	}, [apiErrors]);
	useEffect(() => {}, [isLoading, apiErrors, user, location.pathname]);
	const handleButton = () => {
		// const path = `newPath`;
		history.push('/login');
	};
	return (
		<>
			<div className="fullBg fullContainer">
				<div className=" pt-1" style={{ backgroundColor: '#f5f0e3' }}>
					<HeaderComponent />
				</div>
				<Container fluid style={{ backgroundColor: '#f5f0e3' }}>
					<Row className=" pt-5  pb-5 " style={{ backgroundColor: '#f5f0e3' }}>
						<div
							className="customFormStyle py-3 my-5 px-5  offset-xl-2 col-xl-4  offset-lg-1 col-lg-5  offset-md-1 col-md-5 offset-sm-2 col-sm-8 offset-xs-1 col-xs-11"
							style={{ backgroundColor: 'white' }}
						>
							<div className="">
								<h1 className="mt-5 mb-5 formText">
									{referralCode && 'Referral '}Sign Up
								</h1>
								<Formik
									initialValues={{
										emailAddress: '',
										password: '',
										reTypePassword: '',
										termsCheck: false,
										userType: 'CUSTOMER',
									}}
									onSubmit={async (values, { setSubmitting }) => {
										setSubmitting(true);
										let queries = queryString.parse(location.search);

										dispatch(
											RegisterCustomerAction({
												emailAddress: values.emailAddress,
												password: values.password,
												userType: values.userType,
												referralCode: referralCode ? referralCode : null,
											})
										);
										setSubmitting(false);
									}}
									validationSchema={Yup.object().shape({
										emailAddress: Yup.string()
											.email('Email must be valid')
											.required("Email can't be empty"),
										password: Yup.string()
											.min(
												6,
												'Password is too short - should be 6 characters minimum.'
											)
											.max(
												20,
												'Your characters limit is exceded - maximum 20 characters allowed'
											)
											.required("Password can't be empty")
											.matches(
												/^.*(?=.{6,})(?=.*\d)((?=.*[A-Z]){1}).*$/,
												'Password must contain at least one uppercase character and one number'
											),
										reTypePassword: Yup.string()
											.required("Re-type password can't be empty")
											.oneOf(
												[Yup.ref('password'), null],
												'Passwords must match'
											),
										termsCheck: Yup.boolean().oneOf(
											[true],
											'Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy'
										),
									})}
								>
									{(props) => {
										const {
											values,
											touched,
											errors,
											// isSubmitting,
											handleChange,
											// handleBlur,
											handleSubmit,
											setFieldValue,
										} = props;
										return (
											<Form onSubmit={handleSubmit} noValidate>
												<div className="py-2">
													<MyTextField
														placeholder="Email"
														inputType="text"
														fieldName="emailAddress"
														serverError={serverError}
														errors={errors.emailAddress || false}
														inputValue={values.emailAddress}
														fieldTouch={touched.emailAddress}
														setServerErrors={setServerErrors}
														apiErrors={apiErrors}
														handleChange={handleChange}
													/>
												</div>
												<div className="py-2">
													<MyTextField
														placeholder="Password"
														inputType="password"
														fieldName="password"
														//
														// 	customHandle(handleChange, 'password', e);
														// }}
														serverError={serverError}
														errors={errors.password || false}
														inputValue={values.password}
														fieldTouch={touched.password}
														setServerErrors={setServerErrors}
														apiErrors={apiErrors}
														handleChange={handleChange}
													/>
												</div>
												<div className="py-2">
													<MyTextField
														placeholder="Re-type password"
														inputType="password"
														fieldName="reTypePassword"
														// onChange={(e) => {
														// 	customHandle(handleChange, 'password', e);
														// }}
														serverError={serverError}
														errors={errors.reTypePassword || false}
														inputValue={values.reTypePassword}
														fieldTouch={touched.reTypePassword}
														setServerErrors={setServerErrors}
														apiErrors={apiErrors}
														handleChange={handleChange}
													/>
												</div>

												<div className="py-2 d-flex">
													<div className="checkbox-wrapper">
														<input
															id="test"
															type="checkbox"
															onChange={(e) => {
																setFieldValue('termsCheck', e.target.checked);
															}}
															value={values.termsCheck}
														/>
														<label htmlFor="test"></label>
													</div>
													{/* <Form.Group className="">
													<Form.Check
														id="termsCheck"
														type="checkbox"
														name="termsCheck"
														className="customCheck"
														onChange={(e) => {
															setFieldValue('termsCheck', e.target.checked);
														}}
														// disabled={values.termsCheck && true}
														value={values.termsCheck}
														// label="I agree to the terms and conditions"
													></Form.Check>
												</Form.Group> */}
													<span className="termsCondition">
														I agree to the &nbsp;
														<a className="pr-5 termsCondition2" href="#">
															terms and conditions
														</a>
													</span>
												</div>

												{touched.termsCheck && errors.termsCheck && (
													<div className="invalid-feedback d-block">
														{errors.termsCheck}
													</div>
												)}

												<Button
													type="submit"
													className="loginButton mb-4 mt-1 py-2 border-0 "
													disabled={isLoading}
												>
													{isLoading ? (
														<ClipLoader size={20} color="#FCF9F2" />
													) : (
														`Sign Up`
													)}
													&nbsp;&nbsp;
												</Button>
											</Form>
										);
									}}
								</Formik>
								<div className="pt-xl-3  mt-xl-3   pt-lg-3 pb-lg-1 mt-lg-3  pt-md-4  mt-md-4 mt-4  pt-3">
									<p className="alreadyMember">
										Already a member? &nbsp;
										<Link to="/login">
											<a className="forgotpassword">Sign In</a>
										</Link>
									</p>
								</div>
								<div>
									<p className="alreadyMember">
										Joining as a business? &nbsp;
										<Link to="/business-register">
											<a className="forgotpassword">Sign up here</a>
										</Link>
									</p>
								</div>
							</div>
						</div>

						<Col xl={5} lg={5} md={5} sm={8} xs={12} className="py-4">
							<Row className="px-xl-5  pt-xl-5  mr-xl-5 pr-xl-5  pl-lg-5  pt-lg-1 mb-lg-5   pt-md-2 mb-md-5 joinTop">
								<div
									className=" px-4 pt-5 px-xl-5 pt-xl-5 pl-lg-4 pr-lg-0 pt-lg-5 px-md-4 pt-md-5 px-sm-4 pt-sm-5 px-xs-4 pt-xs-5  offset-xl-0 col-xl-12 offset-lg-0 col-lg-9  offset-md-1 col-md-10 offset-sm-0 col-sm-10 offset-xs-0 col-xs-11"
									style={{
										backgroundColor: ' #FCF9F2',
									}}
								>
									<p className="para1">Be a part of the data revolution </p>
									<h1 className="giftcard">
										Join today and earn a $50 Gift card{' '}
									</h1>
									<p className="para2">
										Simply install the Gravy extension to your favourite browser
										and start earning for using the web as you usually would.
									</p>
									<p className="para2"></p>
									<div className="buttonWrapper text-center	">
										<Button
											className="px-5 py-2 mb-5 border-0 signinButton d-block"
											type="button"
											onClick={handleButton}
										>
											Sign In &nbsp;&nbsp;
										</Button>
										<img
											src={Group}
											alt="Sign-up Group"
											className="img-fluid fix-img"
										/>
									</div>
								</div>
							</Row>
						</Col>
					</Row>
				</Container>
				<FooterComponent />
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	register: state.authRegister,
});

export default connect(mapStateToProps, { RegisterCustomerAction })(
	withRouter(SignUpComponent)
);
