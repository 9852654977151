import React from 'react';
import { Row, Col, Button, Nav, Navbar, Container } from 'react-bootstrap';
import './AdminHeaderStyle.css';
import GravyIcon from '../../../assets/images/GravyIcon.svg';
import PendingSuccess from '../../common/PendingSuccess';
import { Link } from 'react-router-dom';
const AuthAdminHeaderComponent = ({ user }) => {
	return (
		<>
			<div className="fullBg">
				<Container fluid>
					<Navbar collapseOnSelect expand="lg" className="header1">
						<Link to="/app/admin/member">
							<Navbar.Brand>
								<img src={GravyIcon} className="my-3 mx-4" />
							</Navbar.Brand>
						</Link>
						<Navbar.Toggle aria-controls="responsive-navbar-nav" />
						<Navbar.Collapse id="responsive-navbar-nav">
							<Nav className="mx-auto"></Nav>
							<Nav className="my-4 mr-1">
								<Link
									to="/app/admin/member"
									className="mx-4 mt-2 text-decoration-none"
									style={{ color: 'black' }}
								>
									<h5>
										<b>Members</b>
									</h5>
								</Link>
								<Link
									to="/app/admin/companies"
									className="mx-4 mt-2 text-decoration-none"
									style={{ color: 'black' }}
								>
									<h5>
										<b>Companies</b>
									</h5>
								</Link>
								<Link
									to="/app/admin/invoices"
									style={{ color: 'black' }}
									className="mx-4 mt-2 text-decoration-none"
								>
									<h5>
										<b>Invoices</b>
									</h5>
								</Link>
								<Link
									to="/app/admin/giftcard"
									style={{ color: 'black' }}
									className="mx-4 mt-2 text-decoration-none"
								>
									<h5>
										<b>Gift Cards</b>
									</h5>
								</Link>
								<Link
									to="/app/admin/profile"
									className="mx-4 text-decoration-none mt-2"
								>
									<Row className="header1">
										<Col>
											<div className="d-flex">
												<PendingSuccess
													type="success"
													width="35px"
													height="35px"
												/>

												<div className="ml-3">
													<h5 className="accounts">
														<b style={{ color: 'black' }}>Your Account</b>
													</h5>

													<small style={{ fontSize: '11px', color: '#50BFA5' }}>
														Status: Verified
													</small>
												</div>
											</div>
										</Col>
									</Row>
								</Link>
							</Nav>
						</Navbar.Collapse>
					</Navbar>
				</Container>
			</div>
		</>
	);
};

export default AuthAdminHeaderComponent;
