import {
	ManualApiCall,
	ManualAuthApiCall,
} from '../../middlewares/apiMiddleware';

import { toQueryString } from '../../utils/index';
import history from 'utils/history';
export const COMPANIES_INVOICES_TABLE = 'COMPANIES_INVOICES_TABLE';
export const COMPANIES_INVOICES_TABLE_SUCCESS =
	'COMPANIES_INVOICES_TABLE_SUCCESS';
export const COMPANIES_INVOICES_TABLE_FAILURE =
	'COMPANIES_INVOICES_TABLE_FAILURE';
// export const LOGOUT = 'LOGOUT';

export const CompanyInvoicesTableAction = (filter) => async (dispatch) => {
	const queryString = toQueryString(filter);
	// console.log('invoice user id', userId);

	dispatch({
		type: COMPANIES_INVOICES_TABLE,
	});

	const apiRequest = await ManualAuthApiCall(
		`/auth/advertiser/invoice?${queryString}`,
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			// body: JSON.stringify(filter),
		},
		dispatch,
		COMPANIES_INVOICES_TABLE_FAILURE,
		history
	);

	if (apiRequest.error === false) {
		dispatch({
			type: COMPANIES_INVOICES_TABLE_SUCCESS,
			apiResponse: apiRequest,
		});
		// history.push('/app/dashboard');
	}
};
