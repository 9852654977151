import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import {
	AuthFooterComponent,
	AuthAdminHeaderComponent,
	DeleteUser,
	AdminBonus,
	AdminAboutYou,
	AdminYourEmail,
	AdminPhoneNumber,
	AdminDomainOwnership,
	AdminSuccessPaymentDetail,
} from 'components/common';
import { useLocation } from 'react-router';
import Profile from '../../../assets/images/Profile.svg';
import { fetchProfileAction } from '../../../actions';
import { connect } from 'react-redux';
import queryString from 'query-string';
// import MemberViewTable from './MemberViewTable';
import TableComponent from '../../User/UserDashboardComponent/Dashboard/TableComponent';
import AdvertiserTableComponent from '../../Advertiser/AdvertiserDashboard/AdvertiserTableComponent';
import './MemberView.css';

const AdminMemberView = ({
	profile: { apiErrors, isLoading, user },
	fetchProfileAction,
	dispatch,
}) => {
	const location = useLocation();
	const [id, setId] = useState({});

	// const id = queryString.parse(location.search);
	// const memberId = queryString.parse(location.search);
	useEffect(() => {
		const memberId = queryString.parse(location.search);
		setId(memberId);
		fetchProfileAction(memberId);
	}, []);
	return (
		<>
			<div className="authfullContainer">
				<AuthAdminHeaderComponent user={user && user} />
				<div className="fullBg">
					<Container fluid>
						<Row style={{ backgroundColor: '#F4F0E3', paddingTop: '6%' }}>
							<div className="offset-xl-1 col-xl-5 offset-lg-1 col-lg-6 offset-md-1 col-md-6 offset-sm-1 col-sm-6 pr-0">
								<div className=" ml-xl-4 ml-lg-4 ml-md-4 ml-sm-4 ml-0 mt-5">
									<div>
										<h1 className="heading admin-heading">Member</h1>
									</div>
								</div>
							</div>
							<Col xl={6}>
								<img
									src={Profile}
									alt="profile"
									className="img-fluid admin-girl"
								/>
							</Col>
						</Row>
					</Container>
				</div>
				<Container fluid>
					<Row>
						<div className=" col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 ">
							<DeleteUser userId={id} />
						</div>

						<div className="col-xl-8  col-lg-7 col-md-10 col-sm-12 col-xm-12 ">
							<AdminAboutYou user={user && user} />
							<AdminYourEmail user={user && user} />
							<AdminPhoneNumber user={user && user} />
							{user && user.userRole == '3' ? (
								<>
									<AdminBonus userId={id} />
								</>
							) : (
								<>
									<AdminDomainOwnership user={user && user} />
									<AdminSuccessPaymentDetail user={user && user} />
								</>
							)}
						</div>
					</Row>
				</Container>

				<Container fluid>
					<div className=" ml-5 pt-5">
						<h1>Website Records</h1>
					</div>
					{user && user.userRole == '3' ? (
						<>
							<TableComponent userId={id} />
						</>
					) : (
						<>
							<AdvertiserTableComponent userId={id} />
						</>
					)}
				</Container>
			</div>
			<AuthFooterComponent />
		</>
	);
};

// export default ProfileComponent;

const mapStateToProps = (state) => ({
	profile: state.profile,
});

export default connect(mapStateToProps, {
	fetchProfileAction,
})(withRouter(AdminMemberView));
