import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import { PaginationComponent, TableBaseComponent } from 'components/common';
import { ColumnFilterCheckbox } from './AdminColumnFilterCheckbox';
import ColumnFilterText from './AdminColumnFilterText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import './AdminMemberTableStyle.css';
import { MemberTableAction } from '../../../actions';
import { roleAbbrevation, nameSplitter } from 'utils';

const AdminTableComponent = ({
	memberTable: { apiErrors, isLoading, tableData },
	MemberTableAction,
	totalMember,
	setTotalMember,
}) => {
	let [csv, setCsv] = useState([]);
	const [filter, setFilter] = useState({
		filter: [],
		sorting: [],
		pagination: [],
		checkFilter: [],
	});

	useEffect(() => {
		MemberTableAction(filter);
	}, []);

	// let csv = [];
	useEffect(() => {
		tableData &&
			tableData.map((item, index) => {
				item.userRole = roleAbbrevation(item && item.userRole);
				item.createdAt = new Date(item && item.createdAt).toLocaleDateString(
					'en-US'
				);
				const splittedName = nameSplitter(item && item.fullName);
				item['firstName'] = splittedName[0];
				item['lastName'] = splittedName[splittedName.length - 1];

				csv[index] = {
					userRole: item && item.userRole,
					firstName: item && item.firstName,
					lastName: item && item.lastName,
					createdAt: item && item.createdAt,
					phoneNumber: item && item.phoneNumber,
				};
				setCsv(csv);
			});

		// csv.map((item) => {
		// 	delete item.totalUser;
		// 	delete item.totalCustomers;
		// 	delete item.totalCompanies;
		// 	item.createdAt = new Date(item.createdAt).toLocaleDateString('en-US');
		// 	// const splittedName = nameSplitter(item && item.fullName);
		// 	// item['firstName'] = splittedName[0];
		// 	// item['lastName'] = splittedName[splittedName.length - 1];
		// });
		if (
			!_.isUndefined(
				tableData && tableData[0] && tableData[0].totalCustomers
			) &&
			!_.isUndefined(tableData && tableData[0] && tableData[0].totalCompanies)
		) {
			setTotalMember({
				totalCustomers:
					tableData && tableData[0] && tableData[0].totalCustomers,
				totalCompanies:
					tableData && tableData[0] && tableData[0].totalCompanies,
			});
		}
	}, [tableData]);

	const headers = [
		{ label: 'User/Company', key: 'userRole' },
		{ label: 'First Name', key: 'firstName' },
		{ label: 'Last Name', key: 'lastName' },
		{ label: 'Date', key: 'createdAt' },
		{ label: 'Phone Number', key: 'phoneNumber' },
	];

	const handleTableAction = () => {
		MemberTableAction(filter);
	};

	// const headers = [
	// 	{ label: 'First Name', key: 'firstname' },
	// 	{ label: 'Last Name', key: 'lastname' },
	// 	{ label: 'Email', key: 'email' },
	// ];

	const COLUMNS = [
		{
			Header: () => {
				return (
					<>
						<div className="header-wrapper" style={{ marginRight: '-3em' }}>
							<span className="memberbuttonHeaders">User/Company</span>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<ColumnFilterCheckbox
								filter={filter}
								setFilter={setFilter}
								handleTableAction={handleTableAction}
							/>
						</div>
					</>
				);
			},
			accessor: 'userRole',
		},
		{
			Header: () => {
				return (
					<>
						<div className="header-wrapper" style={{ marginRight: '-6em' }}>
							<span className="memberbuttonHeaders">Full Name</span>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<ColumnFilterText
								filter={filter}
								setFilter={setFilter}
								handleTableAction={handleTableAction}
							/>
						</div>
					</>
				);
			},
			accessor: 'fullName',
		},

		{
			Header: () => (
				<div
					className="header-wrapper"
					style={{ marginRight: '-2em' }}
					onClick={() => {
						filter['sorting'] &&
						filter['sorting']['date'] &&
						filter['sorting']['date'] == 'ASC'
							? (filter['sorting']['date'] = 'DESC')
							: (filter['sorting'] = {
									...filter['sorting'],
									date: 'ASC',
							  });

						setFilter(filter);
						handleTableAction();
					}}
				>
					<span className="pb-2" style={{ cursor: 'pointer' }}>
						Date &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
					</span>
					<span className="tableSortIcon" style={{ cursor: 'pointer' }}>
						{filter['sorting'] &&
						filter['sorting']['date'] &&
						filter['sorting']['date'] == 'ASC' ? (
							<FontAwesomeIcon icon={faCaretDown} />
						) : (
							<FontAwesomeIcon icon={faCaretUp} />
						)}
					</span>
				</div>
			),
			accessor: 'createdAt',
		},
		{
			Header: () => {
				return (
					<div className="header-wrapper">
						<span className="pb-2">Phone Number</span>
					</div>
				);
			},
			accessor: 'phoneNumber',
		},
		{
			Header: () => {
				return (
					<div className="header-wrapper">
						<span className="pb-2">Action</span>
					</div>
				);
			},
			accessor: 'action',
			Cell: ({ row }) => {
				const data = row.original;

				return (
					<Link to={`/app/admin/memberview?id=${data.id}`}>
						<Button className="button shadow-none">
							<b>View</b>
						</Button>
					</Link>
				);
			},
		},
	];

	return (
		<>
			<Row className="mt-5">
				<Col xl={{ span: 12 }} className="py-5 px-xl-5 px-lg-5 px-md-3 mt-3">
					<CSVLink
						data={csv && csv}
						headers={headers}
						filename={'Admin Members Data.csv'}
						onClick={() => {
							if (csv && csv.length == 0) {
								return false;
							} else {
								return true;
							}
						}}
					>
						<Button
							className="button shadow-none float-right"
							disabled={csv && csv.length == 0 ? true : false}
						>
							<b>Export to CSV</b>
						</Button>
					</CSVLink>
					<TableBaseComponent COLUMNS={COLUMNS} DATA={tableData && tableData} />
					<PaginationComponent
						filter={filter}
						setFilter={setFilter}
						handleTableAction={handleTableAction}
						totalUser={tableData && tableData[0] && tableData[0].totalUser}
					/>
				</Col>
			</Row>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		memberTable: state.memberTable,
	};
};

export default connect(mapStateToProps, { MemberTableAction })(
	AdminTableComponent
);
