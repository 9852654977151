import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Button, Form } from 'react-bootstrap';
import '../../Advertiser/AdvertiserProfileComponent/profileComponent.css';
import { MyTextField } from '../../../Validations/InputField';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PendingSuccess from '../../common/PendingSuccess';
import * as _ from 'lodash';
import { DomainOwnerAction } from '../../../actions';
import { ClipLoader } from 'react-spinners';
import { SnackBar } from '../../common';
// import { DASHBOARD_HOST } from 'config';

const DomainOwnership = (
	{
		domainOwner: { isDomainVerifying, data, apiErrors },

		user,
		DomainOwnerAction,
	},
	dispatch
) => {
	const [serverError, setServerErrors] = useState({});
	useEffect(() => {
		console.log({ apiErrors });
	}, [apiErrors]);

	// const link = `${DASHBOARD_HOST}/register/${
	// 	user && user.domainVerificationCode
	// }`;
	console.log('user', user);

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);

	return (
		<>
			<div
				className={
					user && user.status && user.status.domainVerified ? 'line2' : 'line1'
				}
			>
				<div className="position-relative ml-3  ml-md-5 ml-sm-4  ml-xm-3 ">
					<Row className=" mt-5 ">
						<div className="col-xl-11 col-lg-11  col-md-11  col-sm-11   col-sx-11  mt-2 ">
							<div
								className="d-flex"
								style={{ justifyContent: 'space-between' }}
							>
								<div style={{ width: '75%' }}>
									<h1 className="profileHeading">
										Verify your domain ownership
									</h1>
									<p>
										Confirm you own this domain by copying the below code into
										the header of your website. If you don’t know how to do
										this, contact your website provider.
									</p>
								</div>
								<div>
									{user && user.status && user.status.domainVerified ? (
										<PendingSuccess
											className="mt-3 "
											type="success"
											width="50px"
										/>
									) : (
										<PendingSuccess
											className="mt-3 "
											type="pending"
											width="50px"
										/>
									)}
								</div>
							</div>
						</div>
					</Row>
					<Row>
						{/* <Col xl={6} lg={10} md={12} sm={12} xs={12}> */}
						<Col xl={10} lg={10} md={12} sm={12} xs={12}>
							{
								// user && _.isNull(user.domain) &&
								<Formik
									enableReinitialize={true}
									initialValues={{
										siteUrl: user && user.domain ? user.domain : '',
									}}
									onSubmit={async (values, { setSubmitting }) => {
										setSubmitting(true);
										dispatch(DomainOwnerAction(values));
										setSubmitting(false);
									}}
									validationSchema={Yup.object().shape({
										siteUrl: Yup.string()
											.required("Domain Name can't be empty")
											.matches(
												/^(?!:\/\/)[w][w][w][.]([a-zA-Z0-9]+\.)?[a-zA-Z0-9][a-zA-Z0-9-]+\.[a-zA-Z]{2,6}?$/,
												'Domain must be formated as i.e. www.example.com'
											),
									})}
								>
									{(props) => {
										const {
											values,
											touched,
											errors,
											isSubmitting,
											handleChange,
											// handleBlur,
											handleSubmit,
											// setStatus,
										} = props;
										return (
											<Form onSubmit={handleSubmit} noValidate>
												<Row>
													<Col xl={7} lg={12} md={12} sm={12} xs={12}>
														<div
															class="input-group mb-3"
															style={{ maxWidth: '427px' }}
														>
															<input
																type="text"
																className="form-control spaceField"
																aria-label="Recipient's username"
																aria-describedby="basic-addon2"
																// className="py-3"
																disabled={true}
																value={`<meta name="gravy-site-verification" content="${
																	user && user.domainVerificationCode
																}"/>`}
															/>
															<div class="input-group-append">
																<button
																	className="btnColor"
																	type="button"
																	style={{
																		backgroundColor: '#FDBF00',
																		boxShadow: 'none',
																	}}
																	onClick={() => {
																		navigator.clipboard.writeText(
																			`<meta name="gravy-site-verification" content="${
																				user && user.domainVerificationCode
																			}"/>`
																		);
																		SnackBar('success', 'Copied to clipboard');
																	}}
																>
																	Copy
																</button>
															</div>
														</div>
														{/* <div className="input-group mb-3"> */}
														{/* <MyTextField
														placeholder="Site Address"
														inputType="text"
														fieldName="siteUrl"
														// onChange={(e) => {
														// 	customHandle(handleChange, 'emailAddress', e);
														// }}
														serverError={serverError}
														errors={errors.siteUrl || false}
														inputValue={values.siteUrl}
														fieldTouch={touched.siteUrl}
														setServerErrors={setServerErrors}
														apiErrors={apiErrors}
														handleChange={handleChange}
													/> */}

														<MyTextField
															// style={{ width: '135%' }}
															placeholder="Domain Name"
															inputType="text"
															fieldName="siteUrl"
															// onChange={(e) => {
															// 	customHandle(handleChange, 'emailAddress', e);
															// }}
															serverError={serverError}
															errors={errors.siteUrl || false}
															inputValue={values.siteUrl}
															fieldTouch={touched.siteUrl}
															setServerErrors={setServerErrors}
															apiErrors={apiErrors}
															handleChange={handleChange}
														/>
														{/* </div> */}
													</Col>
												</Row>

												<Button
													type="submit"
													className="loginButton   my-4 "
													disabled={isDomainVerifying}
												>
													{isDomainVerifying ? (
														<ClipLoader size={20} color="#FCF9F2" />
													) : (
														`Verify`
													)}
												</Button>
											</Form>
										);
									}}
								</Formik>
							}
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	domainOwner: state.domainOwner,
});

export default connect(mapStateToProps, {
	DomainOwnerAction,
})(withRouter(DomainOwnership));
