import { ManualApiCall } from '../../middlewares/apiMiddleware';
import { saveAuthToken } from '../../utils/index';
import history from 'utils/history';
export const REGISTER_CUSTOMER = 'REGISTER_CUSTOMER';
export const REGISTER_CUSTOMER_SUCCESS = 'REGISTER_CUSTOMER_SUCCESS';
export const REGISTER_CUSTOMER_FAILURE = 'REGISTER_CUSTOMER_FAILURE';
export const RegisterCustomerAction = (payload) => async (dispatch) => {
	console.log(payload);
	dispatch({
		type: REGISTER_CUSTOMER,
	});

	const apiRequest = await ManualApiCall(
		`/auth/customer/register`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify(payload),
		},
		dispatch,
		REGISTER_CUSTOMER_FAILURE,
		history
	);
	if (apiRequest.error === false) {
		saveAuthToken(apiRequest.data);
		dispatch({ type: REGISTER_CUSTOMER_SUCCESS, apiResponse: apiRequest });
		history.push('/app/customer/dashboard');
	}
};
