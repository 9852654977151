import React from 'react';
import { Row } from 'react-bootstrap';
import '../../Advertiser/AdvertiserProfileComponent/profileComponent.css';

const CoinsPerUser = () => {
	return (
		<>
			<Row className="  my-5  ">
				<div
					className="col-xl-11 col-lg-11 col-md-12"
					style={{ backgroundColor: '#FCF9F2' }}
				>
					<div className="mt-5 pt-3 pb-5 px-5">
						<h1>50 Coins per user</h1>
						<p style={{ color: '#9C9C9C' }}>
							We have set the coins per user based on market rates. The total
							cost per user is $5 (10 coin = $1).
						</p>
						<p style={{ color: '#9C9C9C' }}>
							If you would like to discuss pricing, contact our team.
						</p>
					</div>
				</div>
			</Row>
		</>
	);
};

export default CoinsPerUser;
