import React from 'react';
import classnames from 'classnames';
import { Form } from 'react-bootstrap';

const InputFeedback = ({ error }) =>
	error ? <div className="invalid-feedback d-block">{error}</div> : null;

// const Label = ({ error, className, children, ...props }) => {
//   return (
//     <Form.Label className="label" {...props}>
//       {children}
//     </Form.Label>
//   );
// };

export const SelectGender = ({
	type,
	id,
	label,
	error,
	value,
	onChange,
	className,
	...props
}) => {
	const classes = classnames({
		'animated shake error': !!error,
	});
	return (
		<Form.Group className={classes}>
			<Form.Control
				as="select"
				id={id}
				className={className}
				value={value}
				onChange={onChange}
				{...props}
			>
				<option value="" selected disabled hidden>
					Gender
				</option>
				<option value="male">Male</option>
				<option value="female">Female</option>
				<option value="other">Other</option>
			</Form.Control>
			<InputFeedback error={error} />
		</Form.Group>
	);
};

export const SelectState = ({
	type,
	id,
	label,
	error,
	value,
	onChange,
	className,
	data,
	selected,
	...props
}) => {
	const classes = classnames({
		'animated shake error': !!error,
	});
	return (
		<>
			{
				<Form.Group className={classes}>
					<Form.Control
						as="select"
						id={id}
						className={className}
						value={value}
						onChange={onChange}
						{...props}
					>
						<option value="" selected disabled hidden>
							{selected}
						</option>
						{data &&
							data.map((item, i) => {
								return (
									<option key={i} value={item.id}>
										{item.name}
									</option>
								);
							})}
					</Form.Control>
					<InputFeedback error={error} />
				</Form.Group>
			}
		</>
	);
};
