import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import * as _ from 'lodash';
import {
	PaginationComponent,
	TableBaseComponent,
	PendingSuccess,
} from 'components/common';
import ColumnFilterText from './AdvertiserColumnFilterText';
import AdvertiserModalTable from './AdvertiserModalTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import {
	AdvertiserDashboardTableAction,
	advertiserModalTableAction,
} from '../../../actions';
import './AdvertiserTableStyle.css';
import LoadingOverlay from 'react-loading-overlay';
import { ClipLoader } from 'react-spinners';
import { nameSplitter } from 'utils';

const AdvertiserTableComponent = ({
	props,
	advertiserTable: { isLoading, tableData },
	AdvertiserDashboardTableAction,
	userId,
	domain,
}) => {
	const [show, setShow] = useState(false);
	const [companyUser, setCompanyUser] = useState(false);

	// const handleClose = () => setShow(false);
	// const [search, setSearch] = useState({});
	const [filter, setFilter] = useState({
		filter: [],
		sorting: [],
		pagination: [],
	});

	// useEffect(() => {
	// 	AdvertiserDashboardTableAction(filter, userId);
	// }, [filter, userId]);

	const handleTableAction = () => {
		AdvertiserDashboardTableAction(filter, userId);
	};

	useEffect(() => {
		// if (!_.isUndefined(userId.id)) {
		AdvertiserDashboardTableAction(filter, userId);
		// }
	}, [filter, userId]);

	const totalRecord = tableData && tableData[0] && tableData[0].totalRecord;
	let csv = [];
	tableData &&
		tableData.map((item, index) => {
			item.visitedAt = new Date(item.visitedAt).toLocaleDateString();
			const splittedName = nameSplitter(item.fullName);
			item['firstName'] = splittedName[0];
			item['lastName'] = splittedName[splittedName.length - 1];

			csv[index] = {
				emailAddress: item.emailAddress,
				firstName: item.firstName,
				lastName: item.lastName,
				isPaymentVerified:
					item.isPaymentVerified == false ? 'pending' : 'success',
				phoneNumber: item.phoneNumber,
				visitedAt: item.visitedAt,
			};
		});

	const headers = [
		{ label: 'Email', key: 'emailAddress' },
		{ label: 'First Name', key: 'firstName' },
		{ label: 'Last Name', key: 'lastName' },
		{ label: 'Date', key: 'visitedAt' },
		{ label: 'Phone Number', key: 'phoneNumber' },
		{ label: 'Payment Status', key: 'isPaymentVerified' },
	];

	const COLUMNS = [
		{
			Header: () => {
				return (
					<>
						<div className="header-wrapper">
							<span className="buttonHeaders pt-1">Email</span>
							&nbsp;&nbsp;&nbsp;
						</div>
					</>
				);
			},
			accessor: 'emailAddress',
		},
		{
			Header: () => {
				return (
					<>
						<div className="header-wrapper" style={{ marginRight: '-5em' }}>
							<span className="buttonHeaders">Full Name</span>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<ColumnFilterText
								filter={filter}
								setFilter={setFilter}
								handleTableAction={handleTableAction}
							/>
						</div>
					</>
				);
			},
			accessor: 'fullName',
		},
		{
			Header: () => (
				<div
					className="header-wrapper"
					style={{ cursor: 'pointer', marginRight: '-2em' }}
					onClick={() => {
						filter['sorting'] &&
						filter['sorting']['visitedAt'] &&
						filter['sorting']['visitedAt'] == 'asc'
							? (filter['sorting']['visitedAt'] = 'desc')
							: (filter['sorting'] = {
									...filter['sorting'],
									visitedAt: 'asc',
							  });

						setFilter(filter);
						handleTableAction();
					}}
				>
					<span>
						Date &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					</span>
					<span className="tableSortIcon">
						<ul>
							<li>
								{filter['sorting'] &&
								filter['sorting']['visitedAt'] &&
								filter['sorting']['visitedAt'] == 'asc' ? (
									<FontAwesomeIcon icon={faCaretDown} />
								) : (
									<FontAwesomeIcon icon={faCaretUp} />
								)}
							</li>
						</ul>
					</span>
				</div>
			),
			accessor: 'visitedAt',
		},
		{
			Header: () => {
				return (
					<div className="header-wrapper">
						<span className="pb-2">Phone Number</span>
					</div>
				);
			},
			accessor: 'phoneNumber',
		},
		{
			Header: () => (
				<div className="header-wrapper" style={{ marginRight: '-4em' }}>
					<span>
						Payment Status
						&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
					</span>
				</div>
			),

			Cell: ({ cell: { value } }) =>
				value ? (
					<PendingSuccess
						type="success"
						width="30px"
						className="img-fluid mx-4"
					/>
				) : (
					<PendingSuccess
						type="pending"
						width="30px"
						className="img-fluid mx-4"
					/>
				),
			accessor: 'isPaymentVerified',
		},
		{
			Header: () => {
				return (
					<div className="header-wrapper">
						<span className="pb-2">Action</span>
					</div>
				);
			},
			accessor: 'visitedId',
			Cell: ({ cell: { value } }) => (
				<Button
					className="button shadow-none"
					onClick={() => {
						setCompanyUser(value);
						setShow(true);
					}}
				>
					<b>View</b>
				</Button>
			),
		},
	];

	return (
		<>
			<LoadingOverlay active={isLoading} spinner={<ClipLoader />}>
				{tableData && (
					<>
						<Row>
							<Col xl={{ span: 12 }} className="py-5 px-xl-5 px-lg-5 px-md-3">
								<CSVLink
									data={csv && csv}
									headers={headers}
									filename={'Advertiser Dashboard Data.csv'}
									onClick={() => {
										if (csv && csv.length == 0) {
											return false;
										} else {
											return true;
										}
									}}
								>
									<Button
										className="button shadow-none float-right"
										disabled={csv && csv.length == 0 ? true : false}
									>
										<b>Export to CSV</b>
									</Button>
								</CSVLink>
								<TableBaseComponent COLUMNS={COLUMNS} DATA={tableData} />
								<PaginationComponent
									filter={filter}
									setFilter={setFilter}
									handleTableAction={handleTableAction}
									totalUser={totalRecord}
								/>
							</Col>
						</Row>
						{show && (
							<AdvertiserModalTable
								show={show}
								setShow={setShow}
								companyUser={companyUser}
								domain={domain}
							/>
						)}
					</>
				)}
			</LoadingOverlay>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		advertiserTable: state.advertiserTable,
		// loading: state.category.loading,
	};
};

export default connect(mapStateToProps, {
	AdvertiserDashboardTableAction,
	advertiserModalTableAction,
})(AdvertiserTableComponent);
// export default AdvertiserTableComponent;
