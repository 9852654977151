import { ManualAuthApiCall } from '../../middlewares/apiMiddleware';
import { toQueryString } from '../../utils/index';
import history from 'utils/history';
export const MEMBER_TABLE = 'MEMBER_TABLE';
export const MEMBER_TABLE_SUCCESS = 'MEMBER_TABLE_SUCCESS';
export const MEMBER_TABLE_FAILURE = 'MEMBER_TABLE_FAILURE';
// export const LOGOUT = 'LOGOUT';

export const MemberTableAction = (filter) => async (dispatch) => {
	const queryString = toQueryString(filter);

	dispatch({
		type: MEMBER_TABLE,
	});

	const apiRequest = await ManualAuthApiCall(
		`/auth/admin/member?${queryString}`,
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			// body: JSON.stringify(filter),
		},
		dispatch,
		MEMBER_TABLE_FAILURE,
		history
	);

	if (apiRequest.error === false) {
		dispatch({ type: MEMBER_TABLE_SUCCESS, apiResponse: apiRequest });
		// history.push('/app/dashboard');
	}
};
