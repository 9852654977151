import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Container, Button, Form } from 'react-bootstrap';
import Tug from '../../../assets/images/Tug.svg';
import AuthHeaderComponent from '../../common/Auth/HeaderComponent/HeaderComponent';
import AuthFooterComponent from '../../common/Auth/FooterComponent/FooterComponent';
import PublicHeaderComponent from '../../common/PublicHeaderFooter/HeaderComponent';
import { MyTextField } from '../../../Validations/InputField';
import { resetPasswordAction } from '../../../actions';
import { isLoggedIn } from 'utils';
import queryString from 'query-string';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ClipLoader } from 'react-spinners';
import './PasswordResetStyles.css';

const PasswordReset = (
	{
		resetPassword: { apiErrors, isLoading, user },
		match: {
			params: { resetPasswordCode },
		},
		resetPasswordAction,
		history,
		location,
	},
	dispatch
) => {
	const [serverError, setServerErrors] = useState({});

	useEffect(() => {
		setServerErrors(apiErrors);
	}, [apiErrors]);

	useEffect(() => {}, [isLoading, apiErrors, user, location.pathname]);

	return (
		<>
			<div className="authfullContainer">
				{isLoggedIn() ? <AuthHeaderComponent /> : <PublicHeaderComponent />}

				<div className="fullBg">
					<Container fluid>
						<Row className="header1 pt-5">
							<Col className="mt-5 ml-5">
								<h1 className="emailVerificationFont">Reset your password</h1>
							</Col>
						</Row>

						<Row className="header1">
							<Col>
								<img className="img-fluid float-right" src={Tug} alt="tug" />
							</Col>
						</Row>
					</Container>
				</div>

				<Container fluid>
					<Row className="emailPasswordTop">
						<Col
							className="mt-xl-0 mt-lg-0 mt-md-5 mt-5 p-xl-5 p-lg-5 p-md-5 p-5 col-xl-6 col-lg-6 col-md-8 offset-xl-3 offset-lg-3 offset-md-2"
							style={{ backgroundColor: '#FCF9F2', maxWidth: '50rem' }}
						>
							<div className="mx-xl-5 mx-lg-4">
								<h1>Account Password</h1>
								<p className="mb-5" style={{ color: '#9C9C9C' }}>
									You can reset your password below.
								</p>

								<Formik
									initialValues={{
										newPassword: '',
										rePassword: '',
									}}
									onSubmit={async (values, { setSubmitting }) => {
										setSubmitting(true);

										values = {
											newPassword: values.newPassword,
											verificationCode: resetPasswordCode,
										};

										// alert(JSON.stringify(values));

										dispatch(resetPasswordAction(values));
										setSubmitting(false);
									}}
									validationSchema={Yup.object().shape({
										newPassword: Yup.string()
											.min(
												6,
												'Password is too short - should be 6 characters minimum.'
											)
											.max(
												20,
												'Your characters limit is exceded - maximum 20 characters allowed'
											)
											.required("Password can't be empty")
											.matches(
												/^.*(?=.{6,})(?=.*\d)((?=.*[A-Z]){1}).*$/,
												'Password must contain at least one uppercase character and one number'
											),
										rePassword: Yup.string()
											.required("Re-type password can't be empty")
											.oneOf(
												[Yup.ref('newPassword'), null],
												'Passwords must match'
											),
									})}
								>
									{(props) => {
										const {
											values,
											touched,
											errors,
											// isSubmitting,
											handleChange,
											// handleBlur,
											handleSubmit,
											// setStatus,
										} = props;
										return (
											<Form onSubmit={handleSubmit} noValidate>
												<MyTextField
													placeholder="New Password"
													inputType="password"
													fieldName="newPassword"
													// onChange={(e) => {
													// 	customHandle(handleChange, 'emailAddress', e);
													// }}
													serverError={serverError}
													errors={errors.newPassword || false}
													inputValue={values.newPassword}
													fieldTouch={touched.newPassword}
													setServerErrors={setServerErrors}
													apiErrors={apiErrors}
													handleChange={handleChange}
												/>

												<MyTextField
													placeholder="Re-enter Password"
													inputType="password"
													fieldName="rePassword"
													// onChange={(e) => {
													// 	customHandle(handleChange, 'emailAddress', e);
													// }}
													serverError={serverError}
													errors={errors.rePassword || false}
													inputValue={values.rePassword}
													fieldTouch={touched.rePassword}
													setServerErrors={setServerErrors}
													apiErrors={apiErrors}
													handleChange={handleChange}
												/>

												{/* <PuffLoader size={20} color="#FDBF00" /> */}
												<Button
													type="submit"
													className="loginButton mb-4 mt-5"
													disabled={isLoading}
												>
													{isLoading ? (
														<ClipLoader size={20} color="#FCF9F2" />
													) : (
														'Change Password'
													)}
												</Button>
											</Form>
										);
									}}
								</Formik>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<AuthFooterComponent />
		</>
	);
};

//export default EmailVerification;

const mapStateToProps = (state) => ({
	resetPassword: state.resetPassword,
});

export default connect(mapStateToProps, { resetPasswordAction })(
	withRouter(PasswordReset)
);
