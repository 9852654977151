import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import * as _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from 'react-csv';
import {
	PaginationComponent,
	TableBaseComponent,
	PendingSuccess,
} from 'components/common';
import ColumnFilterText from './ColumnFilterText';
import ModalTableComponent from './ModalTableComponent';
import './TableStyle.css';
import { connect } from 'react-redux';
import { dashboardTableAction } from '../../../../actions';
import LoadingOverlay from 'react-loading-overlay';
import { ClipLoader } from 'react-spinners';
import { nameSplitter } from 'utils';

const TableComponent = ({
	userTable: { isLoading, tableData },
	dashboardTableAction,
	userId,
}) => {
	const [show, setShow] = useState(false);
	const [site, setSite] = useState(false);

	const handleClose = () => setShow(false);
	const [filter, setFilter] = useState({
		filter: [],
		sorting: [],
		pagination: [],
	});

	const handleTableAction = () => {
		dashboardTableAction(filter, userId);
	};

	useEffect(() => {
		// if (!_.isUndefined(userId.id)) {
		dashboardTableAction(filter, userId);
		// }
	}, [filter, userId]);

	const totalRecord = tableData && tableData[0] && tableData[0].totalRecord;
	let csv = [];

	tableData &&
		tableData.map((item, index) => {
			item.visitedAt = new Date(item.visitedAt).toLocaleDateString();

			csv[index] = {
				registeredDomain: item.registeredDomain,
				coinEarnt: item.coinEarnt,
				visitedAt: item.visitedAt,
				isPaymentVerified: !item.isPaymentVerified ? 'pending' : 'success',
			};
		});

	console.log('TABLE DATA', tableData);
	console.log('CSV', csv);

	const headers = [
		{ label: 'Company Domain Name', key: 'registeredDomain' },
		{ label: 'Coins Earnt', key: 'coinEarnt' },
		{ label: 'Visited Date', key: 'visitedAt' },
		{ label: 'Payment Status', key: 'isPaymentVerified' },
	];

	const COLUMNS = [
		{
			Header: () => {
				return (
					<>
						<div className="header-wrapper" style={{ marginRight: '-5em' }}>
							<span className="buttonHeaders">Company</span>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<ColumnFilterText
								filter={filter}
								setFilter={setFilter}
								handleTableAction={handleTableAction}
							/>
						</div>
					</>
				);
			},
			accessor: 'registeredDomain',
		},
		{
			Header: () => {
				return (
					<>
						<div
							className="header-wrapper"
							style={{ cursor: 'pointer', marginRight: '-3em' }}
							onClick={() => {
								filter['sorting'] &&
								filter['sorting']['coinEarnt'] &&
								filter['sorting']['coinEarnt'] == 'asc'
									? (filter['sorting']['coinEarnt'] = 'desc')
									: (filter['sorting'] = {
											...filter['sorting'],
											coinEarnt: 'asc',
									  });

								setFilter(filter);
								handleTableAction();
							}}
						>
							<span>Coins earnt</span>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<span className="tableSortIcon">
								<ul>
									<li>
										{filter['sorting'] &&
										filter['sorting']['coinEarnt'] &&
										filter['sorting']['coinEarnt'] == 'asc' ? (
											<FontAwesomeIcon icon={faCaretDown} />
										) : (
											<FontAwesomeIcon icon={faCaretUp} />
										)}
									</li>
								</ul>
							</span>
						</div>
					</>
				);
			},
			accessor: 'coinEarnt',
		},
		{
			Header: () => (
				<div
					className="header-wrapper"
					style={{ cursor: 'pointer', marginRight: '-4em' }}
					onClick={() => {
						filter['sorting'] &&
						filter['sorting']['visitedAt'] &&
						filter['sorting']['visitedAt'] == 'asc'
							? (filter['sorting']['visitedAt'] = 'desc')
							: (filter['sorting'] = {
									...filter['sorting'],
									visitedAt: 'asc',
							  });

						setFilter(filter);
						handleTableAction();
					}}
				>
					<span>
						Date &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
					</span>
					<span className="tableSortIcon">
						<ul>
							{/* <li
								style={{ cursor: 'pointer' }}
								onClick={() => {
									filter['sorting'] = { sort: 'asc', columnName: 'date' };
									setFilter(filter);
									handleFilter();
								}}
							>
								<OverlayTrigger
									placement="top"
									overlay={<Tooltip id="tooltip-disabled">Ascending</Tooltip>}
								>
									<FontAwesomeIcon icon={faCaretUp} />
								</OverlayTrigger>
							</li> */}

							<li>
								{filter['sorting'] &&
								filter['sorting']['visitedAt'] &&
								filter['sorting']['visitedAt'] == 'asc' ? (
									<FontAwesomeIcon icon={faCaretDown} />
								) : (
									<FontAwesomeIcon icon={faCaretUp} />
								)}
							</li>
						</ul>
					</span>
				</div>
			),
			accessor: 'visitedAt',
		},
		{
			Header: () => {
				return (
					<div className="header-wrapper">
						<span>Status</span>
					</div>
				);
			},
			Cell: ({ cell: { value } }) =>
				value ? (
					<PendingSuccess
						type="success"
						width="30px"
						className="img-fluid mx-4"
					/>
				) : (
					<PendingSuccess
						type="pending"
						width="30px"
						className="img-fluid mx-4"
					/>
				),
			accessor: 'isPaymentVerified',
		},
		{
			Header: () => {
				return (
					<div className="header-wrapper">
						<span>Action</span>
					</div>
				);
			},
			accessor: 'id',
			Cell: ({ cell: { value } }) => (
				<Button
					className="button shadow-none"
					onClick={() => {
						setSite(value);
						setShow(value);
					}}
				>
					<b>View</b>
				</Button>
			),
		},
	];

	return (
		<>
			<LoadingOverlay active={isLoading} spinner={<ClipLoader />}>
				{/* {!isLoading && ( */}
				<>
					<Row>
						<Col xl={{ span: 12 }} className="py-5 px-xl-5 px-lg-5 px-md-3">
							<CSVLink
								data={csv && csv}
								headers={headers}
								filename={'Customer Dashboard Data.csv'}
								onClick={() => {
									if (csv && csv.length == 0) {
										return false;
									} else {
										return true;
									}
								}}
							>
								<Button
									className="button shadow-none float-right"
									disabled={csv && csv.length == 0 ? true : false}
								>
									<b>Export to CSV</b>
								</Button>
							</CSVLink>
							<TableBaseComponent
								COLUMNS={COLUMNS}
								DATA={tableData && tableData}
							/>
							<PaginationComponent
								filter={filter}
								setFilter={setFilter}
								handleTableAction={handleTableAction}
								totalUser={totalRecord}
							/>
						</Col>
					</Row>
					{show && (
						<ModalTableComponent show={show} setShow={setShow} site={site} />
					)}
				</>
				{/* )} */}
			</LoadingOverlay>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		userTable: state.userTable,
	};
};

export default connect(mapStateToProps, {
	dashboardTableAction,
})(TableComponent);
