import {
	ManualApiCall,
	ManualAuthApiCall,
} from '../../middlewares/apiMiddleware';
import { toQueryString } from '../../utils/index';
import history from 'utils/history';
export const COMPANIES_TABLE = 'COMPANIES_TABLE';
export const COMPANIES_TABLE_SUCCESS = 'COMPANIES_TABLE_SUCCESS';
export const COMPANIES_TABLE_FAILURE = 'COMPANIES_TABLE_FAILURE';
// export const LOGOUT = 'LOGOUT';

export const CompaniesTableAction = (filter) => async (dispatch) => {
	const queryString = toQueryString(filter);

	dispatch({
		type: COMPANIES_TABLE,
	});

	const apiRequest = await ManualAuthApiCall(
		`/auth/admin/companies?${queryString}`,
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			// body: JSON.stringify(filter),
		},
		dispatch,
		COMPANIES_TABLE_FAILURE,
		history,
		true
	);

	if (apiRequest.error === false) {
		dispatch({ type: COMPANIES_TABLE_SUCCESS, apiResponse: apiRequest });
		// history.push('/app/dashboard');
	}
};
