import { ManualApiCall } from '../../middlewares/apiMiddleware';
import { saveAuthToken } from '../../utils/index';
import history from 'utils/history';
export const EMAIL_VERIFICATION = 'EMAIL_VERIFICATION';
export const EMAIL_VERIFICATION_SUCCESS = 'EMAIL_VERIFICATION_SUCCESS';
export const EMAIL_VERIFICATION_FAILURE = 'EMAIL_VERIFICATION_FAILURE';
// export const LOGOUT = 'LOGOUT';

export const emailVerificationAction = (payload) => async (dispatch) => {
	dispatch({
		type: EMAIL_VERIFICATION,
	});

	const apiRequest = await ManualApiCall(
		`/public/verify/verfication/code`,
		{
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify(payload),
		},
		dispatch,
		EMAIL_VERIFICATION_FAILURE,
		history
	);
	if (apiRequest.error === false) {
		saveAuthToken(apiRequest.data);
		dispatch({ type: EMAIL_VERIFICATION_SUCCESS, apiResponse: apiRequest });
		if (apiRequest.data.user.userRole === 'CUSTOMER') {
			history.push('/app/customer/dashboard');
		} else {
			history.push('/app/advertiser/dashboard');
		}
	}
};

// export const logout = () => ({
// 	type: LOGOUT,
// });
