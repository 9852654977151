import { ManualAuthApiCall } from '../../middlewares/apiMiddleware';
import history from 'utils/history';

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE';

export const DeleteAccountAction = (payload) => async (dispatch) => {
	dispatch({
		type: DELETE_ACCOUNT,
	});

	const apiRequest = await ManualAuthApiCall(
		`/auth/account/delete`,
		{
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify(payload),
		},
		dispatch,
		DELETE_ACCOUNT_FAILURE,
		history,
		true
	);
	if (apiRequest.error === false) {
		dispatch({ type: DELETE_ACCOUNT_SUCCESS, apiResponse: apiRequest });
		// localStorage.clear();
		// history.go(0);
	}
};
