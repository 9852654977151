import React, { useState, useEffect } from 'react';
import { Container, Row, Button, Modal } from 'react-bootstrap';
import '../Advertiser/AdvertiserProfileComponent/profileComponent.css';
import DeleteUserComponent from './DeleteUserComponent/DeleteUserComponent';
import crossIcon from '../../assets/images/crossIcon.svg';

const DeleteUser = ({ userId }) => {
	// const [serverError, setServerErrors] = useState({});

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);

	useEffect(() => {
		// console.log('user delete id', userId);
	}, [userId]);
	return (
		<>
			<Row>
				<div
					className=" col-xl-11  col-lg-11 col-md-12 col-sm-12 col-xs-12 px-xl-5 px-lg-5 px-sm-1 px-xs-1  overlap"
					style={{
						backgroundColor: '#FCF9F2',
						position: 'relative',
						marginTop: '-17%',
					}}
				>
					<div className="mt-4 pt-4 mb-4 pb-4  px-xl-4 px-lg-0 VerifiedQuery  verified1">
						<h1 className="">Delete Member</h1>
						<p style={{ color: '#9C9C9C' }}>
							To delete member's account click the button below.
						</p>
						<Button
							className="loginButton  border-0  "
							onClick={() => setShow(true)}
						>
							Delete Account
							{/* {<PuffLoader size={20} color="#3c8f7c" />} */}
						</Button>
						<Modal
							show={show}
							onHide={() => setShow(false)}
							dialogClassName="customModal2"
							aria-labelledby="example-custom-modal-styling-title"
						>
							<Modal.Header className="colorModal">
								<Button
									className="closeButton border-0 shadow-none rounded-0"
									onClick={handleClose}
								>
									<img
										className="m-xl-3 m-lg-3 m-md-3 m-1"
										src={crossIcon}
										alt="icon"
									/>

									{/* <img className="mx-1 img-fluid" src={crossIcon} /> */}
								</Button>
								<Modal.Title className="colorModal"></Modal.Title>
							</Modal.Header>
							<Modal.Body className="colorModal">
								<Container fluid>
									<DeleteUserComponent
										handleClose={handleClose}
										userId={userId}
									/>
								</Container>
							</Modal.Body>
						</Modal>
					</div>
				</div>
			</Row>
		</>
	);
};

export default DeleteUser;
