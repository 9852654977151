import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Button, Modal } from 'react-bootstrap';
import crossIcon from '../../../assets/images/crossIcon.svg';
import Profile from '../../../assets/images/Profile.svg';
import {
	AuthAdvertiserHeaderComponent,
	AuthFooterComponent,
	LogoutComponent,
	GetVerifiedAdvertiser,
	AccountPassword,
	DeleteAccount,
	AboutYou,
	YourEmail,
	PhoneNumber,
	DomainOwnership,
	CoinsPerUser,
	PendingPaymentDetail,
	SuccessPaymentDetail,
} from 'components/common';

import './profileComponent.css';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchProfileAction } from '../../../actions';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const AdvertiserProfileComponent = ({
	profile: { user },
	fetchProfileAction,
}) => {
	// const [showError, setShowError] = useState(true);
	// const [serverError, setServerErrors] = useState({});
	const stripePromise = loadStripe(
		'pk_test_51IXlqCDB7M7TFWnni7FL2UNVGXgqQC4XOuFU2yqwLJ7RTtxA5iKwCQOo1Q7yC2ogz1xjsOsRcWZox3956ajwuDzR00gm1lQr6Z'
	);

	useEffect(() => {
		fetchProfileAction();
	}, []);

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);

	console.log('user', user);

	return (
		<>
			<div className="authfullContainer">
				<AuthAdvertiserHeaderComponent user={user && user} />
				<div className="fullBg">
					<Container fluid>
						<Row style={{ backgroundColor: '#F4F0E3' }}>
							<div className="offset-xl-1 col-xl-5 offset-lg-1 col-lg-6 offset-md-1 col-md-6 offset-sm-1 col-sm-6 pr-0">
								<div className=" ml-xl-4 ml-lg-4 ml-md-4 ml-sm-4 ml-0 mt-5">
									<div>
										<h1 className="heading">Your Profile</h1>
										<div>
											<ul className="profile ">
												<li className="d-inline-block pr-2 mr-3 ">
													<Link
														className="link text-decoration-none"
														onClick={() => setShow(true)}
													>
														Logout
													</Link>
													<Modal
														show={show}
														onHide={() => setShow(false)}
														dialogClassName="customModal2"
														aria-labelledby="example-custom-modal-styling-title"
													>
														<Modal.Header className="colorModal">
															<Button
																className="closeButton border-0 shadow-none rounded-0"
																onClick={handleClose}
															>
																<img
																	className="m-xl-3 m-lg-3 m-md-3 m-1"
																	src={crossIcon}
																	alt="icon"
																/>

																{/* <img className="mx-1 img-fluid" src={crossIcon} /> */}
															</Button>
															<Modal.Title className="colorModal"></Modal.Title>
														</Modal.Header>
														<Modal.Body className="colorModal">
															<LogoutComponent handleClose={handleClose} />
														</Modal.Body>
													</Modal>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<Col xl={6}>
								<img src={Profile} alt="profile" class="img-fluid" />
							</Col>
						</Row>
					</Container>
				</div>
				<Container fluid>
					<Row>
						<div className=" col-xl-4 col-lg-5 col-md-12 col-sm-12 col-xs-12 ">
							<GetVerifiedAdvertiser status={user && user.status} />
							<CoinsPerUser />
							<AccountPassword />
							<DeleteAccount />
						</div>

						<div className="col-xl-8  col-lg-7 col-md-10 col-sm-12 col-xm-12 ">
							<AboutYou user={user && user} />
							<YourEmail user={user && user} />
							<PhoneNumber user={user && user} />
							<DomainOwnership user={user && user} />
							{user &&
							user.stripePayment &&
							user.stripePayment.billing_details ? (
								<SuccessPaymentDetail user={user && user} />
							) : (
								<Elements stripe={stripePromise}>
									<PendingPaymentDetail user={user && user} />
								</Elements>
							)}
						</div>
					</Row>
				</Container>
			</div>
			<AuthFooterComponent />
		</>
	);
};

// export default AdvertiserProfileComponent;

const mapStateToProps = (state) => ({
	profile: state.profile,
});

export default connect(mapStateToProps, {
	fetchProfileAction,
})(withRouter(AdvertiserProfileComponent));
