import {
	ManualApiCall,
	ManualAuthApiCall,
} from '../../middlewares/apiMiddleware';
import { toQueryString } from '../../utils/index';
import history from 'utils/history';
export const DASHBOARD_MODAL_TABLE = 'DASHBOARD_MODAL_TABLE';
export const DASHBOARD_MODAL_TABLE_SUCCESS = 'DASHBOARD_MODAL_TABLE_SUCCESS';
export const DASHBOARD_MODAL_TABLE_FAILURE = 'DASHBOARD_MODAL_TABLE_FAILURE';
// export const LOGOUT = 'LOGOUT';

export const dashboardModalTableAction = (site, filter) => async (dispatch) => {
	dispatch({
		type: DASHBOARD_MODAL_TABLE,
	});

	const apiRequest = await ManualAuthApiCall(
		`${
			!filter
				? `/customer/dashboard/popup?siteId=${site}`
				: `/customer/dashboard/popup?siteId=${site}&${toQueryString(filter)}`
		}`,
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			// body: JSON.stringify(asma),
		},
		dispatch,
		DASHBOARD_MODAL_TABLE_FAILURE,
		history
	);
	console.log('apiRequest for first action', apiRequest);
	if (apiRequest.error === false) {
		dispatch({ type: DASHBOARD_MODAL_TABLE_SUCCESS, apiResponse: apiRequest });
		// history.push('/app/dashboard');
	}
};
