import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import PendingSuccess from '../../common/PendingSuccess';
import { stripeDeleteAction } from '../../../actions';
import history from 'utils/history';
import Visa from '../../../assets/images/Visa.svg';
import mastercard from '../../../assets/images/mastercard.svg';
import amex from '../../../assets/images/amex.svg';
import discover from '../../../assets/images/discover.svg';
import dinersclub from '../../../assets/images/dinersclub.svg';
import '../../Advertiser/AdvertiserProfileComponent/profileComponent.css';

const SuccessPaymentDetail = ({
	stripeDelete: { isPaymentSaving, data, apiErrors },
	stripeDeleteAction,
	user,
}) => {
	const [isLoading, setLoading] = useState(false);

	useEffect(() => {
		if (!isPaymentSaving && !apiErrors) {
			setLoading(false);
			history.go(0);
		}
	}, [isPaymentSaving, apiErrors]);
	const handleClick = () => {
		stripeDeleteAction(user);
	};
	const date = new Date(
		user && user.stripePayment && user.stripePayment.created * 1000
	).toLocaleDateString('en-US');
	return (
		<>
			<div className="line2">
				<div className="position-relative ml-3  ml-md-5 ml-sm-4  ml-xm-3 ">
					<Row className="mt-5 ">
						<div className="col-xl-11 col-lg-11  col-md-11  col-sm-8   col-sx-7 mt-5  ">
							<div
								className="d-flex"
								style={{ justifyContent: 'space-between' }}
							>
								<div style={{ width: '75%' }}>
									<h1 className="profileHeading">Payment Details</h1>
									<p>
										The payment details below will be charged weekly and is
										calculated by the number of users that visit your website
										times the cost per user. You will not be charged for your
										first 20 users.
									</p>
								</div>
								<div>
									<PendingSuccess
										className="mt-5 "
										type="success"
										width="50px"
										// height="40px"
									/>
								</div>
							</div>
						</div>
					</Row>

					<Row>
						<Col xl={4} lg={8} md={8}>
							<div className="border px-4 py-3" style={{ height: '9em' }}>
								<h1 className="cardHeading pb-2"> Payment Method</h1>
								{user &&
								user.stripePayment &&
								user.stripePayment.card &&
								user.stripePayment.card.brand ? (
									user.stripePayment.card.brand == 'mastercard' ? (
										<img src={mastercard} alt="logo" width="50px" />
									) : user.stripePayment.card.brand == 'visa' ? (
										<img src={Visa} alt="logo" width="50px" />
									) : user.stripePayment.card.brand == 'amex' ? (
										<img src={amex} alt="logo" width="50px" />
									) : user.stripePayment.card.brand == 'discover' ? (
										<img src={discover} alt="logo" width="50px" />
									) : user.stripePayment.card.brand == 'diners' ? (
										<img src={dinersclub} alt="logo" width="50px" />
									) : (
										''
									)
								) : (
									''
								)}
								{/* <img src={Visa} alt="logo" /> */}
								<p>
									**** **** ****{' '}
									{user &&
										user.stripePayment &&
										user.stripePayment.card &&
										user.stripePayment.card.last4}
								</p>
							</div>
							{/* <Card style={{ width: '22rem' }}>
								<Card.Body>
									<Card.Title className="cardHeading">
										Payment Method
									</Card.Title>
									<img src={Visa} alt="logo" className="" />
									<p>**** **** **** 4242</p>
								</Card.Body>
                                </Card> */}
						</Col>
						<Col xl={5} lg={8} md={8}>
							<div className="border px-4 pt-3" style={{ height: '9em' }}>
								<h1 className="cardHeading"> Subscription</h1>
								<p>Started: {date}</p>
								<Button onClick={handleClick} className="paymentButton mb-3">
									{isLoading ? (
										<ClipLoader size={20} color="#FCF9F2" />
									) : (
										`Remove Payment Method`
									)}
								</Button>
							</div>
							{/* <Card style={{ width: '24rem' }}>
								<Card.Body>
									<Card.Title className="cardHeading">Subscription</Card.Title>
									<Card.Subtitle className="mb-2 text-muted">
										Started: 11/08/2020
									</Card.Subtitle>
									<Button type="submit" className="paymentButton    ">
										Cancel and Remove Payment Method
									</Button>
								</Card.Body>
							
							</Card> */}
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};

// export default SuccessPaymentDetail;

const mapStateToProps = (state) => ({
	stripeDelete: state.stripeDeleteReducer,
});

export default connect(mapStateToProps, {
	stripeDeleteAction,
})(withRouter(SuccessPaymentDetail));
