import { combineReducers } from 'redux';
// import {} from '../../'
// import * as ActionTypes from '../actions'

// import jobDescription from './jobDescription';
// import editJob from './editJob';
// import popup from './popup';

//...................USER REDUCER.....................//

import LoginReducer from './user/LoginReducer';
import RegisterReducer from './user/RegisterReducer';
import DashboardTableReducer from './user/DashboardTable';
import { GetDashboardReducer } from './user/DashboardReducer';
import ReferralInvitation from './user/ReferrInvitationReducer';
import AccountPasswordReducer from './user/AccountPasswordReducer';
import DashboardModalTableReducer from './user/DashbaordModalTable';
import EmailVerificationReducer from './user/EmailVerificationReducer';
import RedeemGiftReducer from './user/RedeemGiftReducer';

//...................COMMON REDUCER.....................//

import EnterEmailReducer from './common/EmailEnterReducer';
import { ResendReducer } from './common/Profile/ResendReducer';
import { GetEmailReducer } from './common/Profile/EmailReducer';
import RestPasswordReducer from './common/ResetPasswordReducer';
import DeleteAccountReducer from './common/DeleteAccountReducer';
import { AboutYouReducer } from './common/Profile/AboutYouReducer';
import { PhoneNumberReducer } from './common/Profile/PhoneNumberReducer';
import { FetchProfileReducer } from './common/Profile/FetchProfileReducer';

//...................ADVERTISER REDUCER.....................//

import DomainOwnerReducer from './advertiser/DomainOwerReducer';
import StripeDeleteReducer from './advertiser/StripeDeleteReducer';
import StripePaymentReducer from './advertiser/StripePaymentReducer';
import AdvertiserRegisterReducer from './advertiser/AdvertiserRegisterReducer';
import AdvertiserDashboardTableReducer from './advertiser/AdvertiserDashboardReducer';
import AdvertiserDashboardModalTable from './advertiser/AdvertiserDashbaordModalTable';
import AdvertiserInvoiceTableReducer from './advertiser/AdvertiserInvoiceTableReducer';

//...................ADMIN REDUCER.....................//
import MemberTableReducer from './admin/MemberTableReducer';
import MemberViewTableReducer from './admin/MemberViewTableReducer';
import MemberViewModalTableReducer from './admin/MemberViewTableReducer';
import CompaniesTableReducer from './admin/CompaniesTableReducer';
import CompanyInvoicesTableReducer from './admin/CompanyInvoicesTableReducer';
import InvoicesTableReducer from './admin/InvoicesTableReducer';
import { AdminBonusReducer } from './admin/AdminBonusReducer';
import { CompanyDetailReducer } from './admin/CompanyDetailReducer';

// import RegisterAdvertiserReducer from './advertiser/RegisterAdvertiserReducer';
// import score from './score';
// import calendar from './calendar';
// import editProgress from './editProgress';
// import tribe from './tribe';
// import jobs from './jobs';
// import tabs from './tabs';
// const user = (state = { isLoading: false, isDataAvailable: false, data: null }, action) => {
//   switch (action.type) {
//     case ActionTypes.REQUEST_SET_USER:
//       return { ...state, isLoading: true }

//     case ActionTypes.REQUEST_SET_USER_FAILURE:
//       return { ...state, isLoading: false, isDataAvailable: false }

//     case ActionTypes.REQUEST_SET_USER_SUCCESS:
//       return { ...state, isLoading: false, isDataAvailable: true, data: action.payload }
//     default:
//       return state
//   }
// }

export default combineReducers({
	// jobDescription,
	// auth,
	// editJob,
	// popup,
	// authAdvertiserRegister: RegisterAdvertiserReducer,

	//...................USER REDUCER.....................//
	auth: LoginReducer,
	authRegister: RegisterReducer,
	userTable: DashboardTableReducer,
	dashboard: GetDashboardReducer,
	referral: ReferralInvitation,
	accountPassword: AccountPasswordReducer,
	userModalTable: DashboardModalTableReducer,
	emailVerification: EmailVerificationReducer,
	redeemGift: RedeemGiftReducer,

	//...................COMMON REDUCER.....................//
	sendEmail: EnterEmailReducer,
	resend: ResendReducer,
	email: GetEmailReducer,
	resetPassword: RestPasswordReducer,
	deleteAccount: DeleteAccountReducer,
	aboutYou: AboutYouReducer,
	phoneNumber: PhoneNumberReducer,
	profile: FetchProfileReducer,

	//...................ADVERTISER REDUCER.....................//
	domainOwner: DomainOwnerReducer,
	stripeDeleteReducer: StripeDeleteReducer,
	stripePayment: StripePaymentReducer,
	authAdvertiserRegister: AdvertiserRegisterReducer,
	advertiserTable: AdvertiserDashboardTableReducer,
	advertiserModalTable: AdvertiserDashboardModalTable,
	invoiceTable: AdvertiserInvoiceTableReducer,

	//...................ADMIN REDUCER.....................//
	memberTable: MemberTableReducer,
	memberViewTable: MemberViewTableReducer,
	memberViewModalTable: MemberViewModalTableReducer,
	companiesTable: CompaniesTableReducer,
	companyInvoices: CompanyInvoicesTableReducer,
	invoicesTable: InvoicesTableReducer,
	bonus: AdminBonusReducer,
	companyDetail: CompanyDetailReducer,

	// editJob,
	// score,
	// calendar,
	// editProgress,
	// tribe,
	// jobs,
	// tabs,
});
