import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import happyGirl from '../../../assets/images/HappyGirl.svg';
import Line from '../../../assets/images/Line.svg';
import PendingSuccess from '../../common/PendingSuccess';
import { AuthAdminHeaderComponent } from '../../common';
import FooterComponent from '../../common/Auth/FooterComponent/FooterComponent';
import TableComponent from './AdminTableComponent';
import { DashbaordAction } from '../../../actions';
import { connect } from 'react-redux';
import Profile from '../../../assets/images/Profile.svg';
import './AdminStyle.css';

const AdminCompaniesDashboard = ({
	dashboard: { apiErrors, isLoading, user },
	DashbaordAction,
}) => {
	const [totalMember, setTotalMember] = useState({
		totalcomapany: 0,
	});
	useEffect(() => {
		DashbaordAction();
	}, []);
	return (
		<>
			<div className="authfullContainer">
				<AuthAdminHeaderComponent user={user && user} />
				<div className="fullBg">
					<Container fluid className="font">
						<Row className="header1 pt-5">
							<Col xl={3} lg={3} md={{ span: 12, offset: 1 }} sm={3} xs={3}>
								<img
									className="img-fluid"
									src={Profile}
									className="admin-company-img"
									width="115%"
									alt="happy girl"
								/>
							</Col>
							<Col xl={8} lg={8} md={12}>
								<Row className="pt-5 mt-3">
									<Col className="pr-0 col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12">
										<h1 className="CompanyMaxText ml-xl-5 ml-lg-5 ml-sm-5 ml-xs-5 ml-3">
											Welcome Back
											<b>
												{user && user.status && user.status.bioVerified
													? `, ${user.fullName}`
													: ''}
											</b>
										</h1>
										<h4 className="CompanyCoinSummary ml-xl-5 ml-lg-5 ml-sm-5 ml-xs-5 ml-3">
											Here are the Gravy companies
										</h4>
									</Col>
								</Row>
								<Row className="mt-4 mx-xl-5 mx-lg-5 mx-md-5 coins-position">
									<Col
										xl={12}
										lg={12}
										md={12}
										sm={12}
										xs={12}
										style={{
											backgroundColor: '#FCF9F2',
											justifyContent: 'center',
										}}
										className="py-5 d-flex"
									>
										{/* <div className="pt-5 d-flex imgSuccess"> */}
										<div>
											<PendingSuccess
												type="success"
												width="50px"
												className="img-fluid mx-4 imgSuccess"
											/>
										</div>

										<h1 className="cointext">
											Total Companies:{' '}
											<b>{totalMember && totalMember.totalcomapany}</b>
										</h1>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</div>
				<Container fluid className="mt-5 font">
					<TableComponent
						totalMember={totalMember}
						setTotalMember={setTotalMember}
					/>
				</Container>
			</div>
			<FooterComponent />
		</>
	);
};

const mapStateToProps = (state) => ({
	dashboard: state.dashboard,
});

export default connect(mapStateToProps, {
	DashbaordAction,
})(withRouter(AdminCompaniesDashboard));
