// import * as ActionTypes from '../../actions/home'
import * as ActionTypes from '../../actions/';

const initialState = {
	isPasswordLoading: false,
	user: [],
	apiErrors: [],
};

export const ACCOUNT_PASSWORD = 'ACCOUNT_PASSWORD';
export const ACCOUNT_PASSWORD_SUCCESS = 'ACCOUNT_PASSWORD_SUCCESS';
export const ACCOUNT_PASSWORD_FAILURE = 'ACCOUNT_PASSWORD_FAILURE';

const AccountPasswordReducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.ACCOUNT_PASSWORD:
			return { ...state, isPasswordLoading: true };

		case ActionTypes.ACCOUNT_PASSWORD_SUCCESS:
			return {
				...state,
				isPasswordLoading: false,
				user: action.apiResponse.data ? action.apiResponse.data : null,
				apiErrors: null,
			};

		case ActionTypes.ACCOUNT_PASSWORD_FAILURE:
			return {
				...state,
				isPasswordLoading: false,
				user: null,
				apiErrors: action.apiResponse.error ? action.apiResponse.errors : null,
			};
		default:
			return state;
	}
};

export default AccountPasswordReducer;
