import {
	ManualApiCall,
	ManualAuthApiCall,
} from '../../middlewares/apiMiddleware';
import { toQueryString } from '../../utils/index';
import history from 'utils/history';
export const ADVERTISER_MODAL_TABLE = 'ADVERTISER_MODAL_TABLE';
export const ADVERTISER_MODAL_TABLE_SUCCESS = 'ADVERTISER_MODAL_TABLE_SUCCESS';
export const ADVERTISER_MODAL_TABLE_FAILURE = 'ADVERTISER_MODAL_TABLE_FAILURE';
// export const LOGOUT = 'LOGOUT';

export const advertiserModalTableAction =
	(companyUser, domain, filter) => async (dispatch) => {
		dispatch({
			type: ADVERTISER_MODAL_TABLE,
		});

		const apiRequest = await ManualAuthApiCall(
			`${
				!filter
					? `/advertiser/dashboard/popup?companyUser=${companyUser}&domain=${domain}`
					: `/advertiser/dashboard/popup?companyUser=${companyUser}&domain=${domain}&${toQueryString(
							filter
					  )}`
			}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
				// body: JSON.stringify(asma),
			},
			dispatch,
			ADVERTISER_MODAL_TABLE_FAILURE,
			history
		);
		if (apiRequest.error === false) {
			dispatch({
				type: ADVERTISER_MODAL_TABLE_SUCCESS,
				apiResponse: apiRequest,
			});
			// history.push('/app/dashboard');
		}
	};
