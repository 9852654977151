import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Modal, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import ColumnFilterText from './AdminColumnFilterText';
import { ColumnFilterCheckbox } from './AdminCompaniesFilterCheckbox';
import checkSmall from '../../../assets/images/CheckSmall.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faSort,
	faCaretDown,
	faCaretUp,
} from '@fortawesome/free-solid-svg-icons';
import crossIcon from '../../../assets/images/Cross.svg';
// import TableBaseComponent from '../../common/TableBaseComponent';
// import PaginationComponent from '../../common/Pagination';
// import PendingSuccess from '../../common/PendingSuccess';
import {
	ModalPaginationComponent,
	PaginationComponent,
	TableBaseComponent,
	PendingSuccess,
} from 'components/common';
import history from 'utils/history';
import './AdminStyle.css';
import { CompaniesTableAction } from '../../../actions';

const TableComponent = (
	{
		companiesTable: { apiErrors, isLoading, tableData },
		CompaniesTableAction,
		totalMember,
		setTotalMember,
	},
	user
) => {
	const [filter, setFilter] = useState({
		filter: [],
		sorting: [],
		pagination: [],
		checkFilter: [],
	});

	useEffect(() => {
		CompaniesTableAction(filter);
	}, [filter]);

	const handleTableAction = () => {
		CompaniesTableAction(filter);
	};

	let csv = [];

	tableData &&
		tableData.map((item, index) => {
			item.createdAt = new Date(item.createdAt).toLocaleDateString('en-US');

			csv[index] = {
				registeredDomain: item.registeredDomain,
				advertiserPayableCoin: item.advertiserPayableCoin,
				createdAt: item.createdAt,
				customerPayableCoin: item.customerPayableCoin,
			};
		});

	console.log('TABLE', tableData);
	useEffect(() => {
		setTotalMember({
			totalcomapany: tableData && tableData[0] && tableData[0].totalcomapany,
		});
	}, [tableData]);

	console.log('CSV', csv);

	const headers = [
		{ label: 'Host Domain', key: 'registeredDomain' },
		{ label: 'Company Coins', key: 'advertiserPayableCoin' },
		{ label: 'Created At', key: 'createdAt' },
		{ label: 'User Coins', key: 'customerPayableCoin' },
	];

	const COLUMNS = [
		{
			Header: () => {
				return (
					<>
						<div className="header-wrapper" style={{ marginRight: '-3em' }}>
							<span className="buttonHeaders">Host</span>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<ColumnFilterText
								filter={filter}
								setFilter={setFilter}
								handleTableAction={handleTableAction}
							/>
						</div>
					</>
				);
			},
			accessor: 'registeredDomain',
		},
		{
			Header: () => {
				return (
					<>
						<div
							className="header-wrapper"
							style={{ marginRight: '-2em' }}
							onClick={() => {
								filter['sorting'] &&
								filter['sorting']['companyCoins'] &&
								filter['sorting']['companyCoins'] == 'ASC'
									? (filter['sorting']['companyCoins'] = 'DESC')
									: (filter['sorting'] = {
											...filter['sorting'],
											companyCoins: 'ASC',
									  });

								setFilter(filter);
								handleTableAction();
							}}
						>
							<span>Company Coins</span>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<span className="tableSortIcon" style={{ cursor: 'pointer' }}>
								{filter['sorting'] &&
								filter['sorting']['companyCoins'] &&
								filter['sorting']['companyCoins'] == 'ASC' ? (
									<FontAwesomeIcon icon={faCaretDown} />
								) : (
									<FontAwesomeIcon icon={faCaretUp} />
								)}
							</span>
						</div>
					</>
				);
			},
			accessor: 'advertiserPayableCoin',
		},
		{
			Header: () => (
				<div
					className="header-wrapper"
					style={{ marginRight: '-3em' }}
					onClick={() => {
						filter['sorting'] &&
						filter['sorting']['created'] &&
						filter['sorting']['created'] == 'ASC'
							? (filter['sorting']['created'] = 'DESC')
							: (filter['sorting'] = {
									...filter['sorting'],
									created: 'ASC',
							  });

						setFilter(filter);
						handleTableAction();
					}}
				>
					<span>
						Created &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
						{/* <FontAwesomeIcon icon={faSort} /> */}
					</span>
					<span className="tableSortIcon" style={{ cursor: 'pointer' }}>
						{filter['sorting'] &&
						filter['sorting']['created'] &&
						filter['sorting']['created'] == 'ASC' ? (
							<FontAwesomeIcon icon={faCaretDown} />
						) : (
							<FontAwesomeIcon icon={faCaretUp} />
						)}
					</span>
				</div>
			),
			accessor: 'createdAt',
		},
		{
			Header: () => {
				return (
					<>
						<div className="header-wrapper" style={{ marginRight: '-4em' }}>
							<span className="buttonHeaders">User Coins</span>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<ColumnFilterCheckbox
								filter={filter}
								setFilter={setFilter}
								handleTableAction={handleTableAction}
							/>
						</div>
					</>
				);
			},
			accessor: 'customerPayableCoin',
		},
		{
			Header: () => {
				return (
					<div className="header-wrapper">
						<span>Profile</span>
					</div>
				);
			},
			accessor: 'member',
			Cell: ({ row }) => {
				const data = row.original;
				// console.log('id cell data', data);
				return (
					<Link to={`/app/admin/memberview?id=${data.id}`}>
						<Button className="button shadow-none">
							<b>View</b>
						</Button>
					</Link>
				);
			},
		},
		{
			Header: () => {
				return (
					<div className="header-wrapper">
						<span>Company Coins</span>
					</div>
				);
			},
			accessor: 'action',

			Cell: ({ row }) => {
				const data = row.original;
				return (
					<Link to={`/app/admin/company/view?id=${data.id}`}>
						<Button className="button shadow-none">
							<b>View</b>
						</Button>
					</Link>
				);
			},
		},
	];

	return (
		<>
			<Row className="mt-5">
				<Col
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					className="py-5 px-xl-5 px-lg-5 px-md-5 mt-3"
				>
					<CSVLink
						data={csv && csv}
						headers={headers}
						filename={'Admin Companies Data.csv'}
						onClick={() => {
							if (csv && csv.length == 0) {
								return false;
							} else {
								return true;
							}
						}}
					>
						<Button
							className="button shadow-none float-right"
							disabled={csv && csv.length == 0 ? true : false}
						>
							<b>Export to CSV</b>
						</Button>
					</CSVLink>
					<TableBaseComponent COLUMNS={COLUMNS} DATA={tableData && tableData} />
					<PaginationComponent
						filter={filter}
						setFilter={setFilter}
						handleTableAction={handleTableAction}
						totalUser={tableData && tableData[0] && tableData[0].totalUser}
					/>
				</Col>
			</Row>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		companiesTable: state.companiesTable,
	};
};

export default connect(mapStateToProps, { CompaniesTableAction })(
	TableComponent
);
// export default TableComponent;
