import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import './Footer.css';

const FooterComponent = () => {
	return (
		<>
			<div className="fullBg fullFooter">
				<footer class="footer" style={{ backgroundColor: '#f5f0e3' }}>
					<Container fluid pt-5>
						<Row pl-xl-5>
							<Col
								xl={10}
								xl={{ offset: 0 }}
								lg={8}
								lg={{ offset: 0 }}
								md={8}
								sm={6}
								xs={6}
							>
								<p className="copyrights pl-xl-5 pl-lg-5 pl-md-5">
									© 2021 Gravy. All Rights Reserved.
								</p>
							</Col>

							<Col
								xl={2}
								lg={2}
								md={2}
								sm={1}
								xs={1}
								className="pl-xl-4 ml-xl-5"
							>
								<div className="d-flex pl-xl-5">
									<a className=" pl-5  footertext">Terms</a>
									<a className=" pl-4 footertext"> Privacy</a>
								</div>
								{/* <ul className="footer">
                <li>
                  <a className="pr-5 footertext"> Terms</a>
                </li>
                <li>
                  <a className="footertext"> Privacy</a>
                </li>
              </ul> */}
							</Col>
						</Row>
					</Container>
				</footer>
			</div>
		</>
	);
};

export default FooterComponent;
