import { ManualAuthApiCall } from '../../middlewares/apiMiddleware';
import { isLoggedIn } from '../../utils';
import history from 'utils/history';
export const STRIPE_PAYMENT = 'STRIPE_PAYMENT';
export const STRIPE_PAYMENT_SUCCESS = 'STRIPE_PAYMENT_SUCCESS';
export const STRIPE_PAYMENT_FAILURE = 'STRIPE_PAYMENT_FAILURE';

export const stripePaymentAction = (payload) => async (dispatch) => {
	dispatch({
		type: STRIPE_PAYMENT,
	});

	const apiRequest = await ManualAuthApiCall(
		`/auth/advertiser/payment/stripe`,
		{
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify(payload),
		},
		dispatch,
		STRIPE_PAYMENT_FAILURE,
		history
	);
	if (apiRequest.error === false) {
		dispatch({ type: STRIPE_PAYMENT_SUCCESS, apiResponse: apiRequest });

		// if (isLoggedIn().user.userRole === 'CUSTOMER') {
		// 	history.push('/app/customer/dashboard');
		// } else {
		// 	history.push('/app/advertiser/dashboard');
		// }
	}
};
