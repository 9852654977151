import React from 'react';
import { Container, Row, Button } from 'react-bootstrap';
import Tug from '../../../assets/images/Tug.svg';
import './DeleteUser.css';
import { DeleteAccountAction } from '../../../actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import history from 'utils/history';

const DeleteUserComponent = (
	{
		deleteAccount: { apiErrors, isLoading, response },
		DeleteAccountAction,
		handleClose,
		userId,
	},
	dispatch
) => {
	const handleDelete = () => {
		// console.log('delete id', userId);
		DeleteAccountAction(userId);
		history.push('/app/admin/member');
	};

	return (
		<>
			<Container fluid className="containerColour">
				<Row>
					<div className="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 offset-md-2 col-md-8">
						<div class="cardLogout mt-xl-2 my-lg-1 my-md-5">
							<div
								class="card-header py-xl-5 py-lg-5 py-md-4 text-center"
								style={{ backgroundColor: '#DDEDE0' }}
							>
								<h2>Delete Member</h2>
							</div>
							<div
								class="card-body py-xl-5 py-lg-5 text-center"
								style={{
									backgroundColor: '#FCF9F2',
								}}
							>
								<p class="card-text pb-2 logoutTest pt-xl-5 pt-lg-5 pt-md-5">
									Are you sure you want to delete?
								</p>
								<Button
									className="border-0 px-5 logoutButton shadow-none rounded-0 mb-5"
									onClick={handleDelete}
								>
									Delete
								</Button>
							</div>
						</div>
					</div>
				</Row>
				<Row>
					<div className="col-xl-12 mt-1">
						<img className="img-fluid tug" src={Tug} alt="tug" />
					</div>
				</Row>
			</Container>
		</>
	);
};

// export default DeleteAccountComponent;

const mapStateToProps = (state) => ({
	deleteAccount: state.deleteAccount,
});

export default connect(mapStateToProps, {
	DeleteAccountAction,
})(withRouter(DeleteUserComponent));
