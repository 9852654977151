import { ManualAuthApiCall } from '../../middlewares/apiMiddleware';
import { toQueryString } from 'utils';
import * as _ from 'lodash';
export const ADVERTISER_DASHBOARD_TABLE = 'ADVERTISER_DASHBOARD';
export const ADVERTISER_DASHBOARD_TABLE_SUCCESS =
	'ADVERTISER_DASHBOARD_TABLE_SUCCESS';
export const ADVERTISER_DASHBOARD_TABLE_FAILURE =
	'ADVERTISER_DASHBOARD_TABLE_FAILURE';

export const AdvertiserDashboardTableAction =
	(filter, userId) => async (dispatch) => {
		dispatch({
			type: ADVERTISER_DASHBOARD_TABLE,
		});
		console.log(userId);

		const apiRequest = await ManualAuthApiCall(
			`${
				!filter
					? '/advertiser/dashboard'
					: `/advertiser/dashboard?${toQueryString(filter)}${
							!_.isEmpty(userId) ? `&id=${userId && userId.id}` : ''
					  }`
			}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
				// body: JSON.stringify(asma),
			},
			dispatch,
			ADVERTISER_DASHBOARD_TABLE_FAILURE
		);
		if (apiRequest.error === false) {
			dispatch({
				type: ADVERTISER_DASHBOARD_TABLE_SUCCESS,
				apiResponse: apiRequest,
			});
			// history.push('/app/advertisor-dashboard');
		}
	};
