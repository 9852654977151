import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import {
	PaginationComponent,
	TableBaseComponent,
	PendingSuccess,
} from 'components/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { AdvertiserInvoiceTableAction } from 'actions';
import './AdvertiserInvoicesTableStyle.css';

const TableComponent = ({
	invoiceTable: { isLoading, invoiceTable },
	AdvertiserInvoiceTableAction,
	userId,
}) => {
	const [filter, setFilter] = useState({
		filter: [],
		sorting: [],
		pagination: [],
	});

	const handleTableAction = () => {
		AdvertiserInvoiceTableAction(filter, userId);
	};

	useEffect(() => {
		AdvertiserInvoiceTableAction(filter, userId);
	}, [filter, userId]);
	let csv = [];
	invoiceTable &&
		invoiceTable.map((item, index) => {
			item.verifiedAt = new Date(item && item.verifiedAt).toLocaleDateString();

			csv[index] = {
				description: item.description,
				costPerCoin: item.costPerCoin,
				isVerified: !item.isVerified ? 'pending' : 'success',
				verifiedAt: item.verifiedAt,
				invoiceUrl: item.invoiceUrl,
			};
		});

	const headers = [
		{ label: 'Description', key: 'description' },
		{ label: 'Cost per customer', key: 'costPerCoin' },
		{ label: 'Time', key: 'verifiedAt' },
		{ label: 'Payment Status', key: 'isVerified' },
		{ label: 'Invoice URL', key: 'invoiceUrl' },
	];

	const COLUMNS = [
		{
			Header: () => {
				return (
					<>
						<div className="header-wrapper" style={{ marginRight: '-2em' }}>
							<span style={{ paddingTop: '30px', paddingBottom: '30px' }}>
								Description
							</span>
						</div>
					</>
				);
			},
			accessor: 'description',
		},
		{
			Header: () => (
				<div
					className="header-wrapper"
					style={{ cursor: 'pointer', marginRight: '-3em' }}
					onClick={() => {
						filter['sorting'] &&
						filter['sorting']['costPerCoin'] &&
						filter['sorting']['costPerCoin'] == 'ASC'
							? (filter['sorting']['costPerCoin'] = 'DESC')
							: (filter['sorting'] = {
									...filter['sorting'],
									costPerCoin: 'ASC',
							  });

						setFilter(filter);
						handleTableAction();
					}}
				>
					<span>Cost per customer &ensp;&nbsp;&nbsp;</span>
					<span className="tableSortIcon">
						<ul>
							<li>
								{filter['sorting'] &&
								filter['sorting']['costPerCoin'] &&
								filter['sorting']['costPerCoin'] == 'ASC' ? (
									<FontAwesomeIcon icon={faCaretDown} />
								) : (
									<FontAwesomeIcon icon={faCaretUp} />
								)}
							</li>
						</ul>
					</span>
				</div>
			),
			accessor: 'costPerCoin',
		},
		{
			Header: () => (
				<div
					className="header-wrapper"
					style={{ cursor: 'pointer', marginRight: '-2em' }}
					onClick={() => {
						filter['sorting'] &&
						filter['sorting']['verifiedAt'] &&
						filter['sorting']['verifiedAt'] == 'ASC'
							? (filter['sorting']['verifiedAt'] = 'DESC')
							: (filter['sorting'] = {
									...filter['sorting'],
									verifiedAt: 'ASC',
							  });

						setFilter(filter);
						handleTableAction();
					}}
				>
					<span>Time &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
					<span className="tableSortIcon">
						<ul>
							<li>
								{filter['sorting'] &&
								filter['sorting']['verifiedAt'] &&
								filter['sorting']['verifiedAt'] == 'ASC' ? (
									<FontAwesomeIcon icon={faCaretDown} />
								) : (
									<FontAwesomeIcon icon={faCaretUp} />
								)}
							</li>
						</ul>
					</span>
				</div>
			),
			accessor: 'verifiedAt',
		},
		{
			Header: () => (
				<div className="header-wrapper" style={{ marginRight: '-4em' }}>
					<span>
						Payment Status
						&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
					</span>
				</div>
			),

			Cell: ({ cell: { value } }) =>
				value ? (
					<PendingSuccess
						type="success"
						width="30px"
						className="img-fluid mx-4"
					/>
				) : (
					<PendingSuccess
						type="pending"
						width="30px"
						className="img-fluid mx-4"
					/>
				),
			accessor: 'isVerified',
		},
		{
			Header: () => {
				return (
					<div className="header-wrapper">
						<span>Action</span>
					</div>
				);
			},
			accessor: 'invoiceUrl',
			Cell: ({ cell: { value } }) => (
				<a href={value} target="_blank">
					<Button className="button shadow-none">
						<b>View</b>
					</Button>
				</a>
			),
		},
	];

	return (
		<>
			<Row className="mt-5">
				<Col xl={{ span: 12 }} className="p-5 mt-3">
					<CSVLink
						data={csv && csv}
						headers={headers}
						filename={'Advertiser Invoices Data.csv'}
						onClick={() => {
							if (csv && csv.length == 0) {
								return false;
							} else {
								return true;
							}
						}}
					>
						<Button
							className="button shadow-none float-right"
							disabled={csv && csv.length == 0 ? true : false}
						>
							<b>Export to CSV</b>
						</Button>
					</CSVLink>
					<TableBaseComponent
						COLUMNS={COLUMNS}
						DATA={invoiceTable && invoiceTable}
					/>
					<PaginationComponent
						filter={filter}
						setFilter={setFilter}
						handleTableAction={handleTableAction}
						totalUser={
							invoiceTable && invoiceTable[0] && invoiceTable[0].totalRecord
						}
					/>
				</Col>
			</Row>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		invoiceTable: state.invoiceTable,
		// loading: state.category.loading,
	};
};

export default connect(mapStateToProps, { AdvertiserInvoiceTableAction })(
	TableComponent
);
