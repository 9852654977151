import { ManualAuthApiCall } from '../../middlewares/apiMiddleware';
import history from 'utils/history';
import * as _ from 'lodash';
import { toQueryString } from '../../utils/index';
export const ADVERTISER_INVOICE_TABLE = 'ADVERTISER_INVOICE';
export const ADVERTISER_INVOICE_TABLE_SUCCESS =
	'ADVERTISER_INVOICE_TABLE_SUCCESS';
export const ADVERTISER_INVOICE_TABLE_FAILURE =
	'ADVERTISER_INVOICE_TABLE_FAILURE';

export const AdvertiserInvoiceTableAction =
	(filter, userId) => async (dispatch) => {
		// const queryString = toQueryString(filter);
		// console.log('...............ID........', userId);

		dispatch({
			type: ADVERTISER_INVOICE_TABLE,
		});

		const apiRequest = await ManualAuthApiCall(
			`${
				!filter
					? '/auth/advertiser/invoice'
					: `/auth/advertiser/invoice?${toQueryString(filter)}${
							!_.isEmpty(userId) ? `&id=${userId && userId.id}` : ''
					  }`
			}`,
			// `/auth/advertiser/invoice?${queryString}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
				// body: JSON.stringify(asma),
			},
			dispatch,
			ADVERTISER_INVOICE_TABLE_FAILURE
		);
		if (apiRequest.error === false) {
			dispatch({
				type: ADVERTISER_INVOICE_TABLE_SUCCESS,
				apiResponse: apiRequest,
			});
			// history.push('/app/advertisor-dashboard');
		}
	};
