import { Roles } from 'routeConfiguration';

// Components
import {
	Module1,
	Module2,
	Module3,
	ModuleN,
	ModuleNChild1,
	ModuleNChild2,
	ModuleNChild3,
	Dashboard,
	// Profile,
	CustomerGiftCardRedeemRNA,
	ReferFriend,
	ProfileComponent,
	AdvertiserProfileComponent,
	AdvertiserDashboard,
	AdvertiserInvoices,
	AdminCompaniesDashboard,
	AdminCompanyView,
	// EmailVerification,
	AdminInvoices,
	AdminProfile,
	AdminGiftCard,
	AdminMemberDashboard,
	AdminMemberView,
} from 'components';

// TODO:
/*
 * 1. Make title optional
 * 2. Make title multi type support ie: (string, node, react element)
 * 3. Add child route support
 * */

/*
 * Route config object supports all react-router's route component props with some additional props ie: (title, permission, children)
 * you can add or remove props from config object it's means it is super customizable and support upto N nesting, child routes must follow the same parent shape,
 * it means the config object is same for both there is no additional key for child nor for parent.
 * you can find route props from here https://reactrouter.com/web/api/Route let's take a look at some additional props
 * 1. permission: permission is an optional prop and it's type is an array of roles, permission is used for allowing certain users/roles
 *  	to have access of that route and if you skip/omit the permission or it's an empty array that means every authenticated user/role have access to that route.
 * 2. title: title is an optional prop and it's type is a string|node it is used for mapping route link into a navigation
 * 3. children: children is also an optional prop and it's type is an array of route config objects, children are used for nested routes
 * */

export default [
	{
		component: Module1,
		path: '/',
		title: 'Module - 1',
		exact: true,
	},
	{
		component: Module2,
		path: '/module-2',
		title: 'Module - 2',
	},
	{
		component: Module3,
		path: '/module-3',
		title: 'Module - 3',
	},
	{
		component: ModuleN,
		path: '/module-n',
		title: 'Module - N',
		permission: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.MANAGER],
		children: [
			{
				component: ModuleNChild1,
				path: '/child-1',
				title: 'Child - 1',
			},
			{
				component: ModuleNChild2,
				path: '/child-2',
				title: 'Child - 2',
			},
			{
				component: ModuleNChild3,
				path: '/child-3',
				title: 'Child - 3',
				permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
			},
		],
	},
	{
		component: Dashboard,
		path: '/customer/dashboard',
		title: 'Dashboard',
		permission: [Roles.ADVERTISER, Roles.CUSTOMER, Roles.ADMIN],
	},
	{
		component: ProfileComponent,
		path: '/customer/profile',
		title: 'Profile',
		permission: [Roles.ADVERTISER, Roles.CUSTOMER, Roles.ADMIN],
	},
	{
		component: CustomerGiftCardRedeemRNA,
		path: '/customer/CustomerGiftCardRedeemRNA',
		title: 'CustomerGiftCardRedeemRNA',
		permission: [Roles.ADVERTISER, Roles.CUSTOMER, Roles.ADMIN],
	},
	{
		component: ReferFriend,
		path: '/customer/ReferFriend',
		title: 'ReferFriend',
		permission: [Roles.ADVERTISER, Roles.CUSTOMER, Roles.ADMIN],
	},

	{
		component: AdvertiserProfileComponent,
		path: '/advertiser/profile',
		title: 'advertiser-profile',
		permission: [Roles.ADVERTISER, Roles.CUSTOMER, Roles.ADMIN],
	},
	{
		component: AdvertiserDashboard,
		path: '/advertiser/dashboard',
		title: 'advertiser-dashboard',
		permission: [Roles.ADVERTISER, Roles.CUSTOMER, Roles.ADMIN],
	},
	{
		component: AdvertiserInvoices,
		path: '/advertiser/invoices',
		title: 'advertiser-invoices',
		permission: [Roles.ADVERTISER, Roles.CUSTOMER, Roles.ADMIN],
	},
	{
		component: AdminCompaniesDashboard,
		path: '/admin/companies',
		title: 'admin-companies',
		permission: [Roles.ADVERTISER, Roles.CUSTOMER, Roles.ADMIN],
	},
	{
		component: AdminCompanyView,
		path: '/admin/company/view',
		title: 'admin-companiesView',
		permission: [Roles.ADMIN, Roles.CUSTOMER, Roles.ADVERTISER],
	},
	{
		component: AdminProfile,
		path: '/admin/profile',
		title: 'admin-profile',
		permission: [Roles.ADMIN, Roles.CUSTOMER, Roles.ADVERTISER],
	},
	{
		component: AdminGiftCard,
		path: '/admin/giftcard',
		title: 'admin-giftcard',
		permission: [Roles.ADMIN, Roles.CUSTOMER, Roles.ADVERTISER],
	},
	{
		component: AdminMemberDashboard,
		path: '/admin/member',
		title: 'admin-member',
		permission: [Roles.ADMIN, Roles.CUSTOMER, Roles.ADVERTISER],
	},
	{
		component: AdminMemberView,
		path: '/admin/memberview',
		title: 'admin-member-view',
		permission: [Roles.ADMIN, Roles.CUSTOMER, Roles.ADVERTISER],
	},
	{
		component: AdminInvoices,
		path: '/admin/invoices',
		permission: [Roles.ADMIN, Roles.CUSTOMER, Roles.ADVERTISER],
	},
	{
		component: Dashboard,
		path: '/customer/dashboard',
		title: 'Dashboard',
		permission: [Roles.CUSTOMER],
	},
	{
		component: ProfileComponent,
		path: '/customer/profile',
		title: 'Profile',
		permission: [Roles.CUSTOMER],
	},
	{
		component: CustomerGiftCardRedeemRNA,
		path: '/customer/CustomerGiftCardRedeemRNA',
		title: 'CustomerGiftCardRedeemRNA',
		permission: [Roles.CUSTOMER],
	},
	{
		component: ReferFriend,
		path: '/customer/ReferFriend',
		title: 'ReferFriend',
		permission: [Roles.CUSTOMER],
	},

	// {
	// 	component: AdvertiserProfileComponent,
	// 	path: '/advertiser/profile',
	// 	title: 'advertiser-profile',
	// 	permission: [Roles.ADVERTISER],
	// },
	// {
	// 	component: AdvertiserDashboard,
	// 	path: '/advertiser/dashboard',
	// 	title: 'advertiser-dashboard',
	// 	permission: [Roles.ADVERTISER],
	// },
	// {
	// 	component: AdvertiserInvoices,
	// 	path: '/advertiser/invoices',
	// 	title: 'advertiser-invoices',
	// 	permission: [Roles.ADVERTISER],
	// },
	// {
	// 	component: AdminCompaniesDashboard,
	// 	path: '/admin/companies',
	// 	title: 'admin-companies',
	// 	permission: [Roles.ADMIN],
	// },
	// {
	// 	component: AdminCompanyView,
	// 	path: '/admin/company/view',
	// 	title: 'admin-companiesView',
	// 	permission: [Roles.ADMIN],
	// },
	// {
	// 	component: AdminProfile,
	// 	path: '/admin/profile',
	// 	title: 'admin-profile',
	// 	permission: [Roles.ADMIN],
	// },
	// {
	// 	component: AdminGiftCard,
	// 	path: '/admin/giftcard',
	// 	title: 'admin-giftcard',
	// 	permission: [Roles.ADMIN],
	// },
	// {
	// 	component: AdminMemberDashboard,
	// 	path: '/admin/member',
	// 	title: 'admin-member',
	// 	permission: [Roles.ADMIN],
	// },
	// {
	// 	component: AdminMemberView,
	// 	path: '/admin/memberview',
	// 	title: 'admin-member-view',
	// 	permission: [Roles.ADMIN],
	// },
	// {
	// 	component: AdminInvoices,
	// 	path: '/admin/invoices',
	// 	permission: [Roles.ADMIN],
	// },
];
