import React from 'react';
// import classnames from 'classnames';
import { Form } from 'react-bootstrap';
// import FormControl from 'react-bootstrap/FormControl';
export const InputFeedback = ({ error }) =>
	error ? <div className="input-feedback">{error}</div> : null;

// const Label = ({ error, className, children, ...props }) => {
// 	return (
// 		<Form.Label className="label" {...props}>
// 			{children}
// 		</Form.Label>
// 	);
// };

export const MyTextField = ({
	placeholder,
	inputType,
	fieldName,
	onChange,
	serverError,
	errors,
	inputValue,
	fieldTouch,
	setServerErrors,
	apiErrors,
	handleChange,
	disabled,
	style,
}) => {
	const customHandle = (handleChange, type, e, apiErrors) => {
		// call the built-in handleBur
		handleChange(e);
		if (apiErrors) {
			// and do something about e
			RemoverServerErrors(serverError, setServerErrors, type);
		}
	};
	const RemoverServerErrors = (serverError, setServerErrors, fieldName) => {
		delete serverError[fieldName];
		setServerErrors(serverError);
	};
	// const classes = classnames({
	// 	' error': !!(errors[fieldName] || serverError[fieldName]),
	// });

	return (
		<>
			<Form.Group className={(errors || serverError) && 'classes'}>
				{/* <Form.Label
					className={
						fieldTouch && errors
							? 'invalid-title'
							: serverError && serverError[fieldName]
							? 'invalid-title'
							: ' '
					}
				>
					{label}
				</Form.Label> */}
				<Form.Control
					// placeholder={placeholder}
					name={fieldName}
					id={fieldName}
					type={inputType}
					value={inputValue}
					onChange={(e) => {
						customHandle(handleChange, fieldName, e, apiErrors);
					}}
					placeholder={placeholder}
					className={
						fieldTouch && errors
							? 'invalid-error animated shake error formField'
							: serverError && serverError[fieldName]
							? 'invalid-error animated shake error formField'
							: ' formField'
					}
					disabled={disabled}
					style={style}
				/>

				{fieldTouch && errors && (
					<div className="invalid-feedback d-block">{errors}</div>
				)}
				{serverError && serverError[fieldName] && (
					<div className="invalid-feedback d-block">
						{serverError[fieldName]}
					</div>
				)}

				{/* {meta.status && <InputFeedback error={meta.status} />} */}
			</Form.Group>
		</>
	);
};

/* <>
	<label>
		{label}
		<input {...field} {...props} />
	</label>
	{meta.touched && meta.error ? (
		<div className="error">{meta.error}</div>
	) : null}
</>; */
