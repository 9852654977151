import React from 'react';
import { useField, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import { Form } from 'react-bootstrap';
import classnames from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';

export const DatePickerField = ({ error, ...props }) => {
	const { setFieldValue } = useFormikContext();
	const [field] = useField(props);
	const classes = classnames({
		'animated shake error': !!error,
	});
	const InputFeedback = ({ error }) =>
		error ? <div className="invalid-feedback d-block">{error}</div> : null;
	return (
		<>
			<Form.Group className={classes}>
				<DatePicker
				
					{...field}
					{...props}
					selected={(field.value && new Date(field.value)) || null}
					onChange={(val) => {
						setFieldValue(field.name, val);
					}}
					className="formField form-control"
				/>
				<InputFeedback error={error} />
			</Form.Group>
		</>
	);
};

export default DatePickerField;
