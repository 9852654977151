import { ManualAuthApiCall } from '../../../middlewares/apiMiddleware';
import { isLoggedIn } from '../../../utils';
import history from 'utils/history';
export const PROFILE_ABOUTYOU = 'PROFILE_ABOUTYOU';
export const PROFILE_ABOUTYOU_SUCCESS = 'PROFILE_ABOUTYOU_SUCCESS';
export const PROFILE_ABOUTYOU_FAILURE = 'PROFILE_ABOUTYOU_FAILURE';

export const AboutYouAction = (payload) => async (dispatch) => {
	dispatch({
		type: PROFILE_ABOUTYOU,
	});

	const apiRequest = await ManualAuthApiCall(
		`/auth/profile/about/update`,
		{
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify(payload),
		},
		dispatch,
		PROFILE_ABOUTYOU_FAILURE,
		history
		// true
	);
	if (apiRequest.error === false) {
		dispatch({ type: PROFILE_ABOUTYOU_SUCCESS, apiResponse: apiRequest });
		// if (isLoggedIn().user.userRole === 'CUSTOMER') {
		// 	history.push('/app/customer/dashboard');
		// } else {
		// 	history.push('/app/advertiser/dashboard');
		// }
	}
};
