import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import {
	AuthFooterComponent,
	AuthAdminHeaderComponent,
} from 'components/common';
import Profile from '../../../assets/images/Profile.svg';
import { fetchProfileAction } from '../../../actions';
import { connect } from 'react-redux';
import '../../User/ProfileComponent/profileComponent.css';
import AdminGiftTable from './AdminGiftTable';
import './AdminGift.css';

const AdminGiftCard = ({
	profile: { apiErrors, isLoading, user },
	fetchProfileAction,
	dispatch,
}) => {
	// useEffect(() => {
	// 	fetchProfileAction();
	// }, []);

	// const [show, setShow] = useState(false);
	// const handleClose = () => setShow(false);

	return (
		<>
			<div className="authfullContainer">
				<AuthAdminHeaderComponent user={user && user} />
				<div className="fullBg">
					<Container fluid>
						<Row style={{ backgroundColor: '#F4F0E3', paddingTop: '100px' }}>
							<div className="offset-xl-1 col-xl-5 offset-lg-1 col-lg-6 offset-md-1 col-md-6 offset-sm-1 col-sm-6 pr-0">
								<div className=" ml-xl-4 ml-lg-4 ml-md-4 ml-sm-4 ml-0 mt-5">
									<div>
										<h1 className="heading admin-heading">Gift Cards</h1>
									</div>
								</div>
							</div>
							<Col xl={6}>
								<img
									src={Profile}
									alt="profile"
									className="img-fluid admin-girl"
								/>
							</Col>
						</Row>
					</Container>
				</div>
				<Container fluid className="mt-5 font">
					<AdminGiftTable />
				</Container>
			</div>
			<AuthFooterComponent />
		</>
	);
};

// export default ProfileComponent;

const mapStateToProps = (state) => ({
	profile: state.profile,
});

export default connect(mapStateToProps, {
	fetchProfileAction,
})(withRouter(AdminGiftCard));
