import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import history from 'utils/history';
import { connect } from 'react-redux';
import { Row, Col, Button, Form } from 'react-bootstrap';
import '../Advertiser/AdvertiserProfileComponent/profileComponent.css';
import { MyTextField } from '../../Validations/InputField';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PendingSuccess from './PendingSuccess';
import { AdminBonusAction } from '../../actions';
import { ClipLoader } from 'react-spinners';
const AdminBonus = (
	{
		bonus: { apiErrors, isAboutYouSaving, updatedUser },
		AdminBonusAction,
		userId,
	},
	dispatch
) => {
	const [serverError, setServerErrors] = useState({});
	const [stateList, setStateList] = useState([]);
	const [cityList, setCityList] = useState([]);

	return (
		<>
			<div>
				<div className="position-relative ml-3  ml-md-5 ml-sm-4  ml-xm-3  ">
					<Row className=" my-5 ">
						<div className="col-xl-11 col-lg-11  col-md-8  col-sm-7  ">
							<div
								className="d-flex"
								style={{ justifyContent: 'space-between' }}
							>
								<div>
									<h1 className="profileHeading">Admin Bonus Coins</h1>
								</div>
							</div>
						</div>
					</Row>
					<Row>
						<Col xl={10} lg={10} md={12} sm={12} xs={12}>
							<Formik
								enableReinitialize={true}
								initialValues={{
									bonusName: '',
									coinAmount: '',
								}}
								onSubmit={async (values, { setSubmitting }) => {
									setSubmitting(true);
									values['userId'] = userId;
									AdminBonusAction(values);
									setSubmitting(false);
								}}
								validationSchema={Yup.object().shape({
									bonusName: Yup.string()
										.required("Bonus Name can't be empty")
										.max(40, 'Bonus Name must be less then 40 char'),

									coinAmount: Yup.number('Coin amount must be a number')
										.required("Coin Amount can't be empty")
										.positive("Coin amount can't be negative")
										.integer("Coin amount can't be in decemal"),
								})}
							>
								{(props) => {
									const {
										values,
										touched,
										errors,
										isSubmitting,
										handleChange,
										handleBlur,
										handleSubmit,
										// setStatus,
										setFieldValue,
									} = props;
									return (
										<Form onSubmit={handleSubmit} noValidate>
											<Row>
												<Col xl={6} lg={12} md={12} sm={12} xs={12}>
													<div className="">
														<MyTextField
															placeholder="Bonus name"
															inputType="text"
															fieldName="bonusName"
															// onChange={(e) => {
															// 	customHandle(handleChange, 'emailAddress', e);
															// }}
															serverError={serverError}
															errors={errors.bonusName || false}
															inputValue={values.bonusName}
															fieldTouch={touched.bonusName}
															setServerErrors={setServerErrors}
															apiErrors={apiErrors}
															handleChange={handleChange}
															onBlur={handleBlur}
														/>
													</div>
												</Col>
												<Col xl={6} lg={12} md={12} sm={12} xs={12}>
													<div className="">
														<MyTextField
															placeholder="Coin amount"
															inputType="text"
															fieldName="coinAmount"
															// onChange={(e) => {
															// 	customHandle(handleChange, 'password', e);
															// }}
															serverError={serverError}
															errors={errors.coinAmount || false}
															inputValue={values.coinAmount}
															fieldTouch={touched.coinAmount}
															setServerErrors={setServerErrors}
															// apiErrors={apiErrors}
															handleChange={handleChange}
														/>
													</div>
												</Col>
											</Row>

											<Button
												type="submit"
												className="loginButton   my-4 "
												disabled={isAboutYouSaving}
											>
												{isAboutYouSaving ? (
													<ClipLoader size={20} color="#FCF9F2" />
												) : (
													`Save`
												)}
											</Button>
										</Form>
									);
								}}
							</Formik>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	bonus: state.bonus,
});

export default connect(mapStateToProps, {
	AdminBonusAction,
})(withRouter(AdminBonus));
