// import * as ActionTypes from '../../actions/home'
import * as ActionTypes from '../../actions/';

const initialState = {
	isLoading: false,
	user: [],
	apiErrors: [],
};

export const REDEEM_GIFT = 'REDEEM_GIFT';
export const REDEEM_GIFT_SUCCESS = 'REDEEM_GIFT_SUCCESS';
export const REDEEM_GIFT_FAILURE = 'REDEEM_GIFT_FAILURE';

const RedeemGiftReducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.REDEEM_GIFT:
			return { ...state, isLoading: true };

		case ActionTypes.REDEEM_GIFT_SUCCESS:
			return {
				...state,
				isLoading: false,
				user: action.apiResponse.data ? action.apiResponse.data : null,
				apiErrors: null,
			};

		case ActionTypes.REDEEM_GIFT_FAILURE:
			return {
				...state,
				isLoading: false,
				user: null,
				apiErrors: action.apiResponse.error ? action.apiResponse.errors : null,
			};
		default:
			return state;
	}
};

export default RedeemGiftReducer;
