// import * as ActionTypes from '../../actions/home'
import * as ActionTypes from '../../actions';

const initialState = {
	isLoading: false,
	user: [],
	apiErrors: [],
};


export const REGISTER_ADVERTISER = 'REGISTER_ADVERTISER';
export const REGISTER_ADVERTISER_SUCCESS = 'REGISTER_ADVERTISER_SUCCESS';
export const REGISTER_ADVERTISER_FAILURE = 'REGISTER_ADVERTISER_FAILURE';

const AdvertiserRegisterReducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.REGISTER_ADVERTISER:
			return { ...state, isLoading: true };

		case ActionTypes.REGISTER_ADVERTISER_SUCCESS:
			return {
				...state,
				isLoading: false,
				user: action.apiResponse.data ? action.apiResponse.data : null,
				apiErrors: null,
			};

		case ActionTypes.REGISTER_ADVERTISER_FAILURE:
			return {
				...state,
				isLoading: false,
				user: null,
				apiErrors: action.apiResponse.error ? action.apiResponse.errors : null,
			};
		default:
			return state;
	}
};

export default AdvertiserRegisterReducer;
