import * as _ from 'lodash';
// import { isUndefined } from 'lodash';

export function isLoggedIn() {
	/*
	 * Note:
	 *  This app assume if local storage have roles it means
	 *  user is authenticated you can update this logic as per your app.
	 */

	// return !!localStorage.getItem('user');
	return !_.isNull(localStorage.getItem('user'))
		? localStorage.getItem('user')
		: false;
	// return !!localStorage.getItem('roles');
}

export function isArrayWithLength(arr) {
	return Array.isArray(arr) && arr.length;
}

export function getAllowedRoutes(routes) {
	const getUser = localStorage.getItem('roles');
	const getUsers = JSON.parse(localStorage.getItem('user'));
	const role = !_.isNull(getUsers) ? getUsers.user.userRole : null;
	// console.log(role);
	const roles = JSON.parse(getUser);
	return routes.filter(({ permission }) => {
		if (!permission) return true;
		else if (!isArrayWithLength(permission)) return true;
		else {
			// console.log([role]);
			const resp = _.intersection(permission, [role]).length;
			// console.log({ resp });
			return resp;
		}
	});
}
export const getToken = () => {
	if (_.isNull(localStorage.getItem('user'))) return null;
	return `Bearer ${JSON.parse(localStorage.getItem('user')).token}`;
};
export const checkBrowser = () => {
	return new Promise((resolve, reject) => {
		resolve(true);
	});
};
export const saveAuthToken = (token) =>
	token ? localStorage.setItem('user', JSON.stringify(token)) : false;
export const toQueryString = (paramsObject) =>
	Object.keys(paramsObject)
		.map(
			(key) =>
				`${encodeURI(key)}=${encodeURI(JSON.stringify(paramsObject[key]))}`
		)
		.join('&');

export const roleAbbrevation = (role) => {
	const roles = {
		1: 'ADMIN',
		2: 'Company',
		3: 'User',
		4: 'SUPER-ADMIN',
	};
	return roles[role];
};
export const nameSplitter = (name) => {
	if (_.isUndefined(name) || _.isNull(name)) {
		return ['Gravy', 'User'];
	} else if (_.isUndefined(name) || !name.includes(' ')) {
		return [name, ''];
	} else {
		const firstName = name.split(' ');
		return firstName;
	}
};

export const browserChecker = () => {
	var sBrowser,
		sUsrAg = navigator.userAgent;

	// The order matters here, and this may report false positives for unlisted browsers.

	if (sUsrAg.indexOf('Firefox') > -1) {
		sBrowser = 'Mozilla Firefox';
		// "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
	} else if (sUsrAg.indexOf('SamsungBrowser') > -1) {
		sBrowser = 'Samsung Internet';
		// "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
	} else if (sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1) {
		sBrowser = 'Opera';
		// "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
	} else if (sUsrAg.indexOf('Trident') > -1) {
		sBrowser = 'Microsoft Internet Explorer';
		// "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
	} else if (sUsrAg.indexOf('Edg') > -1) {
		sBrowser = 'Microsoft Edge';
		// "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
	} else if (sUsrAg.indexOf('Chrome') > -1) {
		sBrowser = 'Google Chrome or Chromium';
		// "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
	} else if (sUsrAg.indexOf('Safari') > -1) {
		sBrowser = 'Apple Safari';
		// "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
	} else {
		sBrowser = 'unknown';
	}

	// alert('You are using: ' + sBrowser);
	return sBrowser;
};
