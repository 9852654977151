import { ManualAuthApiCall } from '../../middlewares/apiMiddleware';
import history from 'utils/history';

export const DOMAIN_OWNER = 'DOMAIN_OWNER';
export const DOMAIN_OWNER_SUCCESS = 'DOMAIN_OWNER_SUCCESS';
export const DOMAIN_OWNER_FAILURE = 'DOMAIN_OWNER_FAILURE';

export const DomainOwnerAction = (payload) => async (dispatch) => {
	dispatch({
		type: DOMAIN_OWNER,
	});

	const apiRequest = await ManualAuthApiCall(
		`/auth/advertiser/verify/domain`,
		{
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify(payload),
		},
		dispatch,
		DOMAIN_OWNER_FAILURE
	);
	if (apiRequest.error === false) {
		dispatch({ type: DOMAIN_OWNER_SUCCESS, apiResponse: apiRequest });
		history.go(0);
	}
};
