import { ManualAuthApiCall } from '../../middlewares/apiMiddleware';
import { isLoggedIn } from '../../utils';
import history from 'utils/history';
export const ADMIN_BONUS = 'ADMIN_BONUS';
export const ADMIN_BONUS_SUCCESS = 'ADMIN_BONUS_SUCCESS';
export const ADMIN_BONUS_FAILURE = 'ADMIN_BONUS_FAILURE';

export const AdminBonusAction = (payload) => async (dispatch) => {
	dispatch({
		type: ADMIN_BONUS,
	});

	const apiRequest = await ManualAuthApiCall(
		`/auth/admin/member/bonus`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify(payload),
		},
		dispatch,
		ADMIN_BONUS_FAILURE,
		history
	);
	if (apiRequest.error === false) {
		dispatch({ type: ADMIN_BONUS_SUCCESS, apiResponse: apiRequest });
	}
};
