import React, { useState, useEffect } from 'react';
import { Row, Button, Modal, Container } from 'react-bootstrap';
import { DeleteAccountComponent } from '..';
import crossIcon from '../../../assets/images/crossIcon.svg';
import '../../Advertiser/AdvertiserProfileComponent/profileComponent.css';

const DeleteAccount = () => {
	// const [serverError, setServerErrors] = useState({});
	useEffect(() => {});

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	return (
		<>
			<Row>
				<div
					className="col-xl-11 col-lg-11 col-md-12"
					style={{ backgroundColor: '#FCF9F2' }}
				>
					<div className="mt-5 pt-3 pb-5 px-5">
						<h1>Delete Account</h1>
						<p style={{ color: '#9C9C9C' }}>
							To delete your account click the button below, this will also
							delete any coins you have earned.
						</p>
						<Button
							className="loginButton  border-0  "
							onClick={() => setShow(true)}
						>
							Delete Account
							{/* {<PuffLoader size={20} color="#3c8f7c" />} */}
						</Button>
						<Modal
							show={show}
							onHide={() => setShow(false)}
							dialogClassName="customModal2"
							aria-labelledby="example-custom-modal-styling-title"
						>
							<Modal.Header className="colorModal">
								<Button
									className="closeButton border-0 shadow-none rounded-0"
									onClick={handleClose}
								>
									<img
										className="m-xl-3 m-lg-3 m-md-3 m-1"
										src={crossIcon}
										alt="icon"
									/>

									{/* <img className="mx-1 img-fluid" src={crossIcon} /> */}
								</Button>
								<Modal.Title className="colorModal"></Modal.Title>
							</Modal.Header>
							<Modal.Body className="colorModal">
								<Container fluid>
									<DeleteAccountComponent handleClose={handleClose} />
								</Container>
							</Modal.Body>
						</Modal>
					</div>
				</div>
			</Row>
		</>
	);
};

export default DeleteAccount;
