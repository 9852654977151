import React from 'react';
import { Container, Button, Nav, Navbar } from 'react-bootstrap';
import './Header.css';
import { Link } from 'react-router-dom';
import GravyIcon from '../../../../assets/images/GravyIcon.svg';
import { browserChecker } from 'utils';

const HeaderComponent = () => {
	const handleClick = () => {
		const browser = browserChecker();
		if (browser === 'Google Chrome or Chromium') {
			window.open('https://google.com/');
		} else if (browser === 'Mozilla Firefox') {
			window.open('https://www.mozilla.org/en-US/firefox/new/');
		} else {
			alert('Gravy extension is not available for current browser');
		}
	};

	return (
		<>
			<div className="fullBg">
				<Container
					fluid
					style={{ backgroundColor: ' #f5f0e3' }}
					className="pt-xl-4 pt-lg-4 pt-md-4"
				>
					<Navbar bg="" expand="lg">
						<Link to="/">
							<Navbar.Brand>
								<img src={GravyIcon} alt="icon" />
							</Navbar.Brand>
						</Link>
						<Navbar.Toggle aria-controls="responsive-navbar-nav" />
						<Navbar.Collapse id="responsive-navbar-nav">
							<Nav className="mx-auto"></Nav>

							<Nav.Link className="text  mx-2" href="#action2">
								About
							</Nav.Link>
							<Nav.Link className="text  mx-2" href="#action2">
								Help
							</Nav.Link>
							<Nav.Link className="text  mx-2" href="#action2">
								Careers
							</Nav.Link>
							<Nav.Link className="text  mx-2 mr-3" href="#action2">
								News
							</Nav.Link>

							<Button
								className="extensionButton border-0 shadow-none px-4  py-3 mx-4 ml-1"
								onClick={handleClick}
							>
								Install Extension
							</Button>
						</Navbar.Collapse>
					</Navbar>
				</Container>
			</div>
		</>
	);
};

export default HeaderComponent;
