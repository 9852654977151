// import * as ActionTypes from '../../actions/home'
import * as ActionTypes from '../../../actions';

const initialState = {
	isLoading: false,
	sendStatus: false,
	apiErrors: [],
};
export const ResendReducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.SEND_RESEND:
			return { ...state, isLoading: true };

		case ActionTypes.SEND_RESEND_SUCCESS:
			return {
				...state,
				isLoading: false,
				sendStatus: action.apiResponse.data ? action.apiResponse.data : false,
				apiErrors: null,
			};

		case ActionTypes.SEND_RESEND_FAILURE:
			return {
				...state,
				isLoading: false,
				sendStatus: false,
				apiErrors: action.apiResponse.error ? action.apiResponse.errors : null,
			};
		default:
			return state;
	}
};
