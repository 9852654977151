import history from 'utils/history';
import { ManualAuthApiCall } from '../../../middlewares/apiMiddleware';
export const GET_PROFILE_ABOUTYOU = 'GET_PROFILE_ABOUTYOU';
export const GET_PROFILE_ABOUTYOU_SUCCESS = 'GET_PROFILE_ABOUTYOU_SUCCESS';
export const GET_PROFILE_ABOUTYOU_FAILURE = 'GET_PROFILE_ABOUTYOU_FAILURE';

export const fetchProfileAction = (payload) => async (dispatch) => {
	dispatch({
		type: GET_PROFILE_ABOUTYOU,
	});

	const apiRequest = await ManualAuthApiCall(
		payload && payload.id
			? `/auth/fetch/profile?id=${payload.id}`
			: `/auth/fetch/profile`,
		// `/auth/fetch/profile?id=${payload.id}`,
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			// body: JSON.stringify(payload),
		},
		dispatch,
		GET_PROFILE_ABOUTYOU_FAILURE
	);
	if (apiRequest.error === false) {
		dispatch({ type: GET_PROFILE_ABOUTYOU_SUCCESS, apiResponse: apiRequest });
		// history.push('/app/advertiser');
	}
};
