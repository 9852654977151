// import * as ActionTypes from '../../actions/home'
import * as ActionTypes from '../../actions';

const initialState = {
	isDomainVerifying: false,
	data: [],
	apiErrors: [],
};

const DomainOwnerReducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.DOMAIN_OWNER:
			return { ...state, isDomainVerifying: true };

		case ActionTypes.DOMAIN_OWNER_SUCCESS:
			return {
				...state,
				isDomainVerifying: false,
				data: action.apiResponse.data ? action.apiResponse.data : null,
				apiErrors: null,
			};

		case ActionTypes.DOMAIN_OWNER_FAILURE:
			return {
				...state,
				isDomainVerifying: false,
				data: null,
				apiErrors: action.apiResponse.error ? action.apiResponse.errors : null,
			};
		default:
			return state;
	}
};

export default DomainOwnerReducer;
