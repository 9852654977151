import React, { useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import happyGirl from '../../../assets/images/HappyGirl.svg';
import Line from '../../../assets/images/Line.svg';
import PendingSuccess from '../../common/PendingSuccess';
import AuthAdvertiserHeaderComponent from '../../common/Advertiser Header/HeaderComponent';
import FooterComponent from '../../common/Auth/FooterComponent/FooterComponent';
import AdvertiserTableComponent from './AdvertiserTableComponent';
import { DashbaordAction } from '../../../actions';
import { connect } from 'react-redux';

const AdvertiserDashboard = ({
	dashboard: { apiErrors, isLoading, user },
	DashbaordAction,
}) => {
	useEffect(() => {
		DashbaordAction();
	}, []);
	return (
		<>
			<div className="authfullContainer">
				<AuthAdvertiserHeaderComponent user={user && user} />
				<div className="fullBg">
					<Container fluid className="font">
						<Row className="header1 pt-5">
							<Col
								xl={3}
								lg={3}
								md={{ span: 12, offset: 1 }}
								sm={12}
								xs={12}
								className="happy-girl-block"
							>
								<img
									className="img-fluid"
									src={happyGirl}
									alt="happy girl"
									className="happy-girls"
								/>
							</Col>
							<Col xl={8} lg={8} md={12}>
								<Row className="pt-5">
									<Col
										className={`pr-0  ${
											user &&
											user.status &&
											user.status.bioVerified &&
											user.status.emailVerified &&
											user.status.phoneNumberVerified &&
											user.status.domainVerified &&
											user.status.paymentVerified
												? 'col-xl-10 col-lg-10 col-md-10'
												: 'col-xl-5 col-lg-6 col-md-5'
										} col-sm-10 col-12`}
									>
										<h1 className="AdvertiserMaxText ml-xl-5 ml-lg-5 ml-md-5 ml-sm-5 ml-xs-5 ml-5">
											Welcome back
											<b>
												{user && user.status && user.status.bioVerified
													? `, ${user && user.fullName}`
													: ''}
											</b>
										</h1>
										<h4 className="AdvertiserCoinSummary  ml-xl-5 ml-lg-5 ml-md-5 ml-sm-5 ml-xs-5 ml-5">
											Here’s your user summary
										</h4>
									</Col>
									{user &&
									user.status &&
									user.status.bioVerified &&
									user.status.emailVerified &&
									user.status.phoneNumberVerified &&
									user.status.domainVerified &&
									user.status.paymentVerified ? (
										''
									) : (
										<>
											<Col xs={1} className="maxLineWidth line-image">
												<img src={Line} />
											</Col>
											<Col
												xl={6}
												lg={5}
												md={6}
												sm={12}
												xs={12}
												className="px-0"
											>
												<Row className="mt-xl-2 mt-lg-2 mt-md-3 mt-5 mr-0">
													<Col
														xl={2}
														lg={2}
														md={2}
														sm={2}
														xs={2}
														className="px-lg-1"
													>
														<PendingSuccess
															type="pending"
															size="50px"
															className="img-fluid ml-3 mt-xl-0 mt-lg-1 mt-md-0 mt-sm-0 mt-xs-0"
														/>
													</Col>
													<Col
														xl={10}
														lg={10}
														md={10}
														sm={10}
														xs={9}
														className="pr-0"
													>
														<h6 className="mt-xl-2 mt-lg-2 mt-md-0 mt-1 ml-xl-2 ml-lg-2 ml-md-2 ml-2">
															To start seeing new website users verify your
															account. &nbsp;
															<Link
																to="/app/advertiser/profile"
																className="p1 text-decoration-none"
															>
																Get Verified
															</Link>
														</h6>
													</Col>
												</Row>
											</Col>
										</>
									)}
								</Row>
								<Row className="mt-4 mx-xl-5 mx-lg-5 mx-md-5 coins-positions">
									<Col
										xl={12}
										lg={12}
										md={12}
										sm={12}
										xs={12}
										style={{
											backgroundColor: '#FCF9F2',
											justifyContent: 'center',
										}}
										className="py-5 d-flex"
									>
										{/* <div className="pt-5 d-flex imgSuccess"> */}
										<div>
											<PendingSuccess
												type="success"
												width="50px"
												className="img-fluid mx-4 imgSuccess"
											/>
										</div>

										<h1 className="cointext">
											Total Website Users: <b>{user && user.totalCustomer}</b>
										</h1>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</div>
				<Container fluid className="mt-5 font">
					{user && user.isBlocked ? (
						<h1 className="pt-5 px-5" style={{ color: '#FA7269' }}>
							Your account has been suspended by the gravy administration.
							Please contact the gravy team.
						</h1>
					) : (
						<AdvertiserTableComponent domain={user.domain} />
					)}
					{/* <AdvertiserTableComponent domain={user.domain} /> */}
				</Container>
			</div>
			<FooterComponent />
		</>
	);
};

const mapStateToProps = (state) => ({
	dashboard: state.dashboard,
});

export default connect(mapStateToProps, {
	DashbaordAction,
})(withRouter(AdvertiserDashboard));
