import * as ActionTypes from '../../actions';

const initialState = {
	isAboutYouSaving: null,
	updatedUser: [],
	apiErrors: [],
};

export const AdminBonusReducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.ADMIN_BONUS:
			return { ...state, isAboutYouSaving: true };

		case ActionTypes.ADMIN_BONUS_SUCCESS:
			return {
				...state,
				isAboutYouSaving: false,
				updatedUser: action.apiResponse.data ? action.apiResponse.data : null,
				apiErrors: null,
			};

		case ActionTypes.ADMIN_BONUS_FAILURE:
			return {
				...state,
				isAboutYouSaving: false,
				updatedUser: null,
				apiErrors: action.apiResponse.error ? action.apiResponse.errors : null,
			};
		default:
			return state;
	}
};
