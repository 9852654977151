import { ManualAuthApiCall } from '../../middlewares/apiMiddleware';
// import { saveAuthToken } from '../../utils/index';
import history from 'utils/history';

export const ACCOUNT_PASSWORD = 'ACCOUNT_PASSWORD';
export const ACCOUNT_PASSWORD_SUCCESS = 'ACCOUNT_PASSWORD_SUCCESS';
export const ACCOUNT_PASSWORD_FAILURE = 'ACCOUNT_PASSWORD_FAILURE';
// export const LOGOUT = 'LOGOUT';

export const accountPasswordAction = (payload) => async (dispatch) => {
	dispatch({
		type: ACCOUNT_PASSWORD,
	});

	const apiRequest = await ManualAuthApiCall(
		`/auth/reset/password`,
		{
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify(payload),
		},
		dispatch,
		ACCOUNT_PASSWORD_FAILURE,
		history,
		true
	);
	if (apiRequest.error === false) {
		dispatch({ type: ACCOUNT_PASSWORD_SUCCESS, apiResponse: apiRequest });
	}
};

// export const logout = () => ({
// 	type: LOGOUT,
// });
