import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const keepBrowsingComponent = () => {
	return (
		<>
			<Row>
				<Col className="text-center py-5 my-5">
					<div className="py-5 my-5">
						<h1 className="heading2">
							Keep on’ browsing , you’re almost there
						</h1>
						<div>
							<p className="heading3">
								Check back soon to see if you have enough coins to redeem a gift
								card.
							</p>
						</div>
						<Link to="/app/customer/dashboard">
							<a className="btn border-0 font-weight-bold button2 my-2 px-5 pt-2 shadow-none">
								View Dashboard
							</a>
						</Link>
					</div>
				</Col>
			</Row>
		</>
	);
};

export default keepBrowsingComponent;
