import React, { useEffect, useState } from 'react';
import { Row, Col, Dropdown, Button, Form, Container } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { dashboardTableAction } from 'actions/User/DashboardTableAction';
// import { postTableText } from "./../../../Redux/actions/Table/TableAction";

const ColumnFilterText = ({ filter, setFilter, handleTableAction }) => {
	let [search, setSearch] = useState('');

	const handleSubmit = () => {
		filter['filter'] = {
			...filter['filter'],
			searchCompany: search,
		};
		setFilter(filter);
		handleTableAction();
	};

	const handleReset = () => {
		setSearch('');
		filter['filter'] = [];
		setFilter(filter);
		handleTableAction();
	};
	useEffect(() => {
		setSearch(filter && filter['filter'] && filter['filter'].searchCompany);
	}, [filter]);

	// useEffect(() => {
	// 	console.log('filter', filter);
	// }, [filter]);
	// const handleSubmit = () => {
	// 	// e.preventDefault();
	// 	// const param = { cName: search };
	// 	setFilter(search);
	// 	handleFilter(search);

	// 	// dispatch(dashboardTableAction({ cName: search }));
	// };
	// const test = () => {
	// 	handleFilter();
	// };
	// const handleReset = () => {
	// 	setSearch('');
	// };

	// const handleClick = () => {};

	return (
		<>
			<Dropdown>
				<Dropdown.Toggle
					id="dropdown-basic"
					className="dropdown-icon shadow-none rounded-0"
					style={{ paddingTop: '30px', paddingBottom: '30px' }}
					variant="none"
				>
					<FontAwesomeIcon icon={faSearch} />
				</Dropdown.Toggle>

				<Dropdown.Menu align="right" className="menu-items">
					<Container id="checkBox">
						<Row>
							<Col className="py-2">
								<Form onSubmit={handleSubmit}>
									<Form.Group controlId="search">
										<Form.Control
											type="text"
											name="searchCompany"
											placeholder="Search Company"
											value={search}
											onChange={(e) => {
												search = e.target.value;
												setSearch(search);
											}}
											required
										/>
									</Form.Group>
									<Dropdown.Divider />
									<Button
										variant="none"
										className="dropdown-reset-button shadow-none"
										type="reset"
										onClick={handleReset}
									>
										<b>Reset</b>
									</Button>
									<Button
										variant="warning"
										type="submit"
										className="ml-3 shadow-none"
									>
										<b>Search</b>
									</Button>
								</Form>
							</Col>
						</Row>
					</Container>
				</Dropdown.Menu>
			</Dropdown>
		</>
	);
};

// export default connect(null, { postTableText })(ColumnFilterText);
// export default ColumnFilterText;
const mapStateToProps = (state) => ({
	table: state.table,
});

export default connect(mapStateToProps, {
	dashboardTableAction,
})(withRouter(ColumnFilterText));
