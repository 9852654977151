import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Dropdown, Button, Form } from 'react-bootstrap';
import './AdminMemberTableStyle.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

export const ColumnFilterCheckbox = ({
	filter,
	setFilter,
	handleTableAction,
}) => {
	let [company, setCompany] = useState(false);
	let [user, setUser] = useState(false);

	useEffect(() => {
		setCompany(filter.checkFilter.company);
		setUser(filter.checkFilter.user);
	}, [filter]);

	const handleSubmit = (e) => {
		// e.preventDefault();
		filter['checkFilter'] = {
			...filter['checkFilter'],
			company: company,
			user: user,
		};

		setFilter(filter);
		handleTableAction();
		console.log('filter', filter);
	};

	const handleReset = (e) => {
		// e.preventDefault();
		filter['checkFilter'] = {
			...filter['checkFilter'],
			company: false,
			user: false,
		};

		setFilter(filter);
		handleTableAction();
		console.log('filter', filter);
	};

	return (
		<>
			<Dropdown>
				<Dropdown.Toggle
					id="dropdown-basic"
					className="dropdown-icon shadow-none rounded-0"
					style={{ paddingTop: '30px', paddingBottom: '30px' }}
					variant="none"
				>
					<FontAwesomeIcon icon={faFilter} className="img-fluid filter" />
				</Dropdown.Toggle>

				<Dropdown.Menu
					align="right"
					className="checkbox0"
					style={{ transform: 'translate(-116px, 86px)!important' }}
				>
					<Container id="checkBox ">
						<Row>
							<Col>
								<Form onSubmit={handleSubmit}>
									<div className="d-flex py-1">
										<div className="checkbox-wrapper1">
											<input
												id="test1"
												type="checkbox"
												// name={value1}
												// value={value1}
												// onChange={handleChange}
												// value={filter && filter['filter'].fullName}
												// setFilter({ [e.target.value]: e.target.checked });
												name="company"
												value={company}
												onChange={(e) => {
													setCompany(e.target.checked);
												}}
												checked={company}
											/>
											<label htmlFor="test1"></label>
										</div>
										<span className="columnFilterCheckbox">Company</span>
									</div>
									<div className="d-flex py-1">
										<div className="checkbox-wrapper2">
											<input
												id="test2"
												type="checkbox"
												// name={value2}
												// value={value2}
												// onChange={handleChange}
												name="user"
												value={user}
												onChange={(e) => {
													setUser(e.target.checked);
												}}
												checked={user}
											/>
											<label htmlFor="test2"></label>
										</div>
										<span className="columnFilterCheckbox">User</span>
									</div>
									<Dropdown.Divider />
									<Button
										variant="none"
										type="reset"
										className="dropdown-reset-button shadow-none"
										onClick={handleReset}
									>
										<b>Reset</b>
									</Button>
									<Button
										variant="warning"
										type="submit"
										className="ml-2 shadow-none"
									>
										<b>OK</b>
									</Button>
								</Form>
							</Col>
						</Row>
					</Container>
				</Dropdown.Menu>
			</Dropdown>

			{/* <DropdownButton
				menuAlign="right"
				title={<FontAwesomeIcon icon={faFilter} className="img-fluid" />}
				id="dropdown-menu-align-right"
				className="dropdown-icon"
				variant="none"
				onClick={handleClicked}
			>
				<Dropdown.Item id="checkBox">
					<Row>
						<Col>
							<Form>
								<Form.Group controlId="referral">
									<Form.Check type="checkbox" label="Referral" />
								</Form.Group>
								<Form.Group controlId="websiteView">
									<Form.Check type="checkbox" label="Website View" />
								</Form.Group>
								<Form.Group controlId="advertView">
									<Form.Check type="checkbox" label="Advert View" />
								</Form.Group>
								<Dropdown.Divider className="dropdown-divider" />
								<Button
									variant="none"
									className="dropdown-reset-button shadow-none"
								>
									Reset
								</Button>
								<Button
									variant="warning"
									type="submit"
									className="ml-3 shadow-none"
								>
									Ok
								</Button>
							</Form>
						</Col>
					</Row>
				</Dropdown.Item>
			</DropdownButton> */}
		</>
	);
};
