import { ManualAuthApiCall } from '../../middlewares/apiMiddleware';
import { saveAuthToken } from '../../utils/index';
import history from 'utils/history';
export const REFERRAL_INVITATION = 'REFERRAL_INVITATION';
export const REFERRAL_INVITATION_SUCCESS = 'REFERRAL_INVITATION_SUCCESS';
export const REFERRAL_INVITATION_FAILURE = 'REFERRAL_INVITATION_FAILURE';

export const referralInvitationAction = (payload) => async (dispatch) => {
	dispatch({
		type: REFERRAL_INVITATION,
	});

	const apiRequest = await ManualAuthApiCall(
		`/auth/customer/referral/email`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify(payload),
		},
		dispatch,
		REFERRAL_INVITATION_FAILURE,
		history,
		true
	);
	if (apiRequest.error === false) {
		// saveAuthToken(apiRequest.data);
		dispatch({ type: REFERRAL_INVITATION_SUCCESS, apiResponse: apiRequest });
		// history.push('/app/dashboard');
	}
};
