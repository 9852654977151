import React from 'react';
import { useTable } from 'react-table';
import { Table } from 'react-bootstrap';
const TableBaseComponent = (props) => {
	const tableInstance = useTable({
		columns: props.COLUMNS,
		data: props.DATA,
	});

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		tableInstance;

	return (
		<>
			<Table
				borderless
				varient
				responsive
				hover
				size="xl"
				className="table"
				{...getTableProps()}
			>
				<thead size="xl">
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th {...column.getHeaderProps()}>{column.render('Header')}</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map((cell) => {
									return (
										<td {...cell.getCellProps()}>
											{/* <span
												style={{
													borderRight: '2px solid #FDBF00',
													paddingRight: '88px',
													marginRight: '-110px',
												}}
											> */}
											{cell.render('Cell')}
											{/* </span> */}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</Table>
		</>
	);
};

export default TableBaseComponent;
