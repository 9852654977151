import React from 'react';
import { Row } from 'react-bootstrap';
import '../../Advertiser/AdvertiserProfileComponent/profileComponent.css';
import PendingSuccess from '../PendingSuccess';

const GetVerifiedAdvertiser = ({ status }) => {
	return (
		<>
			<Row>
				<div
					className=" col-xl-11  col-lg-11 col-md-12 col-sm-12 col-xs-12 px-xl-4 px-lg-5 px-sm-1 px-xs-1  overlapAdvertiser"
					style={{
						backgroundColor: '#FCF9F2',
						position: 'relative',
						marginTop: '-17%',
					}}
				>
					<div className="mt-5 pt-5 pb-3 px-xl-4 px-lg-4 VerifiedQuery verified1">
						<h1>Get Verified</h1>
						{status &&
						status.bioVerified &&
						status.emailVerified &&
						status.phoneNumberVerified &&
						status.domainVerified &&
						status.paymentVerified ? (
							<p style={{ color: '#50bfa5' }}>Status: Verified</p>
						) : (
							<>
								<p style={{ color: '#F47458' }}>Status: Pending</p>
								<p style={{ color: '#9C9C9C' }}>
									Verified account status allows you to redeem gift cards and
									earn coins. Verify the below to confirm your account.
								</p>
							</>
						)}
					</div>
					<div className="pb-5 px-lg-2 mb-5 pr-sm-1 pr-xs-1 mediumQuery center1">
						<ul className="footerbtn buttonQueryAdvertiser pl-xl-3">
							<li>
								<div
									className={`my-3 py-2 lineSpace  ${
										status && status.bioVerified
											? 'verifiedStatusAdvertiser'
											: 'pendingStatusAdvertiser'
									}`}
								>
									<span className="statusCard">
										{status && status.bioVerified ? (
											<PendingSuccess type="success" width="20px" />
										) : (
											<PendingSuccess type="pending" width="20px" />
										)}
									</span>
									<span className="pl-3 " style={{ color: 'white' }}>
										About You (Status:&nbsp;
										{status && status.bioVerified ? 'Verified' : 'Pending'})
									</span>
								</div>
							</li>
							<li>
								<div
									className={`my-3 py-2 lineSpace  ${
										status && status.emailVerified
											? 'verifiedStatusAdvertiser'
											: 'pendingStatusAdvertiser'
									}`}
								>
									<span className="statusCard">
										{status && status.emailVerified ? (
											<PendingSuccess type="success" width="20px" />
										) : (
											<PendingSuccess type="pending" width="20px" />
										)}
									</span>
									<span className="pl-3" style={{ color: 'white' }}>
										Your Email (Status:&nbsp;
										{status && status.emailVerified ? 'Verified' : 'Pending'})
									</span>
								</div>
							</li>
							<li>
								<div
									className={`my-3 py-2 lineSpace  ${
										status && status.phoneNumberVerified
											? 'verifiedStatusAdvertiser'
											: 'pendingStatusAdvertiser'
									}`}
								>
									<span className="statusCard">
										{status && status.phoneNumberVerified ? (
											<PendingSuccess type="success" width="20px" />
										) : (
											<PendingSuccess type="pending" width="20px" />
										)}
									</span>
									<span className="pl-2" style={{ color: 'white' }}>
										Phone number (Status:&nbsp;
										{status && status.phoneNumberVerified
											? 'Verified'
											: 'Pending'}
										)
									</span>
								</div>
							</li>
							<li>
								<div
									className={`my-3 py-2 lineSpace  ${
										status && status.domainVerified
											? 'verifiedStatusAdvertiser'
											: 'pendingStatusAdvertiser'
									}`}
								>
									<span className="statusCard">
										{status && status.domainVerified ? (
											<PendingSuccess type="success" width="20px" />
										) : (
											<PendingSuccess type="pending" width="20px" />
										)}
									</span>
									<span className="pl-2" style={{ color: 'white' }}>
										Domain Ownership (Status:&nbsp;
										{status && status.domainVerified ? 'Verified' : 'Pending'})
									</span>
								</div>
							</li>
							<li>
								<div
									className={`my-3 py-2 lineSpace  ${
										status && status.paymentVerified
											? 'verifiedStatusAdvertiser'
											: 'pendingStatusAdvertiser'
									}`}
								>
									<span className="statusCard">
										{status && status.paymentVerified ? (
											<PendingSuccess type="success" width="20px" />
										) : (
											<PendingSuccess type="pending" width="20px" />
										)}
									</span>
									<span className="pl-2" style={{ color: 'white' }}>
										Payment Details (Status:&nbsp;
										{status && status.paymentVerified ? 'Verified' : 'Pending'})
									</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</Row>
		</>
	);
};

export default GetVerifiedAdvertiser;
