import React, { useEffect, useState } from 'react';
import { Container, Row, Button, Form } from 'react-bootstrap';
import Tug from '../../../assets/images/Tug.svg';
import { MyTextField } from '../../../Validations/InputField';
import './DeleteAccount.css';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { DeleteAccountAction } from '../../../actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import history from 'utils/history';

const DeleteAccountComponent = (
	{
		deleteAccount: { apiErrors, isDeleteLoading, response },
		DeleteAccountAction,
		handleClose,
	},
	dispatch
) => {
	const [serverError, setServerErrors] = useState({});

	const handleSubmit = (values) => {
		DeleteAccountAction(values);
	};
	useEffect(() => {
		if (!(apiErrors && apiErrors.oldPassword) && isDeleteLoading === false) {
			localStorage.clear();
			history.go(0);
		}
	}, [apiErrors, isDeleteLoading]);
	return (
		<>
			<Container fluid className="containerColour">
				<Row>
					<div className="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 offset-md-2 col-md-8">
						<div class="cardLogout mt-xl-2 my-lg-1 my-md-5">
							<div
								class="card-header py-xl-5 py-lg-5 py-md-4 text-center"
								style={{ backgroundColor: '#DDEDE0' }}
							>
								<h2>Delete Account</h2>
							</div>
							<div
								class="card-body py-xl-5 py-lg-5 text-center"
								style={{
									backgroundColor: '#FCF9F2',
								}}
							>
								<h3>Account Password</h3>
								<p class="card-text pb-2 logoutTest ">
									Enter your account password in order to delete your account.
								</p>
								<div>
									<Formik
										enableReinitialize={true}
										initialValues={{
											oldPassword: '',
										}}
										onSubmit={async (values, { setSubmitting }) => {
											setSubmitting(true);
											handleSubmit(values);
											setSubmitting(false);
										}}
										validationSchema={Yup.object().shape({
											oldPassword: Yup.string().required(
												"Password can't be empty"
											),
										})}
									>
										{(props) => {
											const {
												values,
												touched,
												errors,
												isSubmitting,
												handleChange,
												// handleBlur,
												handleSubmit,
												// setStatus,
											} = props;
											return (
												<Form onSubmit={handleSubmit} noValidate>
													<MyTextField
														placeholder="Password"
														inputType="password"
														fieldName="oldPassword"
														// onChange={(e) => {
														// 	customHandle(handleChange, 'emailAddress', e);
														// }}
														serverError={serverError}
														errors={errors.oldPassword || false}
														inputValue={values.oldPassword}
														fieldTouch={touched.oldPassword}
														setServerErrors={setServerErrors}
														apiErrors={apiErrors}
														handleChange={handleChange}
														style={{ display: 'revert' }}
													/>
													{apiErrors && apiErrors.oldPassword && (
														<>
															<div className="invalid-feedback d-block">
																{apiErrors.oldPassword}
															</div>
															<br />
														</>
													)}

													<Button
														type="submit"
														className="border-0 px-5 logoutButton shadow-none rounded-0"
														disabled={isSubmitting}
														onClick={() => {
															// localStorage.clear();
															// window.location.reload(false);
														}}
													>
														Delete Account
													</Button>
												</Form>
											);
										}}
									</Formik>
								</div>

								<a onClick={handleClose} style={{ cursor: 'pointer' }}>
									<p class="card-text pt-5 pb-3 loggedLink">Stay logged in</p>
								</a>
							</div>
						</div>
					</div>
				</Row>
				<Row>
					<div className="col-xl-12 mt-1">
						<img className="img-fluid tug" src={Tug} alt="tug" />
					</div>
				</Row>
			</Container>
		</>
	);
};

// export default DeleteAccountComponent;

const mapStateToProps = (state) => ({
	deleteAccount: state.deleteAccount,
});

export default connect(mapStateToProps, {
	DeleteAccountAction,
})(withRouter(DeleteAccountComponent));
