import React, { useState } from 'react';
import Vector from '../../assets/images/Vector.svg';
import Vector2 from '../../assets/images/Vector2.svg';
import * as _ from 'lodash';
const PaginationComponent = ({
	filter,
	setFilter,
	handleTableAction,
	totalUser,
}) => {
	const [currentPage, setCurrentPage] = useState(1);
	let [pageSize, setPageSize] = useState(10);

	if (_.isUndefined(totalUser) || _.isEmpty(totalUser) || _.isNull(totalUser)) {
		totalUser = 1;
	}

	const totalPages = Math.ceil(parseInt(totalUser) / parseInt(pageSize));

	// console.log('PAGES', totalUser, totalPages);

	const handleChangeNext = () => {
		if (currentPage < totalPages) {
			setCurrentPage(currentPage + 1);
			filter && filter['pagination'] && filter['pagination']['page']
				? (filter['pagination']['page'] = currentPage + 1)
				: (filter['pagination'] = {
						...filter['pagination'],
						page: currentPage + 1,
				  });

			setFilter(filter);
			handleTableAction();
		}
	};
	const handleChangePrevious = () => {
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1);

			filter && filter['pagination'] && filter['pagination']['page']
				? (filter['pagination']['page'] = currentPage - 1)
				: (filter['pagination'] = {
						...filter['pagination'],
						page: currentPage - 1,
				  });

			setFilter(filter);
			handleTableAction();
		}
	};

	// const handlePageChange = (event, value) => {
	// 	setPage(value);
	// };

	const handlePageSizeChange = (e) => {
		// setPageSize(filter);
		pageSize = parseInt(e.target.value);
		setPageSize(pageSize);
		filter && filter['pagination'] && filter['pagination']['pageSize']
			? (filter['pagination']['pageSize'] = pageSize)
			: (filter['pagination'] = {
					...filter['pagination'],
					pageSize: pageSize,
			  });

		setFilter(filter);

		handleTableAction();
	};

	return (
		<>
			<div className="row mb-5">
				<div className="offset-xl-8  col-xl-4 offset-lg-6 col-lg-5  offset-md-4 col-md-7 col-sm-12 col-xs-12">
					<div className="row">
						<div className="input-group   styleInline col-xl-5 col-lg-6 col-md-6 col-sm-6 col-xs-6">
							<span
								className={
									currentPage === 1
										? 'input-group-addon btn btn-primary paginationAction disabled mx-2'
										: 'input-group-addon btn btn-primary paginationAction mx-2'
								}
								data-pager-action="previous"
								onClick={handleChangePrevious}
							>
								<img src={Vector} alt="pervious arrow" />
							</span>
							<span className="pageCounterPagination mx-2">{currentPage}</span>
							<span
								className={
									currentPage === totalPages
										? 'input-group-addon btn btn-primary paginationAction disabled mx-2'
										: 'input-group-addon btn btn-primary paginationAction mx-2'
								}
								data-pager-action="next"
								onClick={handleChangeNext}
							>
								<img src={Vector2} alt="next arrow" />
							</span>
						</div>
						<div className="input-group mobilePagination col-xl-6 col-lg-6 col-md-6  col-sm-2 col-xs-2">
							<select
								className="form-control paginationAction"
								data-pager-action="pagesize"
								style={{ minWidth: '90%' }}
								value={
									filter && filter.pagination && filter.pagination.pageSize
								}
								onChange={handlePageSizeChange}
							>
								{/* {pageSize &&
									pageSize.map((size) => (
										<option key={size} value={size}>
											{size}/ page
										</option>
									))} */}
								{/* <option value={pageSize}> {1}/ page</option>
								<option value={pageSize}>{2} / page</option>
								<option value={pageSize}>{3} / page</option> */}
								<option value={10}> 10 / page</option>
								<option
									// disabled={currentPage == totalPages ? true : false}
									value={20}
								>
									20 / page
								</option>
								<option
									// disabled={currentPage == totalPages ? true : false}
									value={30}
								>
									30 / page
								</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PaginationComponent;
