import { ManualAuthApiCall } from '../../middlewares/apiMiddleware';
// import { saveAdvertiserToken } from '../../utils/index';
import history from 'utils/history';

export const GET_DASHBOARD = 'GET_DASHBOARD';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAILURE = 'GET_DASHBOARD_FAILURE';

export const DashbaordAction = (payload) => async (dispatch) => {
	dispatch({
		type: GET_DASHBOARD,
	});

	const apiRequest = await ManualAuthApiCall(
		`/auth/fetch/profile`,
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify(payload),
		},
		dispatch,
		GET_DASHBOARD_FAILURE
	);
	if (apiRequest.error === false) {
		dispatch({ type: GET_DASHBOARD_SUCCESS, apiResponse: apiRequest });
		// history.push('/app/advertiser');
	}
};
