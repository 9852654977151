import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import history from 'utils/history';
import { connect } from 'react-redux';
import { Row, Col, Button, Form } from 'react-bootstrap';
import '../Advertiser/AdvertiserProfileComponent/profileComponent.css';
import { MyTextField } from '../../Validations/InputField';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PendingSuccess from './PendingSuccess';
import { fetchProfileAction, CompanyDetailAction } from '../../actions';
import { ClipLoader } from 'react-spinners';
const CompanyDetail = (
	{
		profile: { apiErrors, isLoading, user },
		fetchProfileAction,
		CompanyDetailAction,
		userId,
	},
	dispatch
) => {
	const [serverError, setServerErrors] = useState({});
	const [checked, setChecked] = useState({});

	useEffect(() => {
		fetchProfileAction(userId);
	}, [userId]);

	useEffect(() => {
		setChecked(!user.isBlocked);
	}, [user]);

	return (
		<>
			<div>
				<div className="position-relative ml-3  ml-md-3 ml-sm-4  ml-xm-3  ">
					<Row className=" my-5 ">
						<div className="col-xl-11 col-lg-11  col-md-8  col-sm-7  ">
							<div
								className="d-flex"
								style={{ justifyContent: 'space-between' }}
							>
								<div>
									<h1 className="profileHeading">Company Details</h1>
								</div>
							</div>
						</div>
					</Row>
					<Row>
						<Col xl={10} lg={12} md={12} sm={12} xs={12}>
							<Formik
								enableReinitialize={true}
								initialValues={{
									// fullName: user && user.fullName ? user.fullName : '',
									// postalCode: user && user.postalCode ? user.postalCode : '',
									companyCoins:
										user && user.advertiserPayableCoin
											? user.advertiserPayableCoin
											: '',
									userCoins:
										user && user.customerPayableCoin
											? user.customerPayableCoin
											: '',
									coinEntered: '',
								}}
								onSubmit={async (values, { setSubmitting }) => {
									setSubmitting(true);
									// console.log('VALUES', values);
									dispatch(CompanyDetailAction(values, userId));
									setSubmitting(false);
								}}
								validationSchema={Yup.object().shape({
									companyCoins: Yup.number('Coins must be a number')
										.required("Company coins can't be empty")
										.positive("Coins can't be negative")
										.integer("Coins can't be decimal value"),

									userCoins: Yup.number('Coins must be a number')
										.required("User coins can't be empty")
										.positive("Coins can't be negative")
										.integer("Coins can't be decimal value")
										.lessThan(
											Yup.ref('companyCoins'),
											'User coines must be less than company coins'
										),
								})}
							>
								{(props) => {
									const {
										values,
										touched,
										errors,
										isSubmitting,
										handleChange,
										handleBlur,
										handleSubmit,
										// setStatus,
										setFieldValue,
									} = props;
									return (
										<Form onSubmit={handleSubmit} noValidate>
											<Row>
												<Col xl={6} lg={6} md={6} sm={12} xs={12}>
													<div className="">
														<MyTextField
															placeholder="Company Coins "
															inputType="text"
															fieldName="companyCoins"
															// onChange={(e) => {
															// 	customHandle(handleChange, 'emailAddress', e);
															// }}
															serverError={serverError}
															errors={errors.companyCoins || false}
															inputValue={values.companyCoins}
															fieldTouch={touched.companyCoins}
															setServerErrors={setServerErrors}
															apiErrors={apiErrors}
															handleChange={handleChange}
															onBlur={handleBlur}
														/>
													</div>
												</Col>
												<Col xl={6} lg={6} md={6} sm={12} xs={12}>
													<div className="">
														<MyTextField
															placeholder="User Coins"
															inputType="text"
															fieldName="userCoins"
															// onChange={(e) => {
															// 	customHandle(handleChange, 'password', e);
															// }}
															serverError={serverError}
															errors={errors.userCoins || false}
															inputValue={values.userCoins}
															fieldTouch={touched.userCoins}
															setServerErrors={setServerErrors}
															// apiErrors={apiErrors}
															handleChange={handleChange}
														/>
													</div>
												</Col>
											</Row>
											<div className="py-2 d-flex">
												<div className="checkbox-wrapper">
													<input
														id="test"
														type="checkbox"
														fieldName="coinEntered"
														onChange={(e) => {
															setFieldValue('coinEntered', e.target.checked);
															setChecked(e.target.checked);
														}}
														inputValue={values.coinEntered}
														checked={checked}
													/>
													<label htmlFor="test"></label>
												</div>
												{/* <Form.Group className="">
													<Form.Check
														id="termsCheck"
														type="checkbox"
														name="termsCheck"
														className="customCheck"
														onChange={(e) => {
															setFieldValue('termsCheck', e.target.checked);
														}}
														// disabled={values.termsCheck && true}
														value={values.termsCheck}
														// label="I agree to the terms and conditions"
													></Form.Check>
												</Form.Group> */}
												<span className="termsCondition">
													Coins Entered (For sales team, untick if they need to
													make contact)
												</span>
											</div>
											<Button
												type="submit"
												className="loginButton   my-4 "
												disabled={isLoading}
											>
												{isLoading ? (
													<ClipLoader size={20} color="#FCF9F2" />
												) : (
													`Save`
												)}
											</Button>
										</Form>
									);
								}}
							</Formik>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	profile: state.profile,
});

export default connect(mapStateToProps, {
	fetchProfileAction,
	CompanyDetailAction,
})(withRouter(CompanyDetail));
