import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Form } from 'react-bootstrap';
import '../../Advertiser/AdvertiserProfileComponent/profileComponent.css';
import { MyTextField } from '../../../Validations/InputField';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PendingSuccess from '../PendingSuccess';
import Timer from 'react-compound-timer';
import { SendResendAction } from '../../../actions';
import { useDispatch } from 'react-redux';
const AdminYourEmail = ({
	resendEmail: { apiErrors, isLoading, sendStatus },
	history,
	location,
	user,
	handleResetAction,
}) => {
	const dispatch = useDispatch();
	const [serverError, setServerErrors] = useState({});
	// const [show, setShow] = useState(false);
	// const handleClose = () => setShow(false);

	useEffect(() => {
		// fetchProfileAction();
		// console.log('......email......', sendStatus);
	}, [apiErrors, isLoading, sendStatus]);

	return (
		<>
			<div
				className={
					user && user.status && user.status.emailVerified ? 'line2' : 'line1'
				}
			>
				{/* <div> */}
				<div className="position-relative ml-3  ml-md-5 ml-sm-4  ml-xm-3 ">
					<Row className="mt-5 ">
						<div className="col-xl-11 col-lg-11  col-md-11  col-sm-8   col-sx-7 mt-3 ">
							<div
								className="d-flex"
								style={{ justifyContent: 'space-between' }}
							>
								<div style={{ width: '87%' }}>
									<h1 className="profileHeading">Email Address</h1>
								</div>
								<div className="mb-3">
									{user && user.status && user.status.emailVerified ? (
										<PendingSuccess className="" type="success" width="50px" />
									) : (
										<PendingSuccess className="" type="pending" width="50px" />
									)}
								</div>
							</div>
						</div>
					</Row>

					<Row>
						<Col xl={11} lg={11} md={12} sm={12} xs={12}>
							{/* <ProfileComponentEmailForm
											user={{
												email: '',
											}}
										/> */}
							<Formik
								enableReinitialize={true}
								initialValues={{
									emailAddress:
										user && user.emailAddress ? user.emailAddress : '',
								}}
								onSubmit={async (values, { setSubmitting }) => {
									setSubmitting(true);
									// dispatch(loginUserAction(values));
									setSubmitting(false);
								}}
								validationSchema={Yup.object().shape({
									emailAddress: Yup.string()
										.email('Email must be valid')
										.required("Email can't be empty"),
								})}
							>
								{(props) => {
									const {
										values,
										touched,
										errors,
										isSubmitting,
										handleChange,
										// handleBlur,
										handleSubmit,
										// setStatus,
									} = props;
									return (
										<Form onSubmit={handleSubmit} noValidate>
											<Row>
												<Col
												//  xl={6} lg={12} md={12} sm={12} xs={12}
												>
													<div className="">
														<MyTextField
															placeholder="Email"
															inputType="text"
															fieldName="emailAddress"
															// onChange={(e) => {
															// 	customHandle(handleChange, 'emailAddress', e);
															// }}
															serverError={serverError}
															errors={errors.emailAddress || false}
															inputValue={values.emailAddress}
															fieldTouch={touched.emailAddress}
															setServerErrors={setServerErrors}
															apiErrors={apiErrors}
															handleChange={handleChange}
															disabled={true}
															style={{ backgroundColor: 'white' }}
														/>
													</div>
												</Col>
											</Row>

											{/* <PuffLoader size={20} color="#FDBF00" /> */}
											{/* <Button
												type="submit"
												className="loginButton   my-4 "
												disabled={isSubmitting}
											>
												Save
												
											</Button> */}
										</Form>
									);
								}}
							</Formik>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};
const mapStateToProps = (state) => ({
	resendEmail: state.resend,
});

export default connect(mapStateToProps, {
	SendResendAction,
})(withRouter(AdminYourEmail));
