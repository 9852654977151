import * as ActionTypes from '../../actions';

const initialState = {
	isDeleteLoading: null,
	response: [],
	apiErrors: [],
};

const DeleteAccountReducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.DELETE_ACCOUNT:
			return { ...state, isDeleteLoading: true };

		case ActionTypes.DELETE_ACCOUNT_SUCCESS:
			return {
				...state,
				isDeleteLoading: false,
				response: action.apiResponse.data ? action.apiResponse.data : null,
				apiErrors: null,
			};

		case ActionTypes.DELETE_ACCOUNT_FAILURE:
			return {
				...state,
				isDeleteLoading: false,
				response: null,
				apiErrors: action.apiResponse.error ? action.apiResponse.errors : null,
			};
		default:
			return state;
	}
};

export default DeleteAccountReducer;
