import {
	ManualApiCall,
	ManualAuthApiCall,
} from '../../middlewares/apiMiddleware';
import * as _ from 'lodash';
import { toQueryString } from '../../utils/index';
import history from 'utils/history';
export const DASHBOARD_TABLE = 'DASHBOARD_TABLE';
export const DASHBOARD_TABLE_SUCCESS = 'DASHBOARD_TABLE_SUCCESS';
export const DASHBOARD_TABLE_FAILURE = 'DASHBOARD_TABLE_FAILURE';
// export const LOGOUT = 'LOGOUT';

export const dashboardTableAction = (filter, userId) => async (dispatch) => {
	// const queryString = toQueryString(filter);
	// console.log('filter', filter);
	// console.log('userId', id);
	dispatch({
		type: DASHBOARD_TABLE,
	});

	const apiRequest = await ManualAuthApiCall(
		`${
			!filter
				? '/customer/dashboard'
				: `/customer/dashboard?${toQueryString(filter)}${
						!_.isEmpty(userId) ? `&id=${userId && userId.id}` : ''
				  }`
		}`,
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			// body: JSON.stringify(filter),
		},
		dispatch,
		DASHBOARD_TABLE_FAILURE,
		history
	);
	// console.log('apiRequest for first action', apiRequest);
	if (apiRequest.error === false) {
		dispatch({ type: DASHBOARD_TABLE_SUCCESS, apiResponse: apiRequest });
		// history.push('/app/dashboard');
	}
};
