// import * as ActionTypes from '../../actions/home'
import * as ActionTypes from '../../actions/';

const initialState = {
	isLoading: false,
	user: [],
	apiErrors: [],
};

export const EMAIL_VERIFICATION = 'EMAIL_VERIFICATION';
export const EMAIL_VERIFICATION_SUCCESS = 'EMAIL_VERIFICATION_SUCCESS';
export const EMAIL_VERIFICATION_FAILURE = 'EMAIL_VERIFICATION_FAILURE';

const EmailVerificationReducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.EMAIL_VERIFICATION:
			return { ...state, isLoading: true };

		case ActionTypes.EMAIL_VERIFICATION_SUCCESS:
			return {
				...state,
				isLoading: false,
				user: action.apiResponse.data ? action.apiResponse.data : null,
				apiErrors: null,
			};

		case ActionTypes.EMAIL_VERIFICATION_FAILURE:
			return {
				...state,
				isLoading: false,
				user: null,
				apiErrors: action.apiResponse.error ? action.apiResponse.errors : null,
			};
		default:
			return state;
	}
};

export default EmailVerificationReducer;
