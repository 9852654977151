// import React from 'react';
import React, { useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Container, Button } from 'react-bootstrap';
import happyGirl from '../../../../assets/images/HappyGirl.svg';
import Line from '../../../../assets/images/Line.svg';
// import Time2 from '../../../../assets/images/Time2.png';
// import Check from '../../../../assets/images/Check.png';
import Phone from '../../../../assets/images/Phone.svg';
import PendingSuccess from '../../../common/PendingSuccess';
import HeaderComponent from '../../../common/Auth/HeaderComponent/HeaderComponent';
import FooterComponent from '../../../common/Auth/FooterComponent/FooterComponent';
import TableComponent from './TableComponent';
import history from 'utils/history';
import { DashbaordAction } from '../../../../actions';
import { connect } from 'react-redux';

const Dashboard = ({
	dashboard: { apiErrors, isLoading, user },
	DashbaordAction,
}) => {
	useEffect(() => {
		DashbaordAction();
	}, []);
	console.log('user table', user);

	return (
		<>
			<div className="authfullContainer">
				<HeaderComponent user={user && user} />
				<div className="fullBg">
					<Container fluid className="font">
						<Row className="header1 pt-5">
							<Col
								xl={3}
								lg={3}
								md={{ span: 3, offset: 1 }}
								sm={12}
								xs={12}
								className="happy-girl-block"
							>
								<img
									className="img-fluid"
									src={happyGirl}
									alt="happy girl"
									className="happy-girl"
								/>
							</Col>
							<Col xl={8}>
								<Row className="pt-5">
									<Col
										className={`pr-0  ${
											user &&
											user.status &&
											user.status.bioVerified &&
											user.status.emailVerified &&
											user.status.phoneNumberVerified
												? 'col-xl-10 col-lg-10 col-md-10'
												: 'col-xl-5 col-lg-5 col-md-5'
										} col-sm-10 col-12`}
									>
										<h1 className="MaxText ml-xl-5 ml-lg-5 md-md-4  pl-xl-2 pl-lg-2 pl-md-2">
											Welcome back
											<b>
												{user && user.status && user.status.bioVerified
													? `, ${user && user.fullName}`
													: ''}
											</b>
										</h1>
										<h4 className="CoinSummary  ml-xl-5 ml-lg-5 ml-md-1 pl-xl-2 pl-lg-2 pl-md-2">
											Here’s your coin summary
										</h4>
									</Col>
									{user &&
									user.status &&
									user.status.bioVerified &&
									user.status.emailVerified &&
									user.status.phoneNumberVerified ? (
										''
									) : (
										<>
											<Col xs={1} className="maxLineWidth line-image">
												<img src={Line} alt="line" />
											</Col>
											<Col
												xl={6}
												lg={5}
												md={6}
												sm={12}
												xs={12}
												className="px-0"
											>
												<Row className="mt-xl-2 mt-lg-2 mt-md-3 mt-5 mr-0">
													<Col xl={2} lg={2} md={2} sm={2} xs={2}>
														<PendingSuccess
															type="pending"
															size="50px"
															className="img-fluid ml-4"
														/>
													</Col>
													<Col
														xl={10}
														lg={10}
														md={10}
														sm={10}
														xs={9}
														className="pr-0"
													>
														<h6 className="mt-xl-2 mt-lg-2 mt-md-0 mt-1 ml-xl-2 ml-lg-2 ml-md-2 ml-2">
															To start collecting coins and to redeem gift cards
															verify your account. &nbsp;
															<Link
																to="/app/customer/profile"
																className="p1 text-decoration-none"
															>
																Get Verified
															</Link>
														</h6>
													</Col>
												</Row>
											</Col>
										</>
									)}
								</Row>
								<Row className="mt-4 mx-xl-5 mx-lg-5 mx-md-5 coins-position">
									<Col
										xl={7}
										lg={6}
										md={12}
										sm={12}
										xs={12}
										style={{ backgroundColor: '#FCF9F2', padding: '0px ' }}
									>
										{/* <img src={Check} className="img-fluid pr-5 " /> */}
										<div className="pt-5 d-flex imgSuccess">
											<PendingSuccess
												type="pending"
												size="50px"
												className="img-fluid mx-4"
											/>

											<h1 className="cointext">
												Coins pending: <b>{user && user.totalPendingCoin}</b>
											</h1>
										</div>
									</Col>

									{/* <Col xl={1} style={{ backgroundColor: "#FCF9F2" }} className="py-5">
              <img src={Time2} className="img-fluid" />
            </Col> */}
									<Col
										xl={5}
										lg={6}
										md={12}
										sm={12}
										xs={12}
										style={{ backgroundColor: '#FCF9F2' }}
										className="py-5 d-flex"
									>
										{/* <img src={Time2} className="img-fluid pr-5" /> */}
										<PendingSuccess
											type="success"
											width="50px"
											className="img-fluid mr-4 ml-lg-5 ml-xl-0 imgPending"
										/>
										<h1 className="cointext">
											Total coins: <b>{user && user.totalVerifiedCoin}</b>
										</h1>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</div>
				<Container fluid className="font">
					<Container fluid className="mt-5">
						<TableComponent user={user && user} />
					</Container>

					<Row className=" px-xl-5 px-lg-5 px-md-5 mx-xl-3">
						<Col
							xl={{ span: 12 }}
							lg={{ span: 12 }}
							md={{ span: 12 }}
							sm={{ span: 12 }}
							xs={{ span: 12 }}
							className="header1 px-5  "
							// style={{ height: '465px' }}
						>
							<Row>
								<Col
									xl={{ span: 5, offset: 1 }}
									lg={{ span: 5, offset: 1 }}
									md={{ span: 6 }}
									sm={{ span: 11 }}
									xs={{ span: 11 }}
								>
									<Row>
										<Col
											xl={{ span: 8 }}
											lg={{ span: 12 }}
											md={{ span: 12 }}
											sm={{ span: 12 }}
											xs={{ span: 12 }}
											className="pt-xl-0 pt-lg-0 pt-md-0 pt-5"
										>
											<p className="  pt-0  mt-xl-5 mt-lg-5 mt-md-5 mt-3 p1 InviteFriend customPadding">
												500 coins for you and a friend
											</p>
											<h3 className="Referal">
												<b>Earn coins faster with Gravy’s referral programme</b>
											</h3>
											<p className=" pt-2 text-muted">
												Share the love and receive a £10 gift card for you and a
												friend.
											</p>
											<Button
												onClick={() => {
													history.push('/app/customer/referFriend');
												}}
												className=" border-0 shadow-none button mt-3 mb-5"
											>
												<b>Invite A Friend</b>
											</Button>
										</Col>
									</Row>
								</Col>
								<Col
									xl={{ span: 6 }}
									lg={{ span: 6 }}
									md={{ span: 6 }}
									sm={{ span: 12 }}
									xs={{ span: 12 }}
									className="pt-0"
								>
									<img
										className="img-fluid customimage dashboardImage"
										src={Phone}
										alt="phone"
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
			<FooterComponent />
		</>
	);
};
const mapStateToProps = (state) => ({
	dashboard: state.dashboard,
});

export default connect(mapStateToProps, {
	DashbaordAction,
})(withRouter(Dashboard));
