import {
	ManualApiCall,
	ManualAuthApiCall,
} from '../../middlewares/apiMiddleware';
import { toQueryString } from '../../utils/index';
import history from 'utils/history';
export const MEMBER_View_TABLE = 'MEMBER_View_TABLE';
export const MEMBER_View_TABLE_SUCCESS = 'MEMBER_View_TABLE_SUCCESS';
export const MEMBER_View_TABLE_FAILURE = 'MEMBER_View_TABLE_FAILURE';
// export const LOGOUT = 'LOGOUT';

export const MemberViewTableAction = (filter) => async (dispatch) => {
	const queryString = toQueryString(filter);

	dispatch({
		type: MEMBER_View_TABLE,
	});

	const apiRequest = await ManualAuthApiCall(
		`/auth/fetch/customer/table?${queryString}`,
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			// body: JSON.stringify(filter),
		},
		dispatch,
		MEMBER_View_TABLE_FAILURE,
		history
	);
	console.log('apiRequest for first action', apiRequest);
	if (apiRequest.error === false) {
		dispatch({ type: MEMBER_View_TABLE_SUCCESS, apiResponse: apiRequest });
		// history.push('/app/dashboard');
	}
};
