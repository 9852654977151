import React, { useState, useEffect } from 'react';
import { Button, Modal, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import crossIcon from '../../../../assets/images/crossIcon.svg';
import {
	ModalPaginationComponent,
	TableBaseComponent,
} from 'components/common';
import './TableStyle.css';
import { connect } from 'react-redux';
import { dashboardModalTableAction } from '../../../../actions';

const ModalTableComponent = (
	{
		userModalTable: { apiErrors, isLoading, tableData },
		dashboardModalTableAction,
		show,
		setShow,
		site,
	},
	user
) => {
	const handleClose = () => setShow(false);
	const [filter, setFilter] = useState({
		filter: [],
		sorting: [],
		pagination: [],
	});
	useEffect(() => {
		console.log('Site', site);
		dashboardModalTableAction(site);
	}, []);
	const handleModalSort = () => {
		dashboardModalTableAction(site, filter);
	};
	const totalRecord = tableData && tableData[0] && tableData[0].totalRecord;

	tableData &&
		tableData.map((item) => {
			item.visitedAt = new Date(item.visitedAt).toLocaleString();
		});

	const COLUMNS_MODAL = [
		{
			Header: () => {
				return (
					<>
						<div className="header-wrapper">
							<span>URL</span>
						</div>
					</>
				);
			},
			accessor: 'pageUrl',
		},
		{
			Header: () => {
				return (
					<>
						<div className="header-wrapper">
							<span style={{ paddingTop: '10px', paddingBottom: '10px' }}>
								Browser
							</span>
						</div>
					</>
				);
			},
			accessor: 'browser',
		},

		{
			Header: () => (
				<div
					className="header-wrapper"
					style={{
						cursor: 'pointer',
						marginRight: '-4em',
						paddingTop: '30px',
						paddingBottom: '30px',
					}}
					onClick={() => {
						filter['sorting'] &&
						filter['sorting']['visitedAt'] &&
						filter['sorting']['visitedAt'] == 'asc'
							? (filter['sorting']['visitedAt'] = 'desc')
							: (filter['sorting'] = {
									...filter['sorting'],
									visitedAt: 'asc',
							  });
						console.log({ filter });
						setFilter(filter);
						handleModalSort();
					}}
				>
					<span>
						Visited At &ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
					</span>
					<span className="tableSortIcon">
						{/* {filter['sorting'] &&
						filter['sorting']['time'] &&
						filter['sorting']['time'] == 'asc' ? (
							<>
								<FontAwesomeIcon icon={faCaretDown} />
								{console.log(filter['sorting']['time'])}
							</>
						) : (
							<>
								<FontAwesomeIcon icon={faCaretUp} />
								{console.log(filter['sorting']['time'])}
							</>
						)} */}
						{filter['sorting'] &&
						filter['sorting']['visitedAt'] &&
						filter['sorting']['visitedAt'] == 'asc' ? (
							<FontAwesomeIcon icon={faCaretDown} />
						) : (
							<FontAwesomeIcon icon={faCaretUp} />
						)}
					</span>
				</div>
			),
			accessor: 'visitedAt',
		},
	];

	return (
		<>
			<Modal
				show={show}
				onHide={() => setShow(false)}
				dialogClassName="customModal"
				aria-labelledby="example-custom-modal-styling-title"
			>
				<Modal.Header className="colorModal">
					<Button
						className="closeButton border-0 shadow-none rounded-0"
						onClick={handleClose}
					>
						<img className="m-xl-3 m-lg-3 m-md-3 m-1" src={crossIcon} />
					</Button>
					<Modal.Title className="colorModal"></Modal.Title>
				</Modal.Header>
				<Modal.Body className="colorModal px-xl-5 px-lg-5 py-5">
					<div className="colorModal px-xl-5 px-lg-5 px-md-3">
						<Container fluid>
							<h1 className="colorModal  m-b-3">Details</h1>
							<TableBaseComponent
								COLUMNS={COLUMNS_MODAL}
								DATA={tableData && tableData}
							/>
							<ModalPaginationComponent
								filter={filter}
								setFilter={setFilter}
								handleModalSort={handleModalSort}
								totalRecord={totalRecord}
							/>
						</Container>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		userModalTable: state.userModalTable,
	};
};

export default connect(mapStateToProps, {
	dashboardModalTableAction,
})(ModalTableComponent);
