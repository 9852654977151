import { ManualApiCall } from '../../middlewares/apiMiddleware';
import { saveAuthToken } from '../../utils/index';
import history from 'utils/history';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGOUT = 'LOGOUT';

export const loginUserAction = (payload) => async (dispatch) => {
	dispatch({
		type: LOGIN_USER,
	});

	const apiRequest = await ManualApiCall(
		`/auth/customer/login`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify(payload),
		},
		dispatch,
		LOGIN_USER_FAILURE,
		history
	);
	if (apiRequest.error === false) {
		saveAuthToken(apiRequest.data);
		dispatch({ type: LOGIN_USER_SUCCESS, apiResponse: apiRequest });
		if (apiRequest.data.user.userRole === 'CUSTOMER') {
			history.push('/app/customer/dashboard');
		} else if (apiRequest.data.user.userRole === 'ADVERTISER') {
			history.push('/app/advertiser/dashboard');
		} else {
			history.push('/app/admin/member');
		}
	}
};

export const logout = () => ({
	type: LOGOUT,
});
