import { React, useState, useEffect } from 'react';
import { Row, Container, Button, Form } from 'react-bootstrap';
import { MyTextField } from '../../../Validations/InputField';
import * as Yup from 'yup';
import '../../../Validations/formik.css';
import { Formik } from 'formik';
// import * as _ from 'lodash';
// import { PuffLoader } from 'react-spinners';
import { connect } from 'react-redux';
// import TableComponent from './TableComponent';
import { withRouter } from 'react-router-dom';
import { AuthHeaderComponent, AuthFooterComponent } from 'components/common';
import Refer from '../../../assets/images/Refer.svg';
import Facebook from '../../../assets/images/Facebook.svg';
import Twitter from '../../../assets/images/Twitter.svg';
import Gmail from '../../../assets/images/Gmail.svg';
import { fetchProfileAction, referralInvitationAction } from '../../../actions';
import { DASHBOARD_HOST } from 'config';
import {
	FacebookShareButton,
	TwitterShareButton,
	EmailShareButton,
} from 'react-share';

import './ReferFriend.css';
import { ClipLoader } from 'react-spinners';
import { SnackBar } from '../../common';
// import TableComponent from '../UserDashboardComponent/Dashboard/TableComponent';

const ReferFriend = ({
	profile: { isLoading, user },
	referral: { isLoadingInvitation, apiErrors, referral },
	fetchProfileAction,
	referralInvitationAction,
}) => {
	const [serverError, setServerErrors] = useState({});

	useEffect(() => {
		fetchProfileAction();
	}, []);

	const link = `${DASHBOARD_HOST}/register/${user && user.referralCode}`;

	useEffect(() => {
		setServerErrors(apiErrors);
	}, [apiErrors]);

	return (
		<>
			<div className="authfullContainer">
				<AuthHeaderComponent user={user && user} />
				<div>
					<div className="fullBg">
						<Container fluid>
							<Row style={{ backgroundColor: '#F4F0E3' }}>
								<div className="col-xl-6 col-lg-6 col-md-6 mt-xl-5 pt-xl-5 mt-lg-5 pt-lg-5 mt-md-5 pt-md-4 mt-sm-5 pt-sm-4 pt-5">
									<img src={Refer} alt="refer" className="img-fluid" />
								</div>
								<div className="my-3 col-xl-4  col-lg-5  col-md-6 mx-xl-5 mx-lg-4 mt-xl-5 pt-xl-5 mt-lg-5 pt-lg-5 mt-md-2 pt-md-2 pt-5">
									<h1>Earn a $20 gift card for you and a friend</h1>
									<p className="pt-xl-0 pt-lg-0 pt-md-0 pt-3  pb-xl-0 pb-lg-0 pb-md-0 pb-5">
										Earn 100 coins for each friend you invite, and we’ll also
										give 100 coins to your friend for joining.
									</p>
								</div>
							</Row>
						</Container>
					</div>
					<Container fluid>
						<div className="referFriend">
							<Row>
								<div className="offset-xl-1 col-xl-5 offset-lg-1 col-lg-5  col-md-6   col-sm-11  col-xs-11">
									<div className="card cardCol border-0">
										<div
											className="card-header border-0 py-5 text-center"
											style={{ backgroundColor: '#DDEDE0', height: '8rem' }}
										>
											<h2>Invite by Email</h2>
										</div>
										<div
											className="card-body py-5 px-xl-5 px-lg-5 px-md-5 px-3"
											style={{ backgroundColor: '#FCF9F2', height: '20rem' }}
										>
											<Formik
												initialValues={{ emailAddress: '' }}
												onSubmit={async (values, { setSubmitting }) => {
													// setSubmitting(true);
													// dispatch();
													referralInvitationAction(values);
													// setSubmitting(false);
												}}
												validationSchema={Yup.object().shape({
													emailAddress: Yup.string()
														.email('Email must be valid')
														.required("Email can't be empty"),
												})}
											>
												{(props) => {
													const {
														values,
														touched,
														errors,
														isSubmitting,
														handleChange,
														handleBlur,
														handleSubmit,
														// setStatus,
													} = props;
													return (
														<Form onSubmit={handleSubmit} noValidate>
															<div className="">
																<MyTextField
																	placeholder="Email Address"
																	inputType="text"
																	fieldName="emailAddress"
																	// onChange={(e) => {
																	// 	customHandle(handleChange, 'emailAddress', e);
																	// }}
																	serverError={serverError}
																	errors={errors.emailAddress || false}
																	inputValue={values.emailAddress}
																	fieldTouch={touched.emailAddress}
																	setServerErrors={setServerErrors}
																	apiErrors={apiErrors}
																	handleChange={handleChange}
																	onBlur={handleBlur}
																/>
															</div>
															<Button
																type="submit"
																className="border-0 font-weight-bold customButton shadow-none"
																disabled={isLoadingInvitation}
															>
																{isLoadingInvitation ? (
																	<ClipLoader size={20} color="#FCF9F2" />
																) : (
																	`Invite `
																)}
															</Button>
														</Form>
													);
												}}
											</Formik>
											{/* <ReferFriendEmailForm user={{ inviteByEmail: "" }} /> */}
										</div>
									</div>
								</div>

								<div className="col-xl-5 col-lg-5  col-md-6  col-sm-11  col-xs-11">
									<div className="card cardCol border-0">
										<div
											className="card-header border-0 py-5 text-center"
											style={{ backgroundColor: '#DDEDE0', height: '8rem' }}
										>
											<h2>Share your link</h2>
										</div>
										<div
											className="card-body py-5 px-xl-5 px-lg-5 px-md-5 px-3 "
											style={{ backgroundColor: '#FCF9F2', height: '20rem' }}
										>
											<Form className="card-text ">
												<Form.Group className="mb-3 email text-center">
													<Form.Control
														id="link"
														name="url"
														className="form3"
														value={link}
														type="text"
														//placeholder="www.joingravy.com/friend/10297873"
														// onChange={handleChange}
														disabled
														style={{ backgroundColor: 'white' }}
													/>
												</Form.Group>
												<Button
													className="border-0 font-weight-bold customButton shadow-none"
													onClick={() => {
														navigator.clipboard.writeText(link);
														SnackBar(
															'success',
															'Referral Link is copied to your clipboard'
														);
													}}
												>
													Copy
												</Button>

												<Row>
													<div className="my-4 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
														<FacebookShareButton url={link}>
															<img
																className="mx-1"
																src={Facebook}
																alt="facebook"
																roundedCircle
															/>
														</FacebookShareButton>

														<TwitterShareButton url={link}>
															<img
																className="mx-1"
																src={Twitter}
																alt="twitter"
																roundedCircle
															/>
														</TwitterShareButton>

														<EmailShareButton url={link}>
															<img
																className="mx-1"
																src={Gmail}
																alt="gmail"
																roundedCircle
															/>
														</EmailShareButton>
													</div>
												</Row>
											</Form>
										</div>
									</div>
								</div>
							</Row>
						</div>
					</Container>
					<Container fluid>{/* <TableComponent /> */}</Container>
				</div>
			</div>
			<AuthFooterComponent />
		</>
	);
};

const mapStateToProps = (state) => ({
	profile: state.profile,
	referral: state.referral,
});

export default connect(mapStateToProps, {
	fetchProfileAction,
	referralInvitationAction,
})(withRouter(ReferFriend));
