import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Container, Button, Form } from 'react-bootstrap';
import Tug from '../../../assets/images/Tug.svg';
import PublicHeaderComponent from '../../common/PublicHeaderFooter/HeaderComponent';
import PublicFooterComponent from '../../common/PublicHeaderFooter/FooterComponent';
import { MyTextField } from '../../../Validations/InputField';
import { emailEnterAction } from '../../../actions';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ClipLoader } from 'react-spinners';
import './PasswordResetStyles.css';

const EmailEnter = (
	{
		sendEmail: { apiErrors, isLoading, response },
		emailEnterAction,
		history,
		location,
	},
	dispatch
) => {
	const [serverError, setServerErrors] = useState({});

	useEffect(() => {
		setServerErrors(apiErrors);
	}, [apiErrors]);

	useEffect(() => {}, [isLoading, apiErrors, response, location.pathname]);

	return (
		<>
			<div className="fullContainer">
				<PublicHeaderComponent />

				<div className="fullBg">
					<Container fluid>
						<Row className="header1 pt-5">
							<Col className="mt-5 ml-5">
								<h1 className="emailVerificationFont">Reset your password</h1>
							</Col>
						</Row>

						<Row className="header1">
							<Col>
								<img className="img-fluid float-right" src={Tug} alt="tug" />
							</Col>
						</Row>
					</Container>
				</div>

				<Container fluid>
					<Row className="emailPasswordTop">
						<Col
							className="mt-xl-0 mt-lg-0 mt-md-5 mt-5 p-xl-5 p-lg-5 p-md-5 p-5 col-xl-6 col-lg-6 col-md-8 offset-xl-3 offset-lg-3 offset-md-2"
							style={{ backgroundColor: '#FCF9F2', maxWidth: '50rem' }}
						>
							<div className="mx-xl-5 mx-lg-4">
								<h1>Account Email Address</h1>
								<p className="mb-5" style={{ color: '#9C9C9C' }}>
									Enter your email address in order to reset your password.
								</p>

								<Formik
									initialValues={{
										emailAddress: '',
									}}
									onSubmit={async (values, { setSubmitting }) => {
										setSubmitting(true);

										// alert(JSON.stringify(values));

										dispatch(emailEnterAction(values));
										setSubmitting(false);
									}}
									validationSchema={Yup.object().shape({
										emailAddress: Yup.string()
											.email('Email must be valid')
											.required("Email can't be empty"),
									})}
								>
									{(props) => {
										const {
											values,
											touched,
											errors,
											// isSubmitting,
											handleChange,
											// handleBlur,
											handleSubmit,
											// setStatus,
										} = props;
										return (
											<Form onSubmit={handleSubmit} noValidate>
												<MyTextField
													placeholder="Email Address"
													inputType="emailAddress"
													fieldName="emailAddress"
													// onChange={(e) => {
													// 	customHandle(handleChange, 'emailAddress', e);
													// }}
													serverError={serverError}
													errors={errors.emailAddress || false}
													inputValue={values.emailAddress}
													fieldTouch={touched.emailAddress}
													setServerErrors={setServerErrors}
													apiErrors={apiErrors}
													handleChange={handleChange}
												/>

												<Button
													type="submit"
													className="loginButton mb-4 mt-5"
													disabled={isLoading}
												>
													{isLoading ? (
														<ClipLoader size={20} color="#FCF9F2" />
													) : (
														'Send Email'
													)}
												</Button>
											</Form>
										);
									}}
								</Formik>
							</div>
						</Col>
					</Row>
				</Container>
				<div style={{ paddingTop: '7rem' }}>
					<PublicFooterComponent />
				</div>
			</div>
		</>
	);
};

// export default EmailEnter;

const mapStateToProps = (state) => ({
	sendEmail: state.sendEmail,
});

export default connect(mapStateToProps, { emailEnterAction })(
	withRouter(EmailEnter)
);
