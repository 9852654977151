import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import './SignIn.css';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import '../../../Validations/formik.css';
import { ClipLoader } from 'react-spinners';
import { loginUserAction } from '../../../actions';
import Group from '../../../assets/images/Group.svg';
import { MyTextField } from '../../../Validations/InputField';
import { HeaderComponent, FooterComponent } from '../../../components/common';

// import debounce from 'lodash/debounce';
// import FooterComponent from '../../../components/common';
// import { withFormik } from 'formik';
// import { SignInValidation } from '../../../Validations/SignInValidation';
// import * as _ from 'lodash';

const SignInComponent = (
	{ auth: { apiErrors, isLoading, user }, loginUserAction, history, location },
	dispatch
) => {
	// const [showError, setShowError] = useState(true);
	const [serverError, setServerErrors] = useState({});

	useEffect(() => {
		setServerErrors(apiErrors);
	}, [apiErrors]);

	useEffect(() => {}, [isLoading, apiErrors, user, location.pathname]);

	const handleButton = () => {
		history.push('/register');
	};

	return (
		<>
			<div className="fullBg fullContainer">
				<div className=" pt-1">
					<HeaderComponent />
				</div>
				<div>
					<Container fluid style={{ backgroundColor: '#f5f0e3' }}>
						<Row
							className=" pt-5  pb-5 centerAuthContent"
							style={{ backgroundColor: '#f5f0e3' }}
						>
							<div
								className="customFormStyle py-5 my-5 offset-xl-2 col-xl-4  offset-lg-1 col-lg-5  offset-md-1 col-md-5 offset-sm-2 col-sm-8 offset-xs-1 col-xs-11"
								style={{ backgroundColor: 'white' }}
							>
								<div className="p-xl-4 mx-xl-4 mx-lg-4 mx-md-0 mx-0 mb-5">
									<h1 className="mt-5 mb-5 formText">Sign In</h1>

									<Formik
										initialValues={{ emailAddress: '', password: '' }}
										onSubmit={async (values, { setSubmitting }) => {
											setSubmitting(true);
											dispatch(loginUserAction(values));
											setSubmitting(false);
										}}
										validationSchema={Yup.object().shape({
											emailAddress: Yup.string()
												.email('Email must be valid')
												.required("Email can't be empty"),
											password: Yup.string().required(
												"Password can't be empty"
											),
										})}
									>
										{(props) => {
											const {
												values,
												touched,
												errors,
												// isSubmitting,
												handleChange,
												handleSubmit,
											} = props;
											return (
												<form onSubmit={handleSubmit} noValidate>
													<div className="py-2">
														<MyTextField
															placeholder="Email"
															inputType="text"
															fieldName="emailAddress"
															// onChange={(e) => {
															// 	customHandle(handleChange, 'emailAddress', e);
															// }}
															serverError={serverError}
															errors={errors.emailAddress || false}
															inputValue={values.emailAddress}
															fieldTouch={touched.emailAddress}
															setServerErrors={setServerErrors}
															apiErrors={apiErrors}
															handleChange={handleChange}
														/>
													</div>
													<div className="py-2">
														<MyTextField
															placeholder="Password"
															inputType="password"
															fieldName="password"
															// onChange={(e) => {
															// 	customHandle(handleChange, 'password', e);
															// }}
															serverError={serverError}
															errors={errors.password || false}
															inputValue={values.password}
															fieldTouch={touched.password}
															setServerErrors={setServerErrors}
															apiErrors={apiErrors}
															handleChange={handleChange}
														/>
													</div>

													<Button
														type="submit"
														className="loginButton my-4 py-2 border-0 "
														disabled={isLoading}
													>
														{isLoading ? (
															<ClipLoader size={20} color="#FCF9F2" />
														) : (
															`Sign In  `
														)}
														&nbsp;&nbsp;
													</Button>
												</form>
											);
										}}
									</Formik>
									<div className="pt-xl-5 pb-xl-1 mt-xl-5  pt-lg-3 pb-lg-1 mt-lg-3  pt-md-4  mt-md-4 mt-4 ">
										<Link to="/forgot-password">
											<a className="forgotpassword py-5 my-5 ">
												Forgotten password?
											</a>
										</Link>
									</div>
								</div>
							</div>

							<Col xl={5} lg={5} md={5} sm={8} xs={12} className="py-4">
								<Row className="px-xl-5  pt-xl-5  mr-xl-5 pr-xl-5  pl-lg-5  pt-lg-1 mb-lg-5   pt-md-2 mb-md-5 joinTop">
									<div
										className=" px-4 pt-5 px-xl-5 pt-xl-5 pl-lg-4 pr-lg-0 pt-lg-5 px-md-4 pt-md-5 px-sm-4 pt-sm-5 px-xs-4 pt-xs-5  offset-xl-0 col-xl-12 offset-lg-0 col-lg-9  offset-md-1 col-md-10 offset-sm-0 col-sm-10 offset-xs-0 col-xs-11"
										style={{
											backgroundColor: '#FCF9F2',
										}}
									>
										<p className="para1">Be a part of the data revolution </p>
										<h1 className="giftcard">
											Join today and earn a $50 Gift card{' '}
										</h1>
										<p className="para2">
											Simply install the Gravy extension to your favourite
											browser and start earning for using the web as you usually
											would.
										</p>
										<p className="para2"></p>
										<div className="buttonWrapper text-center	">
											<Button
												className="px-5 py-2 mb-5 border-0 signinButton d-block"
												type="button"
												onClick={handleButton}
											>
												Sign Up &nbsp;&nbsp;
											</Button>
											<img
												src={Group}
												alt="Sign-in Group"
												className="img-fluid"
											/>
										</div>
									</div>
								</Row>
								<Row className="businessTop">
									<Col className="pr-0 pl-xl-2 offset-xl-5 offset-lg-5 offset-md-3 businessCol col-xl-6">
										<div>
											<p className="alreadyMember">
												Joining as a business? &nbsp;
												<Link to="/business-register">
													<a className="forgotpassword">Sign up here</a>
												</Link>
											</p>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</div>
				<FooterComponent />
			</div>
		</>
	);
};

// export default memo(SignInComponent);

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps, { loginUserAction })(
	withRouter(SignInComponent)
);
