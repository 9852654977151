import React from 'react';
import pending from '../../assets/images/Pending.svg';
import success from '../../assets/images/Success.svg';

const PendingSuccess = ({ type, width, height, className }) => {
	switch (type) {
		case 'pending':
			return (
				<img
					src={pending}
					alt="pending"
					className={className}
					width={width}
					height={height}
				/>
			);

		case 'success':
			return (
				<img
					src={success}
					alt="success"
					className={className}
					width={width}
					height={height}
				/>
			);

		default:
			return (
				<img
					src={success}
					alt="success"
					className={className}
					width={width}
					height={height}
				/>
			);
	}
};

export default PendingSuccess;
