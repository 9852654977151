import React, { memo } from 'react';
import { Redirect } from 'react-router-dom';
import { isLoggedIn } from 'utils';
import PublicRoutes from './PublicRoutes';

/*
 * TODO: when user loggedIn he/she unable to goto public routes
 *  ie: ('/about', '/contact', 'any other public route')
 */
function Auth() {
	// TODO: temp logged-in check, update as per your app logic

	const role =
		JSON.parse(localStorage.getItem('user')) &&
		JSON.parse(localStorage.getItem('user')).user &&
		JSON.parse(localStorage.getItem('user')).user.userRole;

	if (isLoggedIn() && role) {
		return role === 'CUSTOMER' ? (
			<Redirect to="/app/customer/dashboard" />
		) : role === 'ADVERTISER' ? (
			<Redirect to="/app/advertiser/dashboard" />
		) : (
			<Redirect to="/app/admin/member" />
		);
	} else {
		return <PublicRoutes />;
	}
}

export default memo(Auth);
