import React, { memo } from 'react';
import { JumbotronWrapper } from './common';

function Module3() {
	return (
		<JumbotronWrapper title="Module - 3" />
	)
}

export default memo(Module3);
