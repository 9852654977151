import { ManualAuthApiCall } from '../../../middlewares/apiMiddleware';
// import { saveAdvertiserToken } from '../../utils/index';
import history from 'utils/history';

export const GET_PROFILE_EMAIL = 'GET_PROFILE_EMAIL';
export const GET_PROFILE_EMAIL_SUCCESS = 'GET_PROFILE_EMAIL_SUCCESS';
export const GET_PROFILE_EMAIL_FAILURE = 'GET_PROFILE_EMAIL_FAILURE';

export const GetEmailAction = (payload) => async (dispatch) => {
	dispatch({
		type: GET_PROFILE_EMAIL,
	});

	const apiRequest = await ManualAuthApiCall(
		`/auth/profile/about`,
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify(payload),
		},
		dispatch,
		GET_PROFILE_EMAIL_FAILURE,
		history
	);
	if (apiRequest.error === false) {
		dispatch({ type: GET_PROFILE_EMAIL_SUCCESS, apiResponse: apiRequest });
		// history.push('/app/advertiser');
	}
};
