import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import RedeemGiftCardComponent from './RedeemGiftCardComponent';
import KeepBrowsingComponent from './KeepBrowsingComponent';
import CongratulationsComponent from './CongratulationsComponent';
import { AuthHeaderComponent, AuthFooterComponent } from 'components/common';
import { fetchProfileAction } from '../../../actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import { AuthFooterComponent } from 'components/common/Auth/FooterComponent/FooterComponent';

const CustomerGiftCardRedeemRNA = ({
	profile: { isLoading, user },
	fetchProfileAction,
}) =>
	// dispatch
	{
		useEffect(() => {
			fetchProfileAction();
		}, []);

		console.log(user);

		return (
			<>
				<div className="authfullContainer">
					<AuthHeaderComponent user={user && user} />
					<RedeemGiftCardComponent user={user && user} />

					<Container fluid>
						{user && parseInt(user.totalVerifiedCoin) >= 100 ? (
							<CongratulationsComponent
								coins={user && user.totalVerifiedCoin}
							/>
						) : (
							<KeepBrowsingComponent />
						)}{' '}
					</Container>
				</div>
				<AuthFooterComponent />
			</>
		);
	};

const mapStateToProps = (state) => ({
	profile: state.profile,
});

export default connect(mapStateToProps, {
	fetchProfileAction,
})(withRouter(CustomerGiftCardRedeemRNA));
