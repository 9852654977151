import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Form } from 'react-bootstrap';
import '../../Advertiser/AdvertiserProfileComponent/profileComponent.css';
import { MyTextField } from '../../../Validations/InputField';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PendingSuccess from '../../common/PendingSuccess';
import Timer from 'react-compound-timer';
import { SendResendAction } from '../../../actions';
import { useDispatch } from 'react-redux';
const YourEmail = ({
	resendEmail: { apiErrors, isLoading, sendStatus },
	history,
	location,
	user,
	handleResetAction,
}) => {
	const dispatch = useDispatch();
	const [serverError, setServerErrors] = useState({});
	// const [show, setShow] = useState(false);
	// const handleClose = () => setShow(false);

	useEffect(() => {
		// fetchProfileAction();
	}, [apiErrors, isLoading, sendStatus]);

	return (
		<>
			<div
				className={
					user && user.status && user.status.emailVerified ? 'line2' : 'line1'
				}
			>
				{/* <div> */}
				<div className="position-relative ml-3  ml-md-5 ml-sm-4  ml-xm-3 ">
					<Row className="mt-5 ">
						<div className="col-xl-11 col-lg-11  col-md-11  col-sm-8   col-sx-7 mt-5  ">
							<div
								className="d-flex"
								style={{ justifyContent: 'space-between' }}
							>
								<div style={{ width: '75%' }}>
									<h1 className="profileHeading">Your email</h1>
									<p>
										Please confirm your email by clicking the link sent during
										sign up. If you didn’t receive an email,&nbsp;
										<Timer
											initialTime={120000}
											direction="backward"
											startImmediately={false}
											onStart={() => {
												user &&
													user.status &&
													!user.status.emailVerified &&
													dispatch(
														SendResendAction({
															type: 'EMAIL',
															data: user && user.emailAddress,
														})
													);
											}}
										>
											{({ start, getTimerState, setTime }) => (
												<span>
													{getTimerState() == 'PLAYING' && (
														<span>
															<Timer.Minutes />
															{':'}
															<Timer.Seconds />
														</span>
													)}
													{getTimerState() != 'PLAYING' && (
														<>
															{setTime(120000)}
															<Link
																to="#"
																type="button"
																// disabled={showPhone}
																className="p1 text-decoration-none"
																style={{ WebkitAppearance: 'none' }}
																onClick={
																	user &&
																	user.status &&
																	!user.status.emailVerified &&
																	start
																}
															>
																click here
															</Link>
														</>
													)}
												</span>
											)}
										</Timer>
										{/* <Link
											to="#"
											type="button"
											className="p1 text-decoration-none"
											onClick={() =>
												user &&
												!user.status.emailVerified &&
												dispatch(
													SendResendAction({
														type: 'EMAIL',
														data: user && user.emailAddress,
													})
												)
											}
											// onClick={handleResetAction}
										>
											click here
										</Link> */}
										&nbsp;to have a new one sent.
									</p>
								</div>
								<div>
									{user && user.status && user.status.emailVerified ? (
										<PendingSuccess
											className="mt-5 "
											type="success"
											width="50px"
										/>
									) : (
										<PendingSuccess
											className="mt-5 "
											type="pending"
											width="50px"
										/>
									)}
								</div>
							</div>
						</div>
					</Row>

					<Row>
						<Col className="xl={6} lg={10} md={12} sm={12} xs={12}">
							{/* <ProfileComponentEmailForm
											user={{
												email: '',
											}}
										/> */}
							<Formik
								enableReinitialize={true}
								initialValues={{
									emailAddress:
										user && user.emailAddress ? user.emailAddress : '',
								}}
								onSubmit={async (values, { setSubmitting }) => {
									setSubmitting(true);
									// dispatch(loginUserAction(values));
									setSubmitting(false);
								}}
								validationSchema={Yup.object().shape({
									emailAddress: Yup.string()
										.email('Email must be valid')
										.required("Email can't be empty"),
								})}
							>
								{(props) => {
									const {
										values,
										touched,
										errors,
										// isSubmitting,
										handleChange,
										// handleBlur,
										handleSubmit,
										// setStatus,
									} = props;
									return (
										<Form onSubmit={handleSubmit} noValidate>
											<div className="">
												<MyTextField
													placeholder="Email"
													inputType="text"
													fieldName="emailAddress"
													// onChange={(e) => {
													// 	customHandle(handleChange, 'emailAddress', e);
													// }}
													serverError={serverError}
													errors={errors.emailAddress || false}
													inputValue={values.emailAddress}
													fieldTouch={touched.emailAddress}
													setServerErrors={setServerErrors}
													apiErrors={apiErrors}
													handleChange={handleChange}
													disabled={true}
													style={{ backgroundColor: 'white' }}
												/>
											</div>

											{/* <PuffLoader size={20} color="#FDBF00" /> */}
											{/* <Button
												type="submit"
												className="loginButton   my-4 "
												disabled={isSubmitting}
											>
												Save
												
											</Button> */}
										</Form>
									);
								}}
							</Formik>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};
const mapStateToProps = (state) => ({
	resendEmail: state.resend,
});

export default connect(mapStateToProps, {
	SendResendAction,
})(withRouter(YourEmail));
