import React, { useState, useEffect } from 'react';
import { Row, Col, Dropdown, Button, Form, Container } from 'react-bootstrap';

import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

// import { postTableText } from "./../../../Redux/actions/Table/TableAction";

const ColumnFilterText = ({ filter, setFilter, handleTableAction }) => {
	let [search, setSearch] = useState('');

	const handleSubmit = () => {
		filter['filter'] = {
			...filter['filter'],
			hostName: search,
		};
		setFilter(filter);
		handleTableAction();
	};

	const handleReset = () => {
		setSearch('');
		filter['filter'] = [];
		setFilter(filter);
		handleTableAction();
	};
	useEffect(() => {
		setSearch(filter && filter['filter'] && filter['filter'].hostName);
	}, [filter]);

	return (
		<>
			<Dropdown>
				<Dropdown.Toggle
					id="dropdown-basic"
					className="dropdown-icon shadow-none"
					style={{ paddingTop: '30px', paddingBottom: '30px' }}
					variant="none"
				>
					<FontAwesomeIcon icon={faSearch} />
				</Dropdown.Toggle>

				<Dropdown.Menu align="right" className="menu-items">
					<Container id="checkBox">
						<Row>
							<Col>
								<Form onSubmit={handleSubmit}>
									<Form.Group controlId="search">
										<Form.Control
											type="text"
											name="hostName"
											placeholder="Search Host Name"
											value={search}
											onChange={(e) => {
												search = e.target.value;
												setSearch(search);
											}}
											required
										/>
									</Form.Group>
									<Dropdown.Divider />
									<Button
										variant="none"
										className="dropdown-reset-button shadow-none"
										type="reset"
										onClick={handleReset}
									>
										Reset
									</Button>
									<Button
										variant="warning"
										type="submit"
										className="ml-3 shadow-none"
									>
										Search
									</Button>
								</Form>
							</Col>
						</Row>
					</Container>
				</Dropdown.Menu>
			</Dropdown>
		</>
	);
};

// export default connect(null, { postTableText })(ColumnFilterText);
export default ColumnFilterText;
