import { ManualAuthApiCall } from '../../middlewares/apiMiddleware';
import { isLoggedIn } from '../../utils';
import history from 'utils/history';
export const COMPANY_DETAIL = 'COMPANY_DETAIL';
export const COMPANY_DETAIL_SUCCESS = 'COMPANY_DETAIL_SUCCESS';
export const COMPANY_DETAIL_FAILURE = 'COMPANY_DETAIL_FAILURE';

export const CompanyDetailAction = (payload, userId) => async (dispatch) => {
	payload['userId'] = userId.id;
	console.log('payload and id', userId);

	dispatch({
		type: COMPANY_DETAIL,
	});

	const apiRequest = await ManualAuthApiCall(
		`/auth/admin/company/detail`,
		{
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify(payload),
		},
		dispatch,
		COMPANY_DETAIL_FAILURE,
		history
	);
	if (apiRequest.error === false) {
		dispatch({ type: COMPANY_DETAIL_SUCCESS, apiResponse: apiRequest });
	}
};
