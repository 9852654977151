import { ManualApiCall } from '../../middlewares/apiMiddleware';
import { saveAuthToken } from '../../utils/index';
import history from 'utils/history';

export const EMAIL_ENTER = 'EMAIL_ENTER';
export const EMAIL_ENTER_SUCCESS = 'EMAIL_ENTER_SUCCESS';
export const EMAIL_ENTER_FAILURE = 'EMAIL_ENTER_FAILURE';
// export const LOGOUT = 'LOGOUT';

export const emailEnterAction = (payload) => async (dispatch) => {
	dispatch({
		type: EMAIL_ENTER,
	});

	const apiRequest = await ManualApiCall(
		`/public/forgot/password/email`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify(payload),
		},
		dispatch,
		EMAIL_ENTER_FAILURE,
		history,
		true
	);
	if (apiRequest.error === false) {
		// saveAuthToken(apiRequest.data);
		dispatch({ type: EMAIL_ENTER_SUCCESS, apiResponse: apiRequest });
		// alert('Your password has been changed successfully');
		// history.push('/');
	}
};

// export const logout = () => ({
// 	type: LOGOUT,
// });
