import { ManualApiCall } from '../../middlewares/apiMiddleware';
import { saveAuthToken } from '../../utils/index';
import history from 'utils/history';
export const REGISTER_ADVERTISER = 'REGISTER_ADVERTISER';
export const REGISTER_ADVERTISER_SUCCESS = 'REGISTER_ADVERTISER_SUCCESS';
export const REGISTER_ADVERTISER_FAILURE = 'REGISTER_ADVERTISER_FAILURE';

export const RegisterAdvertiserAction = (payload) => async (dispatch) => {
	dispatch({
		type: REGISTER_ADVERTISER,
	});

	const apiRequest = await ManualApiCall(
		`/auth/advertiser/registerAdvertiser`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify(payload),
		},
		dispatch,
		REGISTER_ADVERTISER_FAILURE,
		history,
		true
	);
	if (apiRequest.error === false) {
		saveAuthToken(apiRequest.data);
		dispatch({ type: REGISTER_ADVERTISER_SUCCESS, apiResponse: apiRequest });
		history.push('/app/advertiser/dashboard');
	}
};
