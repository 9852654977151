import React, { memo } from 'react';
import { JumbotronWrapper } from './common';

function Module2() {
	return (
		<JumbotronWrapper title="Module - 2" />
	)
}

export default memo(Module2);
