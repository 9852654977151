export { default as LandingPage } from './LandingPage';
export { default as Login } from './Login';
export { default as Register } from './Register';
export { default as ForgotPassword } from './ForgotPassword';
export { default as Module1 } from './Module1';
export { default as Module2 } from './Module2';
export { default as Module3 } from './Module3';
export { default as ModuleN } from './ModuleN';
export { default as ModuleNChild1 } from './ModuleNChild1';
export { default as ModuleNChild2 } from './ModuleNChild2';
export { default as ModuleNChild3 } from './ModuleNChild3';
export { default as Profile } from './Profile';
export { default as Dashboard } from './User/UserDashboardComponent/Dashboard/Dashboard';
export { default as ModalTableComponent } from './User/UserDashboardComponent/Dashboard/ModalTableComponent';
export { default as SignInComponent } from './User/SignInComponent';
export { default as CustomerGiftCardRedeemRNA } from './User/GiftCardRedeemComponent/CustomerGiftCardRedeemRNA';
export { default as ReferFriend } from './User/ReferFriendComponent/ReferFriend';
export { default as SignUpComponent } from './User/SignUpComponent';
export { default as ProfileComponent } from './User/ProfileComponent';
export { default as EmailVerification } from './User/EmailVerificationComponent';

//................ Advertiser ....................//

export { default as AdvertiserSignUpComponent } from './Advertiser/AdvertiserSignUpComponent';
export { default as AdvertiserDashboard } from './Advertiser/AdvertiserDashboard/AdvertiserDashboard';
export { default as AdvertiserInvoices } from './Advertiser/AdvertiserInvoices/AdvertiserInvoices';
export { default as AdvertiserProfileComponent } from './Advertiser/AdvertiserProfileComponent';

///////////////////////// Admin ///////////////////////////////////////
export { default as AdminCompaniesDashboard } from './Admin/AdminCompaniesDashboard/AdminCompaniesDashboard';
export { default as AdminCompanyView } from './Admin/AdminCompanyView/AdminCompanyView';
export { default as AdminInvoices } from './Admin/AdminInvoices/AdminInvoices';
export { default as AdminProfile } from './Admin/AdminProfile/adminProfile';
export { default as AdminGiftCard } from './Admin/AdminGiftCard/AdminGiftCard';
export { default as AdminMemberDashboard } from './Admin/AdminMembers/AdminMemberDashboard';
export { default as AdminMemberView } from './Admin/AdminMemberView/AdminMemberView';
