import React, { useState } from 'react';
import Vector from '../../assets/images/Vector.svg';
import Vector2 from '../../assets/images/Vector2.svg';
import '../../index.css';

const ModalPaginationComponent = ({
	filter,
	setFilter,
	handleModalSort,
	totalRecord = false,
}) => {
	const [currentPage, setCurrentPage] = useState(1);
	const totalPages = Math.ceil(parseInt(totalRecord) / parseInt(10));
	console.log({ totalPages });
	console.log({ currentPage });

	const handleChangeNext = () => {
		// setCurrentPage(currentPage + 1);
		// filter['pagination'] && filter['pagination']['page']
		// 	? (filter['pagination']['page'] = currentPage + 1)
		// 	: (filter['pagination'] = {
		// 			...filter['pagination'],
		// 			page: currentPage + 1,
		// 	  });

		// setFilter(filter);
		// handleModalSort();
		// console.log('my console for next page', currentPage + 1);
		if (currentPage < totalPages) {
			setCurrentPage(currentPage + 1);
			filter && filter['pagination'] && filter['pagination']['page']
				? (filter['pagination']['page'] = currentPage + 1)
				: (filter['pagination'] = {
						...filter['pagination'],
						page: currentPage + 1,
				  });

			setFilter(filter);
			handleModalSort();
		}
	};
	const handleChangePrevious = () => {
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1);

			filter['pagination'] && filter['pagination']['page']
				? (filter['pagination']['page'] = currentPage - 1)
				: (filter['pagination'] = {
						...filter['pagination'],
						page: currentPage - 1,
				  });

			setFilter(filter);
			handleModalSort();
		}
	};

	return (
		<>
			<div className="row mb-5">
				<div className="offset-xl-10  col-xl-2 offset-lg-9 col-lg-3  offset-md-9 col-md-3 col-sm-12 col-xs-12 modalPagination ">
					<div className="row">
						<div className="input-group   styleInline ">
							<span
								className={
									currentPage === 1
										? 'input-group-addon btn btn-primary paginationAction disabled mx-2'
										: 'input-group-addon btn btn-primary paginationAction mx-2'
								}
								data-pager-action="previous"
								onClick={handleChangePrevious}
							>
								{' '}
								<img src={Vector} alt="pervious arrow" />
							</span>
							<b className=" btn btn-primary  pageCounterPagination">
								{currentPage}
							</b>
							<span
								className={
									currentPage === totalPages
										? 'input-group-addon btn btn-primary paginationAction disabled'
										: 'input-group-addon btn btn-primary paginationAction'
								}
								data-pager-action="next"
								onClick={handleChangeNext}
							>
								<img src={Vector2} alt="next arrow" />
							</span>
						</div>
						{/* <div className="input-group mobilePagination col-xl-6 col-lg-6 col-md-6  col-sm-2 col-xs-2">
							<select
								className="form-control paginationAction"
								data-pager-action="pagesize"
								style={{ minWidth: '90%' }}
							>
								<option value={10}>10 / page</option>
								<option value={20}>20 / page</option>
								<option value={30}>30 / page</option>
							</select>
						</div> */}
					</div>
				</div>
			</div>
		</>
	);
};

export default ModalPaginationComponent;
