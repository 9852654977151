import React, { useState, useEffect } from 'react';
import { Row, Button, Form } from 'react-bootstrap';
import '../../Advertiser/AdvertiserProfileComponent/profileComponent.css';
import { MyTextField } from '../../../Validations/InputField';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { accountPasswordAction } from '../../../actions';
import { ClipLoader } from 'react-spinners';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
const AdminAccountPassword = (
	{
		accountPassword: { apiErrors, isAboutYouSaving, user },
		// accountPasswordAction,
		history,
		location,
	},
	dispatch
) => {
	const [serverError, setServerErrors] = useState({});
	useEffect(() => {
		setServerErrors(apiErrors);
	}, [apiErrors]);

	useEffect(() => {}, [isAboutYouSaving, apiErrors, user, location.pathname]);
	return (
		<>
			<Row>
				<div
					className="  col-xl-11  col-lg-11 col-md-12 col-sm-11 col-xs-11  box2 px-xl-5 px-lg-5 px-sm-1 px-xs-1  overlap"
					style={{
						backgroundColor: '#FCF9F2',
						position: 'relative',
						marginTop: '-17%',
					}}
				>
					<div className="mt-5 pt-3 pb-5 px-xl-1 px-lg-1 px-md-5 px-3 pt-sm-1">
						<h1>Account Password</h1>
						<p style={{ color: '#9C9C9C' }}>
							You can reset your password below.
						</p>
						<Formik
							initialValues={{
								oldPassword: '',
								newPassword: '',
							}}
							onSubmit={async (values, { setSubmitting }) => {
								setSubmitting(true);
								dispatch(accountPasswordAction(values));
								setSubmitting(false);
							}}
							validationSchema={Yup.object().shape({
								oldPassword: Yup.string().required(
									"Old Password can't be empty"
								),
								newPassword: Yup.string()
									.min(
										6,
										'Password is too short - should be 6 characters minimum.'
									)
									.max(
										20,
										'Your characters limit is exceded - maximum 20 characters allowed'
									)
									.required("New Password can't be empty")
									.matches(
										/^.*(?=.{6,})(?=.*\d)((?=.*[A-Z]){1}).*$/,
										'Password must contain at least one uppercase character and one number'
									)
									.notOneOf(
										[Yup.ref('oldPassword')],
										'New password must not be same as old password'
									),
							})}
						>
							{(props) => {
								const {
									values,
									touched,
									errors,
									// isSubmitting,
									handleChange,
									// handleBlur,
									handleSubmit,
									// setStatus,
								} = props;
								return (
									<Form onSubmit={handleSubmit} noValidate>
										<div className="">
											<MyTextField
												placeholder="Old Password"
												inputType="password"
												fieldName="oldPassword"
												// onChange={(e) => {
												// 	customHandle(handleChange, 'password', e);
												// }}
												serverError={serverError}
												errors={errors.oldPassword || false}
												inputValue={values.oldPassword}
												fieldTouch={touched.oldPassword}
												setServerErrors={setServerErrors}
												apiErrors={apiErrors}
												handleChange={handleChange}
											/>
										</div>
										<div className="">
											<MyTextField
												placeholder="New Password"
												inputType="password"
												fieldName="newPassword"
												// onChange={(e) => {
												// 	customHandle(handleChange, 'password', e);
												// }}
												serverError={serverError}
												errors={errors.newPassword || false}
												inputValue={values.newPassword}
												fieldTouch={touched.newPassword}
												setServerErrors={setServerErrors}
												apiErrors={apiErrors}
												handleChange={handleChange}
											/>
										</div>

										{/* <Button
											type="submit"
											className="loginButton my-4 "
											disabled={isSubmitting}
										>
											Change Password
										</Button> */}
										<Button
											type="submit"
											className="loginButton   my-4 "
											disabled={isAboutYouSaving}
										>
											{isAboutYouSaving ? (
												<ClipLoader size={20} color="#FCF9F2" />
											) : (
												`Change Password`
											)}
										</Button>
									</Form>
								);
							}}
						</Formik>
					</div>
				</div>
			</Row>
		</>
	);
};
const mapStateToProps = (state) => ({
	accountPassword: state.accountPassword,
});

// export default AccountPassword;

export default connect(mapStateToProps, { accountPasswordAction })(
	withRouter(AdminAccountPassword)
);
// export default AdminAccountPassword;
