import React, { useState } from 'react';
import { Row, Button } from 'react-bootstrap';
import '../Advertiser/AdvertiserProfileComponent/profileComponent.css';
import { Link } from 'react-router-dom';
// import { toQueryString } from '../../utils/index';

const ProfileView = ({ userId }) => {
	// const queryString = toQueryString(userId);
	// const [serverError, setServerErrors] = useState({});

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	return (
		<>
			<Row>
				<div
					className=" col-xl-11  col-lg-11 col-md-12 col-sm-12 col-xs-12 px-xl-5 px-lg-5 px-sm-1 px-xs-1  overlap"
					style={{
						backgroundColor: '#FCF9F2',
						position: 'relative',
						marginTop: '-17%',
					}}
				>
					<div className="mt-4 pt-4 mb-4 pb-4  px-xl-4 px-lg-4 VerifiedQuery  verified1">
						<h1 className="">Profile</h1>
						<p style={{ color: '#9C9C9C' }}>
							View the member who owns and operates this company.
						</p>
						{/* <Button
							className="loginButton  border-0  "
							onClick={() => setShow(true)}
						>
							View Profile
							{<PuffLoader size={20} color="#3c8f7c" />}
						</Button> */}

						<Link to={`/app/admin/memberview?id=${userId.id}`}>
							<Button className=" button shadow-none ">View Profile</Button>
						</Link>
					</div>
				</div>
			</Row>
		</>
	);
};

export default ProfileView;
