import React, { useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import Invoices from '../../../assets/images/Invoices-crop.svg';
import Line from '../../../assets/images/Line.png';
import PendingSuccess from '../../common/PendingSuccess';
import AuthAdvertiserHeaderComponent from '../../common/Advertiser Header/HeaderComponent';
import FooterComponent from '../../common/Auth/FooterComponent/FooterComponent';
import TableComponent from './AdvertiserInvoicesTableComponent';
import { DashbaordAction } from '../../../actions';
import { connect } from 'react-redux';

const AdvertiserInvoices = ({
	dashboard: { apiErrors, isLoading, user },
	DashbaordAction,
}) => {
	useEffect(() => {
		DashbaordAction();
	}, []);
	return (
		<>
			<div className="authfullContainer">
				<AuthAdvertiserHeaderComponent user={user && user} />
				<div className="fullBg">
					<Container fluid className="font">
						<Row className="header1 pt-5">
							<Col
								xl={3}
								lg={3}
								md={12}
								sm={12}
								xs={12}
								className="invoice-block"
							>
								<img
									className="img-fluid"
									src={Invoices}
									alt=""
									className="advertiser-invoice-img"
									width="100%"
								/>
							</Col>
							<Col xl={8} lg={8} md={12}>
								<Row className="pt-5">
									<Col xl={5} lg={5} md={5} sm={10} xs={12} className="pr-0">
										<h1 className="InvoiceMaxText ml-xl-5 ml-lg-5 ml-md-5 ml-5 mt-2">
											Here's your invoices
										</h1>
									</Col>
									{user &&
									user.status &&
									user.status.bioVerified &&
									user.status.emailVerified &&
									user.status.phoneNumberVerified &&
									user.status.domainVerified &&
									user.status.paymentVerified ? (
										''
									) : (
										<>
											<Col xs={1} className="maxLineWidth line-image">
												<img src={Line} alt="" />
											</Col>
											<Col
												xl={6}
												lg={5}
												md={6}
												sm={12}
												xs={12}
												className="px-0"
											>
												<Row className="mt-xl-2 mt-lg-2 mt-md-3 mt-5 mr-0">
													<Col
														xl={2}
														lg={2}
														md={2}
														sm={2}
														xs={2}
														className="px-lg-1"
													>
														<PendingSuccess
															type="pending"
															size="50px"
															className="img-fluid ml-xl-4 ml-lg-3 ml-md-4 ml-sm-4 ml-xs-4 ml-4 mt-xl-0 mt-lg-1 mt-md-0 mt-sm-0 mt-xs-0 mt-0"
														/>
													</Col>
													<Col
														xl={10}
														lg={10}
														md={10}
														sm={10}
														xs={9}
														className="pr-0"
													>
														<h6 className="mt-xl-2 mt-lg-2 mt-md-0 mt-1 ml-xl-2 ml-lg-2 ml-md-2 ml-2">
															To start seeing new website users verify your
															account. &nbsp;
															<Link
																to="/app/advertiser/profile"
																className="p1 text-decoration-none"
															>
																Get Verified
															</Link>
														</h6>
													</Col>
												</Row>
											</Col>
										</>
									)}
								</Row>
								<Row className="mt-4 mx-xl-5 mx-lg-5 mx-md-5 coins-position">
									<Col
										xl={12}
										lg={12}
										md={12}
										sm={12}
										xs={12}
										style={{
											backgroundColor: '#FCF9F2',
											justifyContent: 'center',
										}}
										className="py-5 d-flex"
									>
										{/* <div className="pt-5 d-flex imgSuccess"> */}
										<div>
											<PendingSuccess
												type="success"
												width="50px"
												className="img-fluid mx-4 imgSuccess"
											/>
										</div>

										<h1 className="cointext">
											Total Website Users: <b>{user && user.totalCustomer}</b>
										</h1>
										{/* </div> */}
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</div>
				<Container fluid className="mt-5">
					<TableComponent />
				</Container>
			</div>
			<FooterComponent />
		</>
	);
};

const mapStateToProps = (state) => ({
	dashboard: state.dashboard,
});

export default connect(mapStateToProps, {
	DashbaordAction,
})(withRouter(AdvertiserInvoices));
